/*global RioAppClient */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import EventEmitter from 'events';
import logger from './RioLogger';
import RioHelper from './RioHelper';
import RioTimer from './RioTimer';
import RioAppInterface from './RioAppInterface';
import { delayTime } from '../../functions';

class RioAppClient {
  options = {
    _key: '__rio__',
    _keySetting: 'settings',
  };

  constructor() {
    this._eventEmitter = new EventEmitter();
  }

  /**
  * create instance RioAppClient
  *
  * @returns {void}
  */
  instance = () => {
    if (!window.RioAppClient) {
      window.RioAppClient = this;
    }
    return window.RioAppClient;
  };

  /**
  * Init RioAppClient
  *
  * @returns {void}
  */
  init = (strJson) => {
    const json = RioHelper.decode(strJson, {});
    const { creds, options, users, settings, calls } = json;

    logger.debug('[STEP] 1 RioAppClient init', json);
    RioTimer.start('init');

    this.options['users'] = users || {};
    const initOpt = Object.assign({},
        options,
        {
          creds,
          users
        }
    );
    //check save new setting
    if ( !RioHelper.isEmpty(settings) ) {
      const allSetting = Object.assign({}, 
        settings,
        calls || {},
      );
      this.setSetting(allSetting, true);
    }
    //init RioMeetJS
    RioMeetJS.init({
      creds: creds,
      appSettings: {},
      enableWindowOnErrorHandler: true
    });

    //create APP SDK
    const rioApp = RioMeetJS.createRioApp(initOpt);
    this.options['rioApp'] = rioApp;

    this._addEvents();
    return true;
  };

  getApp = () => {
    return (this.options && this.options['rioApp']) 
        ? this.options['rioApp'] 
        : false;
  };

  /**
  * setting load
  *
  * @returns {void}
  */
  setting = (strJson = {}) => {
    logger.debug('[STEP] 2 RioAppClient setting', strJson);
    const emitter = this._eventEmitter;
    const json = RioHelper.decode(strJson, {});
    const defaultSetting = this._defaultSetting();
    const settings = Object.assign({}, 
        defaultSetting,
        json
    );

    //init RioMeetJS
    RioMeetJS.init({
      creds: {},
      appSettings: {},
      enableWindowOnErrorHandler: true
    });
    //create APP SDK
    const rioApp = RioMeetJS.createRioApp({});
    this.options['rioApp'] = rioApp;

    emitter.emit('onAppSetting', settings);

    RioHelper.setSession(`${this.options._keySetting}`, settings);
    return true;
  };

  /**
  * set setting
  *
  * @returns {object}
  */
  setSetting = (settings, ignoreSendApp) => {
    const defaultSetting = this._defaultSetting();
    const newSettings = Object.assign({}, 
        defaultSetting,
        settings || {},
    );
    const {
      isFilter,
      audioMuted,
      videoMuted
    } = newSettings;
    newSettings.isFilter = Boolean(isFilter);
    newSettings.audioMuted = Boolean(audioMuted);
    newSettings.videoMuted = Boolean(videoMuted);

    logger.debug('[STEP] 2 RioAppClient setSetting', settings, JSON.stringify(newSettings));
    RioHelper.setSession(`${this.options._keySetting}`, newSettings);
    //call to app onSetting
    if (ignoreSendApp !== true) {
      RioAppInterface.onSetting(newSettings);
    }
    
    return newSettings;
  };

  /**
  * get setting
  *
  * @returns {object}
  */
  getSetting = (defaults) => {
    const settings = this._defaultSetting(defaults);
    const {
      isFilter,
      audioMuted,
      videoMuted
    } = settings;
    settings.isFilter = Boolean(isFilter);
    settings.audioMuted = Boolean(audioMuted);
    settings.videoMuted = Boolean(videoMuted);

    logger.debug('RioAppClient getSetting', JSON.stringify(settings));
    RioHelper.setSession(`${this.options._keySetting}`, settings);
    return settings;
  };

  /**
  * start call
  *
  * @returns {void}
  */
  startCall = (options = {}) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }
    return rioApp.startCall(options);
  };

  /**
  * Join to room
  *
  * @returns {void}
  */
  join = (strJson) => {
    logger.debug('[STEP] 2 RioAppClient join', strJson);
    RioTimer.start('start');

    const emitter = this._eventEmitter;
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    const options = RioHelper.decode(strJson, {});
    const {
        from, to, roomName, callId
    } = options || {};
    if (!from || !to || !roomName || !callId) {
        logger.debug('RioAppClient join invalid parameter');
        RioAppInterface.onStop('param', 'Invalid parameter', false);
        return true;
    }
    RioTimer.debug(`RioAppClient join ${callId}`);

    const existedCall = RioHelper.getStorage(`existedCall${callId}`);
    if (existedCall) {
        logger.warn(`RioAppClient join The call ${callId} existed.`);
        return true;
    }
    
    RioHelper.setStorage(`existedCall${callId}`, callId);
    setTimeout(() => {
        const options = rioApp.join(strJson);
        emitter.emit('join', options);
    }, 100);
    return true;
  };

  /**
  * Stop call
  * this function called from application when socket receive stop
  *
  * @returns {void}
  */
  stopCall = (strJson) => {
    logger.debug('RioAppClient stopCall', strJson);
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    rioApp.stopCall(strJson);
    return true;
  };

  /**
  * send remote change quality stream
  *
  * @returns {Boolean}
  */
  sendQuality = (strJson = {}) => {
    logger.debug('RioAppClient sendQuality', strJson);

    const json = RioHelper.decode(strJson, {});
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    rioApp.remoteChangeQuality(json);
    return true;
  };

  /**
  * enter Picture-in-Picture events
  *
  * @returns {Boolean}
  */
  enterPIP = (onPipWebview) => {
    logger.debug('RioAppClient enterPIP');
    const togglePIP = (onPipWebview === true || onPipWebview == '1') ? true : false;
    const emitter = this._eventEmitter;
    const rioApp = this.getApp();
    
    if (!rioApp) {
      return true;
    }

    if (togglePIP) {
      this.togglePIP({enabled: true, isLocal: false});
    }

    setTimeout(() => {
        emitter.emit('onEnterPIP');
    }, 300);
    return true;
  };

  /**
  * exit Picture-in-Picture events
  *
  * @returns {Boolean}
  */
  leavePIP = (onPipWebview) => {
    logger.debug('RioAppClient leavePIP');

    const togglePIP = (onPipWebview === true || onPipWebview == '1') ? true : false;
    const emitter = this._eventEmitter;
    const rioApp = this.getApp();
    
    if (!rioApp) {
      return true;
    }

    if (togglePIP) {
      this.togglePIP({enabled: false, isLocal: false});
    }
    setTimeout(() => {
        emitter.emit('onLeavePIP');
    }, 300);
    return true;
  };

  /**
  * on change audio device
  * call from app
  *
  * @returns {void}
  */
  onChangeAudioDeviceId = (strJson) => {
    logger.debug('RioAppClient onChangeAudioDeviceId', strJson);
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    const json = RioHelper.decode(strJson, {});
    RioMeetJS.videoroom.onChangeAudioDeviceId(json);
    return true;
  };

  /**
  * on change audio device
  * call from app
  *
  * @returns {void}
  */
  onAudioInputChanged = (strJson) => {
    logger.debug('RioAppClient onAudioInputChanged', strJson);
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    const json = RioHelper.decode(strJson, []);
    if (Array.isArray(json)) {
      RioMeetJS.videoroom.onAudioInputChanged(json);
    }
    return true;
  };

  /**
  * on change audio device
  * call from app
  *
  * @returns {void}
  */
  onRequestAudioDevices = (options) => {
    logger.debug('RioAppClient onRequestAudioDevices', options);
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    RioAppInterface.onRequestAudioDevices(options);
    return true;
  };
  
  /**
  * on change audio device
  * call from app
  *
  * @returns {void}
  */
  onSpeakerEnable = (isEnabled) => {
    logger.debug('RioAppClient onSpeakerEnable', isEnabled);
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    if(RioHelper.isTrue(isEnabled)) {
        RioMeetJS.videoroom.onSpeakerEnable(true);
    } else {
        RioMeetJS.videoroom.onSpeakerEnable(false);
    }
    
    return true;
  };

  /**
  * on app force to background
  *
  * @returns {Boolean}
  */
  onPause = (strJson) => {
    logger.debug('RioAppClient onPause');
    const json = RioHelper.decode(strJson, {});
    //const { agentType } = json;
    const rioApp = this.getApp();
    
    if (!rioApp) {
      return true;
    }

    if (RioHelper.isIosBrowser()) {
      rioApp.onPause(json);
    }

    return true;
  };

  /**
  * on app resume from background
  *
  * @returns {Boolean}
  */
  onResume = (strJson) => {
    logger.debug('RioAppClient onResume');
    const json = RioHelper.decode(strJson, {});
    //const { agentType } = json;
    const rioApp = this.getApp();
    
    if (!rioApp) {
      return true;
    }

    if (RioHelper.isIosBrowser()) {
      rioApp.onResume(json);
    }

    return true;
  };

  /**
  * on destroy webview
  * call from app
  *
  * @returns {void}
  */
  onDestroy = () => {
    logger.debug('RioAppClient onDestroy');
    RioMeetJS.videoroom.onDestroy();

    return true;
  };

  /**
  * get list user
  *
  * @returns {array}
  */
  getUsers = () => {
    const {users} = this.options['users'];
    return users;
  };

  setAudioMute = (muted) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }
    return new Promise((resolve, reject) => {
        rioApp.setMute(muted, RioMeetJS.constants.MediaType.AUDIO).then(track => {
            resolve(track);
        })
        .catch(err => {
            reject(err);
        });
    });
  };

  switchCamera = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        rioApp.switchCamera(options)
        .then(track => {
            resolve(track);
        })
        .catch(err => {
            reject(err);
        });
    });
  };

  disposeTrack = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    return new Promise((resolve) => {
        rioApp.disposeTrack(options)
        .then( () => {
            resolve(true);
        });
    });
  };

  //func for webview
  switchInputAudio = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    //send to app
    RioAppInterface.onChangeDeviceId(options);
    return Promise.resolve();
    //return new Promise((resolve, reject) => {
    //    rioApp.switchInputAudio(options)
    //    .then(track => {
    //        resolve(track);
    //    })
    //    .catch(err => {
    //        reject(err);
    //    });
    //});
  };

  //func for webview
  switchOutputAudio = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }
    return new Promise((resolve, reject) => {
        rioApp.switchOutputAudio(options)
        .then(track => {
            resolve(track);
        })
        .catch(err => {
            reject(err);
        });
    });
  };

  //func for webview
  /**
  * switch Speaker on/off
  * @param {boolean} isSpeaker
  *
  * @returns {Promise}
  */
  toggleSpeaker = (isSpeaker) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    try {
        rioApp.toggleSpeaker(isSpeaker)
        RioAppInterface.onToggleSpeaker(isSpeaker);
    } catch (err) {
      logger.warn(`Error: toggleSpeaker: ${err?.message}`);
    }
    return true;
  };

  /**
  * Turn on/off audio stream
  * @param {boolean} muted
  *
  * @returns {Promise}
  */
  toggleMuted = (muted) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }
    
    RioAppInterface.onToggleMuted(muted);
    delayTime((1*1000));
    return Promise.resolve();
  };

  //func for webview
  changeVideoQuality = (resolution) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }
    rioApp.changeVideoQuality(resolution);
  };

  voiceChange = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    rioApp.voiceChange(options);
  };

  toggleSubtitle = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }
    rioApp.toggleSubtitle(options);
  };

  /**
  * toggle Picture-in-Picture mode
  * @param {Object} options
  * options.enabled ON/OFF PIP
  * options.isLocal = true, set PIP on JitsiRemoteTrack
  * options.isLocal = false, set PIP on JitsiLocalTrack
  *
  * @returns {Boolean}
  */
  checkOutputAudio = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }

    rioApp.checkOutputAudio(options);
    return true;
  };

  /**
  * toggle Picture-in-Picture mode
  * @param {Object} options
  * options.enabled ON/OFF PIP
  * options.isLocal = true, set PIP on JitsiRemoteTrack
  * options.isLocal = false, set PIP on JitsiLocalTrack
  *
  * @returns {Boolean}
  */
  togglePIP = (options) => {
    const rioApp = this.getApp();
    logger.debug('RioAppClient togglePIP', options);

    if (!rioApp) {
      return true;
    }

    const {enabled, isLocal }= options
    const isEnabled = (enabled === true || enabled == '1') ? true : false;
    const localTrack = (isLocal === true || isLocal == '1') ? true : false;

    if (isEnabled) {
      rioApp.enterPIP(localTrack);
    } else {
      rioApp.leavePIP(localTrack);
    }
    return true;
  };

  startRecording = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        rioApp.startLocalRecording(options)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    });
  };

  stopRecording = (options) => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
        rioApp.stopLocalRecording(options)
        .then(res => {
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    });
  };

  /**
  * Stop call
  *
  * @returns {void}
  */
  stop = () => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }
    return rioApp.stop();
  };

  /**
  * get Call Info
  *
  * @returns {object}
  */
  getCallInfo = () => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return {};
    }
    return rioApp.getCallInfo();
  };

  /**
  * get Call Info
  *
  * @returns {object}
  */
  getRemoteOptions = () => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return {};
    }
    return rioApp.getRemoteOptions();
  };
  
  /**
  * Logout
  *
  * @returns {void}
  */
  logout = () => {
    const rioApp = this.getApp();
    if (!rioApp) {
      return true;
    }
    return rioApp.logout();
  };

  _addEvents = () => {
    //RioMeetJS.videoroom.onConnectionSuccess = this.onConnectionSuccess.bind(this);
  };

  /**
  * setting load
  *
  * @returns {void}
  */
  _defaultSetting = (iniSettings) => {
    const agentType = RioHelper.getAgentType();
    const _default = {
      agentType: agentType,
      currentUserId: '',
      cameraDeviceId: undefined,
      micDeviceId: 'default',
      voice: '',
      isFilter: false,
      audioMuted: false,
      videoMuted: false
    };

    const strSettings = RioHelper.getSession(`${this.options._keySetting}`);
    const settings = RioHelper.decode(strSettings, {});

    const options = Object.assign({}, 
        _default,
        settings,
        iniSettings || {}
    );

    return options;
  };

  setSession = (key, val) => {
    if (typeof val == 'object') {
      val = JSON.stringify(val);
    }

    sessionStorage.setItem(`${this.options._key}`+ key, val);
  };

  getSession = (key) => {
    return sessionStorage.getItem(`${this.options._key}`+ key);
  };

  removeSession = (key) => {
    return sessionStorage.removeItem(`${this.options._key}`+ key);
  };

  once = (eventId, handler) => {
    if (this._eventEmitter) {
        this._eventEmitter.once(eventId, handler);
    }
  };

  on = (eventId, handler) => {
    if (this._eventEmitter) {
        this._eventEmitter.on(eventId, handler);
    }
  };

  off = (eventId, handler) => {
    if (this._eventEmitter) {
        this._eventEmitter.removeListener(eventId, handler);
    }
  };
}

// create instance
const rioAppClient = new RioAppClient();
// lock instance
Object.freeze(rioAppClient);

export default rioAppClient;

/*global RioAppInterface */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/

import Constants from '../../Constants';
import logger from './RioLogger';
import RioHelper from './RioHelper';
import RioTimer from './RioTimer';

class RioAppInterface {
  options = {
      enable: false,
      agentType: ''
  };

  constructor() {
    if (RioHelper.isIosBrowser()) {
      this.options.agentType = Constants.AGENT.IOS;
    } else {
      this.options.agentType = Constants.AGENT.ANDROID;
    }
  }

  /**
  * set enable status
  *
  * @returns {void}
  */
  setEnable = () => {
    this.options.enable = true;
  };

  /**
  * get enable status
  *
  * @returns {boolean}
  */
  isEnable = () => {
    return this.options.enable;
  };

  /*
    Start function send to app
  */
  /**
  * start call
  *
  * @returns {void}
  */
  onStartCall = (options = {}) => {
    if (!this.isEnable()) {
      return;
    }
    try {
      const {userId, language, callType} = options;
      let json = RioHelper.encode({
        to: userId,
        calleeId: userId,
        language: language,
        callType: callType
      });

      const { agentType } = this.options;
      // eslint-disable-next-line no-undef
      if (agentType === Constants.AGENT.IOS) {
        // eslint-disable-next-line no-undef
        if (window.webkit !== undefined 
          && window.webkit.messageHandlers !== undefined) {
          window.webkit.messageHandlers.onStartCall.postMessage(json);
        }
      } else {
        // eslint-disable-next-line no-undef
        if (typeof RioInterface === "object") {
          RioInterface.onStartCall(json); // eslint-disable-line no-undef
        }
      }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onSubtitle = (userId, message) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          userId: userId,
          message: message
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onSubtitle.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onSubtitle(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onConnectionSuccess = (data) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode(data);

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onConnectionSuccess.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onConnectionSuccess(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onConnectionFailed = (err) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          error: err
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onConnectionFailed.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onConnectionFailed(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onCameraSuccess = (data) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode(data);

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onCameraSuccess.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onCameraSuccess(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onCameraFailed = (err) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          error: err
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onCameraFailed.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onCameraFailed(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onStopResponse = (reason, err) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          reason: reason,
          error: err
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onStopResponse.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onStopResponse(json); // eslint-disable-line no-undef
          }
        }
      } catch (err) {
        logger.warn(`Error: RioAppInterface: ${err?.message}`);
      }
  };

  /**
   * Call to app
   * @param {*} reason stop, disconnect,leave,error,param
   * @param {*} err 
   * @returns 
   */
  onStop = (reason, err, reload) => {
    logger.debug(`onStop: ${reason}`);
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          reason: reason,
          error: err
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onStop.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onStop(json); // eslint-disable-line no-undef
          }
        }

        RioHelper.setSession('isStopped', 1);
        if (reload !== false) {
          window.location.reload(true);
        }
      } catch (err) {
        logger.warn(`Error: RioAppInterface: ${err?.message}`);
      }
  };

  onChangeQuality = (quality) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode({
          quality: quality
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onChangeQuality.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onChangeQuality(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onScreenshot = (data) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        let json = RioHelper.encode(data, true);

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onScreenshot.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onScreenshot(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };
  
  onEmitEvent = (eventName, data) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        // eslint-disable-next-line no-undef no-unused-vars
        const { agentType } = this.options;
        const msTime = RioTimer.time();

        const params = {
          eventName: eventName,
          time: msTime,
          timeFormated: RioTimer.format(msTime),
        };
        let json = {};
        if (data && typeof data === "object") {
          json = RioHelper.encode(Object.assign({},
            data,
            params)
          );
        } else {
          json = RioHelper.encode(params);
        }

        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onEmitEvent.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            //RioInterface.onEmitEvent(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface: ${err?.message}`);
    }
  };

  onToggleSpeaker = (isSpeaker) => {
    try {
        let json = RioHelper.encode({
          enable: Boolean(isSpeaker)
        });
        logger.debug(`[debug] onToggleSpeaker ${isSpeaker}`, json);

        // eslint-disable-next-line no-undef
        if (RioHelper.isIosBrowser()) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onToggleSpeaker.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onToggleSpeaker(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: onToggleSpeaker: ${err?.message}`);
    }
  };

  onToggleMuted = (muted) => {
    try {
        let json = RioHelper.encode({
          enable: Boolean(muted)
        });
        logger.debug(`[debug] onToggleMuted ${muted}`, json);

        // eslint-disable-next-line no-undef
        if (RioHelper.isIosBrowser()) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onToggleMuted.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
              RioInterface.onToggleMuted(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: onToggleMuted: ${err?.message}`);
    }
  };

  onSetting = (data) => {
    try {
        let json = RioHelper.encode(data);
        logger.debug('[debug] onSetting', json);

        // eslint-disable-next-line no-undef
        if (RioHelper.isIosBrowser()) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onSetting.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onSetting(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: onSetting: ${err?.message}`);
    }
  };

  onChangeDeviceId = (options) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        const {micDeviceId} = options;
        let json = RioHelper.encode({
          deviceId: micDeviceId,
          type: 'audio'
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onChangeDeviceId.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onChangeDeviceId(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface.onChangeDeviceId: ${err?.message}`);
    }
  };

  onRequestAudioDevices = (options) => {
    if (!this.isEnable()) {
      return;
    }
    try {
        const {micDeviceId} = options;
        let json = RioHelper.encode({
          deviceId: micDeviceId || '',
        });

        const { agentType } = this.options;
        // eslint-disable-next-line no-undef
        if (agentType === Constants.AGENT.IOS) {
          // eslint-disable-next-line no-undef
          if (window.webkit !== undefined 
            && window.webkit.messageHandlers !== undefined) {
            window.webkit.messageHandlers.onRequestAudioDevices.postMessage(json);
          }
        } else {
          // eslint-disable-next-line no-undef
          if (typeof RioInterface === "object") {
            RioInterface.onRequestAudioDevices(json); // eslint-disable-line no-undef
          }
        }
    } catch (err) {
      logger.warn(`Error: RioAppInterface.onRequestAudioDevices: ${err?.message}`);
    }
  };
}

// create instance
const rioAppInterface = new RioAppInterface();
// lock instance
Object.freeze(rioAppInterface);

export default rioAppInterface;

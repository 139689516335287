/* global __filename, $, Promise */

import Constants from './Constants';
import { appConfig } from "./config";

import HttpService from './modules/http/HttpService';
import RioRoom from './RioRoom';
import RioRecording from './RioRecording';
import RioMedia from './modules/extends/RioMedia';
import RioHelper from './modules/extends/RioHelper';
import RioAuth from './modules/extends/RioAuth';

//import RioAppClient from './modules/extends/RioAppClient';
import RioAppInterface from './modules/extends/RioAppInterface';
import JitsiMediaDevices from './JitsiMediaDevices';
import * as JitsiMediaDevicesEvents from './JitsiMediaDevicesEvents';
import { delayTime } from './functions';
import logger from './modules/extends/RioLogger';

const { STATE } = Constants;

/**
 * Creates a RioApp object with the given name and properties.
 * Note: this constructor is not a part of the public API (objects should be
 * created using JitsiConnection.createConference).
 * @param options.config properties / settings related to the conference that
 * will be created.
 * @param options.name the name of the conference
 * @param options.connection the JitsiConnection object for this
 * RioApp.
 * @constructor
 *
 * FIXME Make all methods which are called from lib-internal classes
 *       to non-public (use _). To name a few:
 *       {@link RioApp.join}
 *       {@link RioApp.getCallInfo}
 *       {@link RioApp.setMute}
 *       and so on...
 */
export default function RioApp(options) {
    const { creds, users, token, app, settings, ...otherOptions } = options;

    this.isAuth = false;
    this.options = {};
    //set enable RioAppInterface
    RioAppInterface.setEnable();
    logger.info(`RioApp init:`, options);

    //init http
    HttpService.init(appConfig);
    //check creds setting
    const { secret_id, secret_key } = creds || {};
    if ( !RioHelper.isEmpty(creds) 
        && !RioHelper.isEmpty(secret_id) 
        && !RioHelper.isEmpty(secret_key)
        ) {
        this.options.token = token;
        //set creds
        RioAuth.setCreds(creds);
        this._initHttp();

        //get app config
        const { app_id, app_name, ...restOptions } = app;
        const { language, agentType } = settings || {};
        const allOptions = Object.assign({}
            ,{
                appId: app_id, 
                appName: app_name,
                language: language,
                agentType: agentType
            }
            , restOptions
        );
        this._init(allOptions);
        RioAppInterface.onEmitEvent('onInit');
        setTimeout(() => {
            RioMeetJS.videoroom.onAppInit(users);
        }, 100);
    } else {
        this._init({});
    }

    this.deviceChangeListener = devices =>
        window.setTimeout(() => this._onDeviceListChanged(devices), 0);

    JitsiMediaDevices.addEventListener(
        JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
        this.deviceChangeListener);

    const state = RioRoom.instance.getState();
    const isStopped = RioHelper.getSession('isStopped');
    RioHelper.removeSession('isStopped');

    $(window).on('beforeunload', ev => {
        logger.log(`beforeunload ${state}:${isStopped}`);
        if (isStopped != 1) {
            this.destroy();
            delayTime(600);
        }
    });
}

// FIXME convert JitsiConference to ES6 - ASAP !
RioApp.prototype.constructor = RioApp;

/**
 * Initializes the conference object properties
 * @param options {object}
 */
RioApp.prototype._init = async function(options) {
    // Override connection and xmpp properties (Useful if the connection
    // reloaded)
    this.callOptions = {
        language: ''
    };
    
    const allOptions = Object.assign({}
        , this.callOptions
        , options
    );
    allOptions.mode = Constants.MODE.API;

    RioRoom.instance.init(allOptions);
};

/**
 * Initializes the http service object properties
 * @param options {object}
 */
RioApp.prototype._initHttp = async function() {
    const creds = RioAuth.getCreds();
    if (!creds.secret_id || !creds.secret_key) {
        const errmsg = 'Invalid secret_id or secret_key';
        logger.error(errmsg);
        
        RioMeetJS.videoroom.onError('Unauthorized');
        RioAppInterface.onStop('error', 'Unauthorized');
        return Promise.reject('Unauthorized');
    }

    await HttpService.auth(creds)
    .then( (data) => {
        this.isAuth = true;
        const { token, app } = data;
        const { app_id, app_name, ...restOptions } = app;

        this.options.token = token;
    })
    .catch( err => {
        logger.log(`Error: RioApp initHttp: ${err?.message}`);
        RioMeetJS.videoroom.onError('Failed to authenticate');
        RioAppInterface.onStop('error', 'Failed to authenticate');
    });
};

RioApp.prototype.getCallInfo = function() {
    const {callOptions} = {...this};
    const callInfo = RioRoom.instance.getCallInfo();

    return Object.assign({}
        , callOptions
        , callInfo
    );
}

RioApp.prototype.getRemoteOptions = function() {
    return RioRoom.instance.getRemoteOptions();
}

RioApp.prototype.join = function(strJson) {
    try {
        const options = RioHelper.decode(strJson, {});
        logger.info('[STEP] 3 RioApp join', strJson);

        const {
            from, to, roomName, callId
        } = options || {};
        if (!from || !to || !roomName || !callId) {
            throw new Error('Invalid parameter');
        }

        const {callOptions} = {...this};
        const allOptions = Object.assign({}
            , callOptions
            , options
        );

        if (!this.isAuth) {
            logger.info(`RioApp join: ${this.isAuth} ${strJson} ${JSON.stringify(callOptions)}`);
            //throw new Error('Unauthorized');
        }

        allOptions.initiatorID = options.from;
        allOptions.currentUserID = options.currentUserID;
        allOptions.calleeId = options.to;

        if (allOptions.initiatorID === allOptions.currentUserID) {
            allOptions.userId = options.to;
            allOptions.type = 'caller';
        } else {
            allOptions.userId = options.from;
            allOptions.type = 'callee';
        }

        this.callOptions = allOptions;

        RioAppInterface.onEmitEvent('onJoined');
        RioRoom.instance.createConnection(options, allOptions);
        return allOptions;
    } catch (err) {
        logger.warn(`Error: [STEP] 3 RioApp join: ${err?.message}`, strJson);
        return RioAppInterface.onStop('param', 'Invalid parameter', false);
    }
}

/**
* start call
*
* @returns {void}
*/
RioApp.prototype.startCall = async (options = {}) => {
    return RioAppInterface.onStartCall(options);
};

/**
    * Mute or unmute audio. When muted, the ongoing local recording should
    * produce silence.
    *
    * @param {boolean} muted - If the audio should be muted.
    * @param {string} type - audio, video
    * @returns Promise
*/
RioApp.prototype.setMute = async function(muted, type = 'audio') {
    try {
        return RioRoom.instance._setMute(muted, type);
    } catch (err) {
        logger.log(`Error: [STEP] xx RioApp setMute: ${err?.message}`);
        return Promise.reject(err);
    }
};

/**
 * Sets the maximum video size the local participant should send to remote
 * participants.
 * @param {number} maxFrameHeight - The user preferred max frame height.
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.changeVideoQuality = function(type) {
    
    return RioRoom.instance.changeVideoQuality(type);
}

/**
 * Sets the maximum video size the local participant should send to remote
 * participants.
 * @param {Object} msg - The user preferred max frame height.
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.remoteChangeQuality = function(msg) {
    
    return RioRoom.instance.remoteChangeQuality(msg);
}

RioApp.prototype.voiceChange = async function(options) {
    const { value } = options;
    const effectOptions = {
        type: 'audio',
        enabled: true,
        value: Number(value),
    }

    if (value === 0 || value === '' ) {
        effectOptions.enabled = false;
    }
    
    RioRoom.instance.onToggleAudioEffect(effectOptions);
}

/**
 * Switch input audio device
 * 
 * @param options.micDeviceId micDeviceId
 * @param options.options array option
 *  
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.switchInputAudio = async function(options) {
    return RioRoom.instance.switchInputAudio(options);
}

/**
 * Switch ouput audio device
 * 
 * @param options.micDeviceId micDeviceId
 *  
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.switchOutputAudio = async function(options) {
    return RioRoom.instance.switchOutputAudio(options);
}

/**
 * Switch stream video
 * 
 * @param options.cameraDeviceId device 
 * 
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.switchCamera = async function(options) {
    return RioRoom.instance.switchCamera(options);
}

/**
* @inheritdoc
*
* Stops sending the media track. And removes it from the HTML. NOTE: Works for local tracks only.
*
* @extends JitsiTrack#dispose
* @returns {Promise}
*/
RioApp.prototype.disposeTrack = async function(options) {
    return RioRoom.instance.disposeTrack(options);
}

/**
 * check output audio 
 * 
 * @param options.micDeviceId device 
 * @param options.voice effected 
 * @param options.timeout number second recording 
 * 
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioApp.prototype.checkOutputAudio = async function(options) {
    return RioRoom.instance.checkOutputAudio(options);
}

RioApp.prototype.toggleSubtitle = function(options) {
    
    return RioRoom.instance.toggleSubtitle(options);
}
/**
* switch Speaker on/off
* @param {boolean} isSpeaker
*
* @returns {Promise}
*/
RioApp.prototype.toggleSpeaker = function(isSpeaker) {
    return RioRoom.instance.toggleSpeaker(isSpeaker);
}

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @param {Object} jitsiTrack - Represents the jitsi track that will have backgraund effect applied.
 * @param {Object} options - Represents the virtual background setted options.
 * @returns {Promise}
 */
RioApp.prototype.toggleBackgroundEffect = async function(track, options) {
    const {enabled, backgroundType} = options;
    const effectOptions = {
        enabled: Boolean(enabled),
        blurValue: options?.blurValue,
        backgroundType: (backgroundType == 'image') ? 'image' : 'video',
        backgroundEffectEnabled: (enabled) ? true : false,
        url: options?.url,
        canvasId: options?.canvasId,
        libPath: options?.libPath,
    };
    let videoTrack = track;
    if (track instanceof Array) {
        const found = track.filter((stream) => {
                return (stream.type && stream.type == 'video');
        });
        videoTrack = found.length ? found.shift() : false;
    }

    if (!videoTrack) {
        return Promise.reject(new Error('Stream invalid'));
    }
    return await RioRoom.instance.toggleBackgroundEffect(videoTrack, effectOptions);
}

RioApp.prototype.startLocalRecording = function (options) {
    const trackOption = RioRoom.instance.getRecordingOptions();

    return new Promise((resolve, reject) => {
        if (trackOption) {
            const recordOptions = Object.assign({}, options, trackOption);
            
            this.rioRecording = new RioRecording(recordOptions);
            RioRoom.instance.startRecording().then(() => {
                this.rioRecording.start();
                resolve();
            });
        } else {
            return reject('starting record was failed');
        }
    });
}

/**
 * stop local recording the current calling.
 *
 * @param options.upload Boolean auto upload to server
 * @returns {Promise<never>|*}
 */
RioApp.prototype.stopLocalRecording = function (isLocal) {
    if (this.rioRecording) {
        const {rioRecording} = this;
        this.rioRecording = null;

        return rioRecording.stop();
    }
    if (isLocal === true) {
        return true;
    } else {
        return Promise.reject(new Error('Recording not start'));
    }
}

/**
  * Stop call
  * this function called from application when socket receive stop
  *
  * @returns {void}
*/
RioApp.prototype.stopCall = function(strJson) {
    const options = RioHelper.decode(strJson);
    logger.log('RioApp stopCall decode', JSON.stringify(options));

    if (!options) {
        return RioAppInterface.onStop('param', 'Invalid parameter');
    }
    const state = RioRoom.instance.getState();
    const callInfo = RioRoom.instance.getCallInfo();

    const { initiatorID, currentUserID, calleeId } = callInfo;
    const { userId } = options;
    logger.log('RioApp stopCall callInfo', state, JSON.stringify(callInfo));

    if (state !== STATE.INITIAL) {
        if ( userId == calleeId
            || userId == initiatorID
            || userId == Constants.FORCE_STOP_ID ) {

            if ( currentUserID != userId ) {
                RioMeetJS.videoroom.onStopCallListener(userId);
            }
        }

        //call stop call
        this.stop(userId);
    }

    return true;
};

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
RioApp.prototype.stop = function(stopId) {
    const state = RioRoom.instance.getState();
    logger.info(`RioApp stop stopId:${stopId} state:${state}`);

    if (state === STATE.IN_CALL) {
        RioRoom.instance._disconnect();
        
        //check is stop recording
        this.stopLocalRecording(true);
    }
    if (state !== STATE.INITIAL) {
        RioAppInterface.onEmitEvent('onStop');
        if (stopId) {
            RioAppInterface.onStopResponse('stop', '');
        } else {
            RioAppInterface.onStop('stop', '');
        }
    }
}

/**
 * Creates and joins new conference.
 * @param name the name of the conference; if null - a generated name will be
 * provided from the api
 * @param options Object with properties / settings related to the conference
 * that will be created.
 * @returns {Promise} returns the new conference object.
 */
RioApp.prototype.getUserMedia = async function(mediaOptions = {}) {
    logger.info('[STEP] 7 RioApp getUserMedia', mediaOptions);
    try {
        return RioRoom.instance.getUserMedia(mediaOptions);
    } catch (err) {
        logger.log(`Error: [STEP] 7 RioApp getUserMedia: ${err?.message}`);
        return Promise.reject(err);
    }
};

/**
 * Removes all event listeners bound to the remote video track and clears
 * any related timeouts.
 *
 * @param {JitsiRemoteTrack} remoteTrack - The remote track which is being
 * removed from the conference.
 */
RioApp.prototype.attachMediaStream = async function (el, stream, options = {}) {
    try {
        logger.info('[STEP] 8 attachMediaStream el', el);
        RioMedia.setRemoteStreamId(el);
        RioRoom.instance.attachRemoteStream(el);
        await RioRoom.instance.attachMediaStream(el, stream, options);
    } catch (err) {
        logger.log(`Error: [STEP] 8 RioMedia attachMediaStream: ${err?.message}`);
    }
    return Promise.resolve();
}

RioApp.prototype.destroy = function() {
    this.stop();
    JitsiMediaDevices.removeEventListener(
        JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
        this.deviceChangeListener);
}

RioApp.prototype.error = function(reason, err) {
    RioAppInterface.onStop(reason, err);
}

/**
 * handle enter Picture-in-Picture events
 * requests that the video enter Picture-in-Picture mode
 * @param {Boolean} localTrack
 * localTrack = true, set PIP on JitsiRemoteTrack
 * localTrack = false, set PIP on JitsiLocalTrack
 */
RioApp.prototype.enterPIP = async function(localTrack) {
    const state = RioRoom.instance.getState();
    if (state !== STATE.IN_CALL) {
        return true;
    }

    RioRoom.instance.setReloaded(false);
    return await RioMedia.enterPIP(localTrack);
}

/**
 * handle exit Picture-in-Picture events
 * requests that the video exit Picture-in-Picture mode
 * @param {Boolean} localTrack
 * localTrack = true, set PIP on JitsiRemoteTrack
 * localTrack = false, set PIP on JitsiLocalTrack
 */
 
RioApp.prototype.leavePIP = async function(localTrack) {
    const state = RioRoom.instance.getState();
    if (state !== STATE.IN_CALL) {
        return true;
    }
    return await RioMedia.leavePIP(localTrack);
}

/**
 * Application send to background
 * @param {Object} options
 */
RioApp.prototype.onPause = function(options) {
    return RioRoom.instance.onPause(options);
}

/**
 * Application resume from background
 * @param {Object} options
 */
RioApp.prototype.onResume = function(options) {
    return RioRoom.instance.onResume(options);
}

/**
 * Set HTML ID video config
 * @param options Object with properties / settings
 * @returns {void}
 */
 RioApp.prototype.setSessionConfigs = function(options = {}) {
    const defaultOption = {
        localStreamId: 'localStream',
        remoteStreamId: 'remoteStreamId',
    }
    const configOptions = {...defaultOption, ...options};
    const {
        localStreamId, 
        remoteStreamId,
        avatarUrl
    } = configOptions;

    RioMedia.startCall();
    RioMedia.setLocalStreamId(localStreamId);
    RioMedia.setRemoteStreamId(remoteStreamId);
    if(avatarUrl) {
        RioMedia.setAvatar(avatarUrl);
    }
};

/**
* Event listener for JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED to
* handle change of available media devices.
* @private
* @param {MediaDeviceInfo[]} devices
* @returns {Promise}
*/
RioApp.prototype._onDeviceListChanged = function(devices) {
    RioMeetJS.videoroom.onDeviceListChanged(devices);
}

/*global RioAuth */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import logger from './RioLogger';
import RioHelper from './RioHelper';
import UtilStore from '../http/UtilStore';

class RioAuth {
  options = {
    _key: '__rioauth__',
    creds: ''
  };

  constructor() {
    this.store = new UtilStore();
    this.store.setSalt(`${this._key}`);
  }
  
  // Properties & Methods
  /**
  * Initializes the http service object properties
  * @param options {object}
  *
  * @returns {Boolean}
  */
  setCreds = (options) => {
    const { secret_id, secret_key, ...otherOptions } = options || {};
    this.options.creds = '';
    try {
      if ( !RioHelper.isEmpty(options) && secret_id && secret_key) {
        const creds = {
              secret_id: secret_id,
              secret_key: secret_key
        };
        this.store.setSalt(`${secret_key}`);

        const strCreds = this.store.strCrypt(creds);
        this.options.creds = strCreds;
        return true;
      }
    } catch (err) {
        logger.log(`Error: RioAuth setCreds: ${err?.message}`);
    }
    return false;
  };

  /**
  * get secret information from store
  *
  * @returns {object}
  */
  getCreds = () => {
    try {
        const creds = this.store.strDecrypt(this.options.creds);
        return creds;
    } catch (err) {
        logger.log(`Error: RioAuth getCreds: ${err?.message}`);
    }

    return '';
  };

  getApp = () => {
    return (this.options && this.options['rioApp']) 
        ? this.options['rioApp'] 
        : false;
  };
}

// create instance
const rioAuth = new RioAuth();
// lock instance
Object.freeze(rioAuth);

export default rioAuth;

/* global config */
import { getLogger } from '@jitsi/logger';
import HttpService from './modules/http/HttpService';

const logger = getLogger(__filename);

const STATE = {
    INITIAL: 0,
    START: 1,
    PAUSE: 2,
}

export default class RioRecording {
    constructor(options) {
        const { interval, callId, autoUpload, micDeviceId, ...otherOptions } = options;

        this.state = STATE.INITIAL;
        this.count = 0;
        this.callId = callId;
        this.micDeviceId = micDeviceId || 'default';
        this.options = otherOptions;

        this.autoUpload = Boolean(autoUpload);
        this.interval = parseInt(interval, 10);
        this.internalRecording = null;

        if (this.autoUpload && !this.interval) {
            this.interval = 5;
        }
        if (autoUpload === undefined && this.interval) {
            this.autoUpload = true;
        }
        if (this.interval > 10) {
            this.interval = 10;
        }
        this.startTime = null
        this.endTime = null
    }
}

/**
 * Start local recording the current calling.
 *
 * @param options
 * @returns {Promise<never>|*}
 */
 RioRecording.prototype.start = async function() {
    this.state = STATE.START;
    this.count = 0;

    this.startTime = this.currentTimeStamp();
    //wait 1000ms
    await new Promise(resolve => setTimeout(resolve, 1000));

    return Promise.resolve();
};

/**
 * Stop local recording the current calling.
 *
 * @returns {Promise<never>|*}
 */
 RioRecording.prototype.stop = async function() {
    this.endTime = this.currentTimeStamp();
    if (this.state !== STATE.START) {
        return Promise.resolve();
    }
    this.state = STATE.INITIAL;

    return new Promise(async (resolve, reject) => {
        await HttpService.trackRecording({
            call_id: this.callId,
            file: '',
            type: 2,
            start_time: this.startTime,
            end_time: this.endTime
        })
        .then( (response) => {
            resolve(response);
        })
        .catch( err => {
            logger.log(`Error: RioRecording stop: ${err?.message}`);
            reject(`Failed to upload file to server`);
        });
    });
};

/**
 * Stop local recording the current calling.
 *
 * @returns {Promise<never>|*}
 */
 RioRecording.prototype._uploadRecording = async function(blob, format, mimeType) {
    return new Promise(async (resolve, reject) => {
        if ( this.autoUpload ) {
            const fname = this._getFileName(format);

            const file = new File([blob], fname, {
                type: mimeType
            });

            await HttpService.uploadRecoding(file, {
                call_id: this.callId,
                fname: fname,
                type: 2,
                start_time: this.startTime,
                end_time: this.endTime
            })
            .then( (response) => {
                resolve(blob);
            })
            .catch( err => {
                logger.log(`Error: RioRecording _uploadRecording: ${err?.message}`);
                reject(`Failed to upload file to server`);
            });
        } else {
            this._downloadRecording(blob, format);
            resolve(blob);
        }
    });
};

/**
 *
 * @returns {String}
 */
 RioRecording.prototype._downloadRecording = function(blob, format) {
    const base64Url = window.URL.createObjectURL(blob);
    // fake a anchor tag
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = base64Url;
    a.download = this._getFileName(format);
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
    }, 0)
};

/**
 *
 * @returns {String}
 */
 RioRecording.prototype._getFileName = function(format) {
    const date = new Date();

    const now = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getTime()}`
    return `recording_${now}.${format}`;
};

 RioRecording.prototype.currentTimeStamp = function() {
    return (new Date()).getTime();
 }

/* global config */
import { getLogger } from '@jitsi/logger';
import * as JitsiConferenceEvents from './JitsiConferenceEvents';
import recordingConstants from './modules/recording/recordingConstants';
import HttpService from './modules/http/HttpService';

const logger = getLogger(__filename);

export default class RioServerRecording {
    constructor(options) {
        const { callId, roomName, conference, ...otherOptions } = options;

        this.callId = callId;
        this.roomName = roomName;
        this._conference = conference;
        this._sessionID = null;
        this._started = false;
        this._isSendStartRecording = false;

        this._conference.on(JitsiConferenceEvents.RECORDER_STATE_CHANGED,
            recorderSession => {
                if (recorderSession && recorderSession.getStatus() == 'on') {
                    this._sessionID = recorderSession.getID();

                    if (this._isSendStartRecording === false) {
                        HttpService.callUpdate({
                            call_id: this.callId,
                            jibri_session_id: this._sessionID,
                            jibri_start_time: this.currentTimeStamp(),
                        })
                        .then( (response) => {
                            //logger.log(response);
                        })
                        .catch( err => {
                            logger.log(`Error: RioServerRecording Failed to upload file to server: ${err?.message}`);
                        });
                        this._isSendStartRecording = true;
                    }
                }
        });
        //console.log('RioServerRecording', options);
    }
}

/**
 * Starts recording the current conference.
 *
 * @param {Object} options - Configuration for the recording. See
 * {@link Chatroom#startRecording} for more info.
 * @returns {Promise} See {@link Chatroom#startRecording} for more info.
 */
RioServerRecording.prototype.startRecording = function () {
    try {
        if (this._conference && this._started === false) {
            const appData = JSON.stringify({
                'callId': this.callId,
                'roomName': this.roomName,
                'file_recording_metadata': {
                    'share': true
                }
            });
            const options = {
                mode: recordingConstants.mode.FILE,
                appData
            }
            this._started = true;
            return this._conference.startRecording(options);
        }
    } catch (err) {
      logger.log(`Error: ${err?.message}`);
    }

    return Promise.resolve();
};

/**
* Stop a recording session.
*
* @param {string} sessionID - The ID of the recording session that
* should be stopped.
* @returns {Promise} See {@link Chatroom#stopRecording} for more info.
*/
RioServerRecording.prototype.stopRecording = function () {
    if (this._conference && this._sessionID && this._started) {
        this._started = false;

        return this._conference.stopRecording(this._sessionID);
    }

    return Promise.resolve();
};

RioServerRecording.prototype.currentTimeStamp = function() {
    return (new Date()).getTime();
}
/* global config */
import EventEmitter from 'events';
import logger from './RioLogger';

import {
    EVT_APP_ON_PAUSE,
    EVT_RELOAD_CAMERA,
    CMD_APP_ON_PAUSE,
    CMD_AVATAR_URL,
    CMD_RELOAD_CAMERA,
    CMD_EMAIL,
    CMD_REMPOTE_CALL_OPTION,
    EVT_REMPOTE_CALL_OPTION
} from './constants';

class RoomListener {
    options = {
        room: null,
        started: false,
        currentUserID: null
    };

    /**
    * list command lister
    */
    commands = [CMD_AVATAR_URL
        , CMD_RELOAD_CAMERA
        , CMD_APP_ON_PAUSE
        , CMD_REMPOTE_CALL_OPTION];

    constructor() {
        this.eventEmitter = new EventEmitter();

        this._onAppPauseResume
            = this._onAppPauseResume.bind(this);

        this._onRemoteCallInfo
            = this._onRemoteCallInfo.bind(this);

        this._onReloadCamera
            = this._onReloadCamera.bind(this);
    }

    /**
    * new room start
    * @param {Object} room - Rio Room
    *
    * @returns {void}
    */
    initRoom = (room, {...options}) => {
        const { currentUserID, ...otherOptions } = options;

        this.options.currentUserID = currentUserID;
        this.options.room = room;

        this._setupListeners();
    };
}

/**
 * Creates and joins new conference.
 * @param name the name of the conference; if null - a generated name will be
 * provided from the api
 * @param options Object with properties / settings related to the conference
 * that will be created.
 * @returns {JitsiConference} returns the new conference object.
 */
RoomListener.prototype._setupListeners = function() {
    const {room} = this.options;

    room.addCommandListener(CMD_AVATAR_URL, (data, id) => {
        logger.debug('addCommandListener CMD_AVATAR_URL', data, id, room.myUserId());
    });
    
    room.addCommandListener(CMD_APP_ON_PAUSE, this._onAppPauseResume);
    room.addCommandListener(CMD_REMPOTE_CALL_OPTION, this._onRemoteCallInfo);
    room.addCommandListener(CMD_RELOAD_CAMERA, this._onReloadCamera);
};

/**
 * send call information to remote
 * 
 * @public
 * @returns {void}
 */
RoomListener.prototype.sendCallInfo = function(options) {
    const data = {...options};
    logger.debug('sendCallInfo', options, data);

    this._sendAttrOnce(CMD_REMPOTE_CALL_OPTION, data);
};

/**
 * send signal onPause
 * 
 * @public
 * @returns {void}
 */
RoomListener.prototype.sendPause = function() {
    this._sendDataOnce(CMD_APP_ON_PAUSE, 'true');
};

/**
 * send signal onResume
 * 
 * @public
 * @returns {void}
 */
RoomListener.prototype.sendResume = function() {
    this._sendDataOnce(CMD_APP_ON_PAUSE, 'false');
};


/**
 * send reload camera
 * 
 * @public
 * @returns {void}
 */
RoomListener.prototype.sendReloadCamera = function() {
    this._sendDataOnce(CMD_RELOAD_CAMERA, 'true');
};

/**
 * destroy class
 * 
 * @private
 * @returns {void}
 */
RoomListener.prototype.destroy = function() {
    const {room} = this.options;
    this.options.room = null;

    if (!room) {
        return;
    }
    this.commands.forEach( (cmd) => {
        room.removeCommand(cmd);
    });

    room.removeCommandListener(CMD_RELOAD_CAMERA, this._onReloadCamera);
    room.removeCommandListener(CMD_APP_ON_PAUSE, this._onAppPauseResume);
    room.removeCommandListener(CMD_REMPOTE_CALL_OPTION, this._onRemoteCallInfo);
};

/**
 * update data when user pause/resume app
 * @param command the command
 * @param {string} value new value
 */
RoomListener.prototype._onAppPauseResume = function (data, id) {
    const {room} = this.options;
    if (!room) {
        return;
    }
    if (id == room.myUserId()) {
        return;
    }
    const {value} = data;
    logger.debug('_onAppPauseResume', value, data, id, room.myUserId());

    this.eventEmitter.emit(EVT_APP_ON_PAUSE, value);
}

/**
 * update data when user pause/resume app
 * @param command the command
 * @param {string} value new value
 */
RoomListener.prototype._onReloadCamera = function (data, id) {
    const {room} = this.options;
    if (!room) {
        return;
    }
    if (id == room.myUserId()) {
        return;
    }
    const {value} = data;
    this.eventEmitter.emit(EVT_RELOAD_CAMERA, value);
}

/**
 * on remote call start join send call info
 * @param command the command
 * @param {string} value new value
 */
RoomListener.prototype._onRemoteCallInfo = function (data, id) {
    const {room} = this.options;
    if (!room) {
        return;
    }
    const {attributes} = data;
    logger.debug(`RemoteCallInfo ${id}`, data);
    if (id == room.myUserId()) {
        return;
    }

    this.eventEmitter.emit(EVT_REMPOTE_CALL_OPTION, attributes);
}

/**
 * Share data to other users.
 * @param command the command
 * @param {string} value new value
 */
RoomListener.prototype._sendData = function (command, value) {
    const {room} = this.options;
    if (!room) {
        return;
    }

    room.removeCommand(command);
    room.sendCommand(command, { value });
}

/**
 * Share attribute to other users.
 * @param command the command
 * @param {Object} atts new value
 */
RoomListener.prototype._sendAttr = function (command, atts) {
    const {room} = this.options;
    if (!room) {
        return;
    }

    room.removeCommand(command);
    room.sendCommand(command, {
        attributes: atts
    });
}

/**
 * Share data to other users.
 * @param command the command
 * @param {string} value new value
 */
RoomListener.prototype._sendDataOnce = function (command, value) {
    const {room} = this.options;
    if (!room) {
        return;
    }
    room.sendCommandOnce(command, { value });
}

/**
 * Share attribute to other users.
 * @param command the command
 * @param {Object} atts new value
 */
RoomListener.prototype._sendAttrOnce = function (command, atts) {
    const {room} = this.options;
    if (!room) {
        return;
    }

    room.sendCommandOnce(command, {
        attributes: atts
    });
}

/**
 * Attaches a handler for events(For example - "participant joined".) in the
 * conference. All possible event are defined in JitsiConferenceEvents.
 * @param eventId the event ID.
 * @param handler handler for the event.
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
RoomListener.prototype.on = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};

/**
 * Removes event listener
 * @param eventId the event ID.
 * @param [handler] optional, the specific handler to unbind
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
RoomListener.prototype.off = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.removeListener(eventId, handler);
    }
};

// Common aliases for event emitter
RoomListener.prototype.addEventListener = RoomListener.prototype.on;
RoomListener.prototype.removeEventListener = RoomListener.prototype.off;

const roomListener = new RoomListener()
Object.freeze(roomListener);

export default roomListener;

/* global config */
import EventEmitter from 'events';
import { appConfig } from "./config";
import { conferenceConfig } from "./conferenceConfig";
import {
    EVT_APP_ON_PAUSE,
    EVT_RELOAD_CAMERA,
    EVT_REMPOTE_CALL_OPTION
} from './modules/extends/constants';

import browser from './modules/browser';
import JitsiConnection from './JitsiConnection';
import * as JitsiConnectionEvents from './JitsiConnectionEvents';
import * as JitsiConferenceEvents from './JitsiConferenceEvents';
import * as JitsiTrackEvents from './JitsiTrackEvents';
import JitsiMediaDevices from './JitsiMediaDevices';
import { MediaType } from './service/RTC/MediaType';
import { VideoType } from './service/RTC/VideoType';

import SocketService from './modules/socket/SocketService';
import Constants from './Constants';
import RioServerRecording from './RioServerRecording';
import RioScreenshot from "./RioScreenshot";
import RioSubtitle from './RioSubtitle';
import RioMedia from './modules/extends/RioMedia';
import RioHelper from './modules/extends/RioHelper';
import RioAppInterface from './modules/extends/RioAppInterface';
import RoomListener from './modules/extends/RoomListener';
import RioLogTracking from './modules/extends/RioLogTracking';
import RioConference from './modules/extends/RioConference';

import AudioEffect from "./modules/effects/audio/AudioEffect";
import DeepArEffect from "./modules/effects/DeepArEffect";

//import { createRnnoiseProcessor } from './modules/effects/rnnoise';
import AudioRecording from "./modules/effects/audio/AudioRecording";
import RTCEvents from './service/RTC/RTCEvents';
import RTCUtils from './modules/RTC/RTCUtils';
import { delayTime } from './functions';
import logger from './modules/extends/RioLogger';
import RioTimer from './modules/extends/RioTimer';

const { STATE } = Constants;

export default class RioRoom {
    constructor() {
        this.mode = Constants.MODE.WEB;
        this.localTracks = [];
        this.remoteTracks = [];
        this.callInfo = {};
        this.userId = null;
        this.options = {};
        this.eventEmitter = new EventEmitter();

        this.transcriptionLanguage = conferenceConfig.transcriptionLanguage;
        this._init();
    }
    /**
     * The list of {@link XmppConnection} events.
     *
     * @returns {Object}
     */
    static get instance() {
        if (RioRoom._instance === undefined) {
            RioRoom._instance = new this();
        }
        return RioRoom._instance;
    }

    /**
     * The list of {@link XmppConnection} events.
     *
     * @returns {Object}
     */
    init(options={}) {
        this.retry = 0;
        this.timeoutInternal = null;
        this.room = null;
        this.connection = null;
        this.callInfo = {};
        this.userId = null;
        this.options = options;
        this.state = STATE.WAITING;

        //set filter flag
        this.options['toggleFilter'] = {};

        if (options.mode && options.mode !== Constants.MODE.WEB) {
            this.mode = options.mode;
        }
        this.callData = {
            callQuality: Constants.Quality.MEDIUM
        };

        this._onConnectionSuccess
            = this._onConnectionSuccess.bind(this);
        this._onConnectionFailed
            = this._onConnectionFailed.bind(this);
        this._onDisconnect
            = this._onDisconnect.bind(this);

        this._onRemoteTrack
            = this.onRemoteTrack.bind(this);
        this._onRemoteTrackRemoved = 
            this.onRemoteTrackRemoved.bind(this);

        this._onRemoteTrackMuted
            = this.onRemoteTrackMuted.bind(this);

        this._onConferenceJoined
            = this._onConferenceJoined.bind(this);
        
        this._onUserLeft
            = this._onUserLeft.bind(this);
        this._onUserJoined
            = this._onUserJoined.bind(this);

        this._onRemotePauseResume
            = this._onRemotePauseResume.bind(this);

        this._onRemoteCallInfo
            = this._onRemoteCallInfo.bind(this);

        this._onReloadCamera
            = this._onReloadCamera.bind(this);
        
        const screenSizes = RioHelper.getScreenSize();
        logger.debug(
            `RioRoom init ${JSON.stringify(options)}`
            + ` devicePixelRatio ${window.devicePixelRatio}`
            + ` options ${JSON.stringify(screenSizes)}`
            + ` isIosBrowser ${browser.isIosBrowser()}`
            + ` isWebKitBased ${browser.isWebKitBased()}`
            + ` isSupported ${browser.isSupported()}`
            + ` isSupportedAndroidBrowser ${browser.isSupportedAndroidBrowser()}`
            + ` isSupportedIOSBrowser ${browser.isSupportedIOSBrowser()}`
        );
    }
}

/**
 * get remoteTracks
 *
 * @returns {Array} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
 RioRoom.prototype.getRemoteTracks = function() {
    const {remoteTracks} = this;
    let tracks = [];
    Object.keys(remoteTracks).forEach( (pId) => {
        if (remoteTracks[pId].length > 0) {
            tracks = tracks.concat(remoteTracks[pId]);
        }
    });
    return tracks;
}

/**
 * get localTracks
 *
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioRoom.prototype.getLocalTracks = function(mediaType) {
    let tracks = this.localTracks || [];

    if (mediaType !== undefined) {
        tracks = tracks.filter(
            track => track.getType() === mediaType);
    }

    return tracks;
    //return this.room.getLocalTracks(mediaType);
}

/**
 * set user ID
 *
 */
RioRoom.prototype.setUserId = function(userId) {
    return this.userId = userId;
}

/**
 * set room options
 *
 */
RioRoom.prototype.setOption = function(key, val) {
    this.options[`${key}`] = `${val}`;
}

/**
 * Initializes the conference object properties
 * @param options {object}
 * @param options.connection {JitsiConnection} overrides this.connection
 */
RioRoom.prototype.createConnection = function(wsJson, options) {
    const {language, initiatorID, currentUserID, userId} = options;
    const {
        from, roomName, callId, startTime, to, callType
        , acceptCallType
        , fromAgentType
        , toAgentType
    } = wsJson;
    this.state = STATE.PROCESSING;

    this.callInfo = {
        from,
        to,
        callId,
        callType: this._getCallType(callType),
        acceptCallType: this._getCallType(acceptCallType, callType),
        fromAgentType,
        toAgentType,
        language,
        roomName: `${roomName.toLowerCase()}`,
        startTime,
        calleeId: to,
        initiatorID: initiatorID,
        currentUserID: currentUserID
    }
    this.startTime = startTime;
    if (language) {
        this.transcriptionLanguage = language;
    }
    if (userId) {
        this.setUserId(userId);
    }

    logger.info('[STEP] 4 createConnection', options, wsJson, this.callInfo);
    RioTimer.debug(`createConnection ${callId}`);
    RioMedia.startCall();
    this._connect();
};

/**
 * Initializes the conference object properties
 * @param options {object}
 * @param options.connection {JitsiConnection} overrides this.connection
 */
 RioRoom.prototype.reconnect = async function() {
    this.timeoutInternal = setTimeout( () => {
        this._connect();
    }, 1000);
};

/**
 * Sets the maximum video size the local participant should send to remote
 * participants.
 * @param {number} quality - The user preferred max frame height.
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
 RioRoom.prototype.changeVideoQuality = function(quality) {
    try {
        if (this.room) {
            const { callQuality } = this.callData;
            let userChanged = true;
            if (!quality) {
                userChanged = false;
                quality = callQuality || Constants.Quality.MEDIUM;
            } else {
                this.callData['callQuality'] = quality;
            }

            const customs = this._getAppConfig(quality);
            const {resolution} = customs;
            if (!resolution) {
                return;
            }
            let newQuality = quality,
                newResolution = resolution,
                remoteRes = 0;

            if (userChanged) {
                const {remoteQuality, cnt} = this.callData;
                if(remoteQuality != quality) {
                    const remoteInfo = this._getAppConfig(remoteQuality);
                    remoteRes = remoteInfo?.resolution;
                }

                if (remoteRes && remoteRes <= resolution) {
                    newQuality = remoteQuality;
                    newResolution = remoteRes;
                }
                this.callData['cnt'] = cnt+1;

                logger.debug(`changeVideoQuality ${quality}:${newQuality} ${resolution}:${newResolution} ${this.callData}`);
            }
            
            RioMeetJS.setResolution(newResolution);
            RTCUtils.setResolution(newResolution);

            this.room.setSenderVideoConstraint(newResolution);

            this._updateReceiverVideoConstraints(newQuality);
            if (userChanged) {
                if (this.mode !== Constants.MODE.API) {
                    SocketService.instance.changeQuality(quality);
                } else {
                    RioAppInterface.onChangeQuality(quality);
                }
            }
        }
    } catch (err) {
        logger.warn(`Error: failed to set receiver video constraints ${quality}: ${err?.message}`);
    }
}

/**
 * Sets the maximum video size the local participant should send to remote
 * participants.
 * @param {number} type - The user preferred max frame height.
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
 RioRoom.prototype.remoteChangeQuality = function(msg) {
    try {
        if (this.room) {
            const {quality, userId} = msg;
            const { callQuality } = this.callData;

            const customs = this._getAppConfig(callQuality);
            const { resolution } = customs;
            const { callInfo } = {...this};
            const { currentUserID } = callInfo;
            let newQuality = callQuality,
                newResolution = resolution,
                chkResolution = 0;

            if (resolution && userId != currentUserID) {
                if(quality != callQuality) {
                    const newCustoms = this._getAppConfig(quality);
                    chkResolution = newCustoms?.resolution;
                }

                if (chkResolution && chkResolution <= resolution) {
                    newQuality = quality;
                    newResolution = chkResolution;
                }
                this.callData['remoteQuality'] = quality;

                logger.debug(`remoteChangeQuality ${quality}:${newQuality} ${resolution}:${newResolution} ${JSON.stringify(this.callData)}`);

                RioMeetJS.setResolution(newResolution);
                RTCUtils.setResolution(newResolution);
                this.room.setSenderVideoConstraint(newResolution);
                this._updateReceiverVideoConstraints(newQuality);
            }
        }
    } catch (err) {
        logger.warn(`Error: Failed to set receiver video constraints: ${err?.message}`);
    }
}

RioRoom.prototype.voiceChange = function(options) {
    try {
        if (this.room && this.mode !== Constants.MODE.API) {
            SocketService.instance.voiceChange(options);
        }
    } catch (err) {
        logger.warn(`Error: Failed to voice ${JSON.stringify(options)}: ${err?.message}`);
    }
}

/**
 * Toggle the local property 'requestingTranscription'. This will cause Jicofo
 * and Jigasi to decide whether the transcriber needs to be in the room.
 *
 * @param {Store} store - The redux store.
 * @private
 * @returns {void}
 */
RioRoom.prototype.toggleSubtitle = function(options) {
    try {
        if (this.room) {
            const { callInfo } = {...this};
            const { callId, initiatorID, currentUserID, calleeId } = callInfo;
            if (!this.rioSubtitle) {
                this.rioSubtitle = new RioSubtitle(this.room, {
                    initiatorID,
                    calleeId,
                    userId: currentUserID, 
                    callId: callId, 
                });
            }
            return this.rioSubtitle.toggle(options);
        }
        return Promise.reject(`The room not start`);
    } catch (err) {
        logger.warn(`Error: Failed to toggle subtitle ${JSON.stringify(options)}: ${err?.message}`);
        return Promise.reject(`Failed to toggle subtitle`);
    }
}

/**
 * Initializes the conference object properties
 * @param options {object}
 * @param options.connection {JitsiConnection} overrides this.connection
 */
 RioRoom.prototype._connect = function() {
    const { endpoints, ...options } = appConfig;
    const { appId, creds, ...restOptions } = this.options;
    const { callInfo } = {...this};
    const { roomName } = callInfo;

    this.serverRecording = null;
    this.state = STATE.WAITING;

    //remove all event lister
    this._removeEvents(true).then( () => {
        //this.localTracks = [];
        //this.remoteTracks = [];

        options.websocketKeepAlive = (5*60*1000);

        let serviceUrl = options.bosh || options.serviceUrl;
        options.serviceUrl = `${serviceUrl}?room=${roomName}`;

        if (options.websocketKeepAliveUrl) {
            options.websocketKeepAliveUrl += `?room=${roomName}`;
        }

        this.connection = new JitsiConnection(null, null, options);

        this.connection.addEventListener(
            JitsiConnectionEvents.CONNECTION_ESTABLISHED,
            this._onConnectionSuccess);

        this.connection.addEventListener(
            JitsiConnectionEvents.CONNECTION_FAILED,
            this._onConnectionFailed);

        this.connection.addEventListener(
            JitsiConnectionEvents.CONNECTION_DISCONNECTED,
            this._onDisconnect);

        this.connection.connect();
        clearTimeout(this.timeoutInternal);
    });
};

/**
 * That function is called when connection is established successfully
 */
RioRoom.prototype._onConnectionSuccess = function () {
    const { appId, token, ...restOptions } = this.options || {};
    const { callInfo } = {...this};
    const { roomName, callId, initiatorID, currentUserID, from, to, callType } = callInfo;
    const { screen_capture_interval } = this.options;
    this.state = STATE.IN_CALL;

    RioTimer.debug(`_onConnectionSuccess ${callId}`);
    RioAppInterface.onEmitEvent('onConnectionSuccess');
    this._removeRoomEvents(true).then(() => {
        //the user option get from API
        const { callQuality } = this.callData;
        const customs = this._getAppConfig(callQuality);
        const {resolution} = customs;
        RioMeetJS.setResolution(resolution);
        RTCUtils.setResolution(resolution);
        //set call data for quality
        this.callData = {
            cnt: 0,
            callQuality: callQuality,
            remoteQuality: callQuality
        };

        const callOptions = {
            appId,
            //callId,
            callType,
            initiatorID, 
            currentUserID,
            from, 
            to,
            maxHeight: this._getMaxResolution()
        };

        const options = Object.assign({}, 
            callOptions,
            conferenceConfig,
            customs
        );

        if (this.transcriptionLanguage) {
            options.transcriptionLanguage = this.transcriptionLanguage;
        }
        const copyOptions = {...callOptions, ...callInfo};
        //options.createVADProcessor = createRnnoiseProcessor;
        logger.info('[STEP] 5 onConnectionSuccess', options, copyOptions);

        const newRoom = this.connection.initJitsiConference(roomName, options);
        newRoom.on(
            JitsiConferenceEvents.TRACK_ADDED,
            this._onRemoteTrack);

        newRoom.on(
            JitsiConferenceEvents.TRACK_REMOVED,
            this._onRemoteTrackRemoved);

        newRoom.on(
            JitsiConferenceEvents.CONFERENCE_JOINED,
            this._onConferenceJoined);

        newRoom.on(JitsiConferenceEvents.USER_JOINED, this._onUserJoined);
        newRoom.on(JitsiConferenceEvents.USER_LEFT, this._onUserLeft);
        newRoom.on(JitsiConferenceEvents.TRACK_MUTE_CHANGED, this._onRemoteTrackMuted);

        newRoom.on(
            JitsiConferenceEvents.TRACK_AUDIO_LEVEL_CHANGED,
            (userId, audioLevel) => {
                //console.log(`${userId} - ${audioLevel}`)
            });
        newRoom.on(
            JitsiConferenceEvents.PHONE_NUMBER_CHANGED,
            () => logger.debug(`${newRoom.getPhoneNumber()} - ${newRoom.getPhonePin()}`));

        RoomListener.initRoom(newRoom, {...copyOptions, mode: this.mode});
        RoomListener.on(
            EVT_APP_ON_PAUSE,
            this._onRemotePauseResume);

        RoomListener.on(
            EVT_REMPOTE_CALL_OPTION,
            this._onRemoteCallInfo);

        RoomListener.on(
            EVT_RELOAD_CAMERA,
            this._onReloadCamera);

        RioConference.onCreateRoom(newRoom, {...copyOptions, mode: this.mode});
        newRoom.join();
        this.room = newRoom;
        
        if (initiatorID == currentUserID) {
            if (this.mode !== Constants.MODE.API) {
                SocketService.instance.startRoom(currentUserID, this.retry);
            }
            const timerCapture = parseInt(screen_capture_interval, 10);
            //disable ios capture screen
            //const enableCapture = !(browser.isIosBrowser() && this.mode === Constants.MODE.API);
            if (timerCapture > 0 
                //&& enableCapture
                ) {
                this.screenshot = new RioScreenshot({...copyOptions, timerCapture: timerCapture, mode: this.mode});
            }
            this.room.setDisplayName(from);
        } else {
            this.room.setDisplayName(to);
        }

        RioLogTracking.initRoom(this.connection, {...copyOptions, mode: this.mode});
        //if (!this.retry) {
        //}
        //reset retry
        this.retry = 0;
    });
}

/**
 * This function is called when we disconnect.
 */
 RioRoom.prototype._removeEvents = function(reconnect) {
    if (!this.connection) {
        return Promise.resolve();
    }
    const { connection } = this;

    RoomListener.off(
        EVT_APP_ON_PAUSE,
        this._onRemotePauseResume);

    RoomListener.off(
        EVT_REMPOTE_CALL_OPTION,
        this._onRemoteCallInfo);

    RoomListener.off(
        EVT_RELOAD_CAMERA,
        this._onReloadCamera);

    return new Promise((resolve, reject) => {
        connection.removeEventListener(
            JitsiConnectionEvents.CONNECTION_ESTABLISHED,
            this._onConnectionSuccess);
    
        connection.removeEventListener(
            JitsiConnectionEvents.CONNECTION_FAILED,
            this._onConnectionFailed);
    
        connection.removeEventListener(
            JitsiConnectionEvents.CONNECTION_DISCONNECTED,
            this._onDisconnect);
    
        return resolve();
    });
}

/**
 * This function is called when we disconnect.
 */
 RioRoom.prototype._removeRoomEvents = function() {
    if (!this.room) {
        return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
        const {room, localTracks, rioSubtitle} = this;
        try {
            room.off(
                JitsiConferenceEvents.TRACK_ADDED,
                this._onRemoteTrack);
        
            room.off(
                JitsiConferenceEvents.CONFERENCE_JOINED,
                this._onConferenceJoined);
        
            room.off(JitsiConferenceEvents.USER_JOINED, this._onUserJoined);
            //destroy rioSubtitle
            if (rioSubtitle) {
                rioSubtitle.destroy();
            }
            //RioConference destroy
            RioConference.destroy();

            delete this.room;
            delete this.localTracks;
            delete this.remoteTracks;
            delete this.rioSubtitle;

            this.room = null;
            this.localTracks = [];
            this.remoteTracks = [];
            this.rioSubtitle = null;
            //this.connection = null;

            room.leave().then(() => {
                room.off(
                    JitsiConferenceEvents.TRACK_REMOVED,
                        this._onRemoteTrackRemoved);
                room.off(JitsiConferenceEvents.TRACK_MUTE_CHANGED, this._onRemoteTrackMuted);
                room.off(JitsiConferenceEvents.USER_LEFT, this._onUserLeft);

                localTracks.map(track => {
                    if (track && !track.disposed) {
                        track.dispose();
                    }
                });
                return resolve()
            }).catch ( (err) => {
                logger.warn(`Error: RioRoom: ${err?.message}`);
                return resolve();
            });
        } catch (err) {
            logger.warn(`Error: RioRoom: ${err?.message}`);
            return resolve();
        }
    });
}

/**
 * This function is called when we disconnect.
 */
 RioRoom.prototype._onDisconnect = function () {
    this.retry = this.retry+1;
    if(this.retry > Constants.CONNECTION_RETRY) {
        //remove all event lister
        clearTimeout(this.timeoutInternal);
        RioMeetJS.videoroom.onConnectionFailed();
    } else {
        RioMeetJS.videoroom.onDisconnect(this.retry);
        //remove all event lister
        clearTimeout(this.timeoutInternal);
        this.timeoutInternal = setTimeout( () => {
            this._connect();
        }, 1000);
    }
}

/**
 * This function is called when we disconnect.
 */
RioRoom.prototype._init = function () {
    this.localTracks = [];
    this.remoteTracks = [];
    this.callInfo = {};

    this.numParticipants = 1;
    this.retry = 0;
    this.timeoutInternal = null;
    this.screenshot = null;
    this.audioEffect = null;
    this.isJoined = false;
    this.callData = {
        callQuality: Constants.Quality.MEDIUM
    };
    this.options['mediaOptions'] = {};
    this.options['constraints'] = {};
    this.options['toggleFilter'] = {};
    this.intervalLocal = null;
    this.intervalRemote = null;
    
    //clear tracking timer
    RioTimer.clear();
}

/**
 * This function is called when we disconnect.
 */
RioRoom.prototype._disconnect = async function () {
    //this.retry = 0;
    logger.debug(`RioRoom disconnect:${this.state}`);

    clearTimeout(this.timeoutInternal);
    if( !this.connection ) {
        return ;
    }
    if (this.state === STATE.INITIAL) {
        return;
    }
    this.state = STATE.INITIAL;

    const {screenshot
        , rioSubtitle
        , audioEffect
        , serverRecording } = this;

    const { callInfo } = {...this};
    const { currentUserID, callId } = callInfo;

    //remove all event lister
    await this._removeEvents();

    if(!this.isJoined) {
        return ;
    }

    this.isJoined = false;
    this.callInfo = {};

    if (serverRecording) {
        await serverRecording.stopRecording();
        this.serverRecording = null;
    }

    //destroy rioSubtitle
    if (rioSubtitle) {
        rioSubtitle.destroy();
        this.rioSubtitle = null;
    }

    //dispose audioEffect
    if (audioEffect) {
        audioEffect.dispose();
        this.audioEffect = null;
    }

    if (screenshot) {
        screenshot.stopScreenshot();
        this.screenshot = null;
    }

    if (this.intervalLocal) {
        clearTimeout(this.intervalLocal);
    }
    if (this.intervalRemote) {
        clearTimeout(this.intervalRemote);
    }

    RoomListener.destroy();
    RioMedia.stopCall();
    RioLogTracking.destroy();
    RioHelper.removeStorage(`existedCall${callId}`);
    
    this._removeRoomEvents().then(() => {
        this.connection.disconnect().then( () => {
            this.connection = null;
            RTCUtils.removeAllListener(RTCEvents.DEVICE_LIST_WILL_CHANGE);
            RTCUtils.removeAllListener(RTCEvents.AUDIO_OUTPUT_DEVICE_CHANGED);
            RioMeetJS.videoroom.onLeaveRoom(currentUserID);
            //RioAppInterface.onStop('leave', currentUserID);
            this._init();
        });
    });
}

/**
 * This function is called when the connection fail.
 */
RioRoom.prototype.onLocalTracks = async function (tracks, localStreamId='localStream', {...options}) {
    const mutePromises = [];

    let localTracks = [];
    let isVideo = false;
    let isAudio = false;
    tracks.map(track => {
        if ( !isAudio && track.isAudioTrack() ) {
            localTracks.push(track);
        }
        if ( !isVideo && track.isVideoTrack() ) {
            localTracks.push(track);
            isVideo = true;
        }
    });
    this.localTracks = localTracks;
    logger.debug(`onLocalTracks length:${localTracks.length}`);

    for (let i = 0; i < this.localTracks.length; i++) {
        RioMedia.attachMediaStream(localStreamId, this.localTracks[i], {...options});

        if (this.isJoined) {
            logger.debug(`onLocalTracks join`);
            const track = await this.room.addTrack(this.localTracks[i]);
            mutePromises.push(track);
        }
    }

    if (isVideo === false) {
        //show avatar instead of stream
        RioMedia.showAvatar({
            isLocal: true
        });
    }
    // We return a Promise from all Promises so we can wait for their
    // execution.
    return Promise.all(mutePromises);
}

/**
 * This function is called when the connection fail.
 */
RioRoom.prototype.onRemoteTrack = function (track) {
    if (!track || track.isLocal()) {
        return;
    }
    const { callInfo } = {...this};
    const { callId } = callInfo;
    logger.info('[STEP] 9 onRemoteTrack');

    const pId = track.getParticipantId();
    const {disposed} = track;
    if (disposed) {
        return;
    }

    const participant = this.room.getParticipantById(pId);
    const disName = participant?._displayName || null;
    if (disName == 'Transcriber') {
        return;
    }

    if (!this.remoteTracks[pId]) {
        this.remoteTracks[pId] = [];
    }
    if (this.isDisableAudio() && track.isAudioTrack()) {
        //iOS app
        logger.debug('[debug] onRemoteTrack audio ignore');
        return;
    }
    logger.info('[STEP] 9 onRemoteTrack', track.toString());
    RioTimer.debug(`[debug] onRemoteTrack ${callId} ${pId} ${track.getType()} - ${track.isMuted()}`);

    // track.addEventListener(
    //     JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED,
    //     audioLevel =>
    //     {
    //         //console.log(`Audio Level remote: ${audioLevel}`)
    //     });
    track.addEventListener(
        JitsiTrackEvents.REMOTE_TRACK_MUTED,
        () => {
            logger.info('[debug] onRemoteTrack REMOTE_TRACK_MUTED', track.isVideoTrack(), track.isMuted());
            if (track.isVideoTrack()) {
                return RoomListener.sendReloadCamera();
            }
        });
    track.addEventListener(
        JitsiTrackEvents.TRACK_MUTE_CHANGED,
        () => {
            logger.info(`[debug] onRemoteTrack TRACK_MUTE_CHANGED ${track.getType()} - ${track.isMuted()}`);
            if (track.isVideoTrack()) {
                if ( track.isMuted() ) {
                    RioMedia.showAvatar({
                        isLocal: false
                    });
                } else {
                    this._attachMediaStream(track, {
                        isLocal: false
                    });
                }
            }
            
            RioMeetJS.videoroom.onTrackMutedListener(track, track.isMuted());
            RioAppInterface.onEmitEvent('onTrackMuted', {
                type: track.getType(),
                muted: track.isMuted()
            });
        });
    // track.addEventListener(
    //     JitsiTrackEvents.LOCAL_TRACK_STOPPED,
    //     () => console.log('remote track stoped'));
    // track.addEventListener(JitsiTrackEvents.TRACK_AUDIO_OUTPUT_CHANGED,
    //     deviceId =>
    //         console.log(
    //             `track audio output device was changed to ${deviceId}`));

    if (track.isAudioTrack()) {
        this.remoteTracks[pId][0] = track;
        RioAppInterface.onEmitEvent('onRemoteAudioTrack');
    } else {
        this.remoteTracks[pId][1] = track;
        RioAppInterface.onEmitEvent('onRemoteVideoTrack');
    }

    //this.remoteTracks[pId].push(track);
    setTimeout(() => {
        const remoteOptions = this.getRemoteOptions();
        logger.info('[STEP] 9 onRemoteTrack onRemoteStreamListener', remoteOptions);

        RioMeetJS.videoroom.onRemoteStreamListener(track, remoteOptions);
    }, 100);
}

/**
 * Removes all event listeners bound to the remote video track and clears
 * any related timeouts.
 *
 * @param {JitsiRemoteTrack} track - The remote track which is being
 * removed from the conference.
 */
RioRoom.prototype.onRemoteTrackRemoved = function (track) {
    if (!track || track.isLocal()) {
        return;
    }

    if (track.isVideoTrack()) {
        try {
            const endpointId = track.getParticipantId();
            logger.debug(`onRemoteTrackRemoved Detector on remote track removed: ${endpointId}`);

            track.off(
                JitsiTrackEvents.TRACK_MUTE_CHANGED,
                () => {
                    logger.debug('onRemoteTrackRemoved');
                });

            //RioMedia.detach(track, true);
        } catch (err) {
            logger.warn(`Error: RioRoom onRemoteTrackRemoved: ${err?.message}`);
        }
    }

    track.removeAllListeners('removetrack');
    track.removeAllListeners(JitsiTrackEvents.TRACK_MUTE_CHANGED);
    track.removeAllListeners(JitsiTrackEvents.TRACK_VIDEOTYPE_CHANGED);
    track.removeAllListeners(JitsiTrackEvents.NO_DATA_FROM_SOURCE);
}

/**
 * Removes all event listeners bound to the remote video track and clears
 * any related timeouts.
 *
 * @param {JitsiRemoteTrack} track - The remote track which is being
 * removed from the conference.
 */
RioRoom.prototype.onRemoteTrackMuted = function (track) {
    logger.debug(`TRACK_MUTE_CHANGED ${track.getType()} - ${track.isMuted()} - ${track.isVideoTrack()} ${track.isLocal()}`);
    if (track.isVideoTrack() && !track.isLocal()) {
        if ( track.isMuted() ) {
            RioMedia.showAvatar({
                isLocal: false
            });
        } else {
            //this._attachMediaStream(track, {
            //    isLocal: false
            //});
        }
    }
}

/**
 * Creates and joins new conference.
 * @param name the name of the conference; if null - a generated name will be
 * provided from the api
 * @param options Object with properties / settings related to the conference
 * that will be created.
 * @returns {Promise} returns the new conference object.
 */
 RioRoom.prototype.getUserMedia = async function(mediaOptions = {}) {
    const defaultOption = {
        deviceId: 'default',
        micDeviceId: 'default',
        cameraDeviceId: 'default',
        localStreamId: 'localStream',
        remoteStreamId: 'remoteStreamId',
        options: {}
    }
    const constraintsOptions = {...defaultOption, ...mediaOptions};
    const {
        audio, 
        video, 
        localStreamId, 
        remoteStreamId,
        resolution,
        deviceId,
        micDeviceId,
        cameraDeviceId,
        avatarUrl,
        options
    } = constraintsOptions;

    let devices = [];
    let isVideo = false;
    if (this.isDisableAudio()) {
        //iOS app
    } else if (audio === true) {
        devices.push('audio');
    }
    if (video === true) {
        devices.push('video');
        isVideo = true;
    }

    const _mediaOptions = options || {};
    _mediaOptions.isVideo = isVideo;

    //the user option get from API
    let customs = {};
    const screenSizes = RioHelper.getScreenSize();
    if (!RioHelper.isMobile()) {
        if (resolution) {
            customs = this._getAppConfig(resolution);
        } else {
            customs = this._getAppConfig(Constants.Quality.MEDIUM);
        }
    } else {
        //customs = Object.assign({}, {
        //        constraints: screenSizes
        //    }
        //);
    }

    const _cameraDeviceId = RioHelper.obtainCameraId(deviceId);
    const constraints = Object.assign({}, {
            devices: devices,
            deviceId: _cameraDeviceId,
            micDeviceId: micDeviceId,
            cameraDeviceId: cameraDeviceId,
        }
        , customs
        , {
            //constraints: screenSizes
        }
    );

    _mediaOptions.deviceId = _cameraDeviceId;
    const requestConstraints = RioHelper.obtainFacingMode(constraints);

    this.options['localStreamId'] = localStreamId;
    this.options['mediaOptions'] = _mediaOptions;
    this.options['constraints'] = requestConstraints;

    RioMedia.setLocalStreamId(localStreamId);
    RioMedia.setRemoteStreamId(remoteStreamId);
    if(avatarUrl) {
        RioMedia.setAvatar(avatarUrl);
    }
    //show avatar instead of stream
    RioMedia.showAvatar({
        isLocal: true
    });
    //show avatar instead of stream
    RioMedia.showAvatar({
        isLocal: false
    });

    logger.info('[STEP] 7 getUserMedia', mediaOptions, constraintsOptions, constraints, requestConstraints);
    RioTimer.start('requestmedia');

    return new Promise((resolve, reject) => {
        RioMeetJS.createLocalTracks(requestConstraints)
        .then(tracks => {
            return  this._prepareTracks(tracks, _mediaOptions)
        }).then(async (tracks) => {
            await this.onLocalTracks(tracks, localStreamId, _mediaOptions);

            //call back application camera OK
            logger.info('[STEP] 7 onCameraSuccess', requestConstraints);
            RioAppInterface.onCameraSuccess(requestConstraints);
            RioAppInterface.onEmitEvent('onCameraSuccess');
            const { speaker } = _mediaOptions;
            if (speaker !== undefined) {
                RioAppInterface.onToggleSpeaker(speaker);
            }

            const cameraChangeSupported = JitsiMediaDevices.isDeviceChangeAvailable('input');
            const speakerChangeSupported = JitsiMediaDevices.isDeviceChangeAvailable('output');
            let disableAudioInputChange = !JitsiMediaDevices.isMultipleAudioInputSupported();
            
            logger.debug(`RioRoom getAudioOutputDevice: ${JitsiMediaDevices.getAudioOutputDevice()}
                cameraChangeSupported: ${cameraChangeSupported}
                speakerChangeSupported: ${speakerChangeSupported}
                disableAudioInputChange: ${disableAudioInputChange}
            `);
            setTimeout(() => {
                this.changeVideoQuality(undefined);
            }, 1000);
            //send call setting to remote
            RoomListener.sendCallInfo(_mediaOptions);

            if (isVideo) {
                //listen video stream can play
                //clearTimeout(this.intervalLocal);
                //this.intervalLocal = setTimeout(() => {
                //    this.checkLocalPlaying(0);
                //}, 3000);
            }

            RioTimer.debug('createLocalTrack success');
            return resolve(tracks);
        })
        .catch(err => {
            RioTimer.debug('createLocalTrack failed');
            logger.warn(`Error: [STEP] 7 RioRoom getUserMedia: ${err?.message}`, err);
            //call back application camera OK
            RioAppInterface.onCameraFailed(err?.message);
            reject(err?.message);
        });
    });
};

/**
 * get new local audio track and create effect
 * replace old track to effected track
 * @returns {Promise} returns the new track.
 */ 
RioRoom.prototype.onToggleAudioEffect = async function (effectOptions) {
    const { enabled, value } = effectOptions;
    const options = {...this.options};

    const {mediaOptions, audioEffected} = options;
    const {audioMuted} = mediaOptions;

    if (audioMuted || !this.isJoined) {
        return Promise.resolve();
    }
    logger.debug('onToggleAudioEffect', JSON.stringify(effectOptions));
    const found = this.localTracks.filter((stream) => {
        return (stream.type && stream.type == MediaType.AUDIO);
    });
    const jitsiTrack = found.length ? found.shift() : false;
    if (!jitsiTrack) {
        logger.warn(`RioRoom onToggleAudioEffect empty localTrack`);
        return Promise.resolve();
    }

    if (!enabled || isNaN(value) || value === '') {
        if (!audioEffected) {
            return Promise.resolve();
        }
        return await this.offAudioEffect(jitsiTrack);
    }
    //set voice changce
    return new Promise(async (resolve, reject) => {
        //if found
        if (jitsiTrack) {
            this.options['audioEffected'] = true;
            this.audioEffect = new AudioEffect(jitsiTrack, effectOptions);
            await jitsiTrack.setEffect(await this.audioEffect.setEffect(effectOptions));
            return resolve(jitsiTrack);
        }

        return Promise.resolve();
    });
};

/**
 * get new local audio track and create effect
 * replace old track to effected track
 * @returns {Promise} returns the new track.
 */
RioRoom.prototype.offAudioEffect = async function(jitsiTrack) {
    let constraints = {...this.options.constraints};
    const {deviceId } = constraints;
    logger.info('offAudioEffect');
    try {
        //if found
        if (jitsiTrack) {
            await jitsiTrack.setEffect(undefined);
            jitsiTrack.stopStream();
        }
        //delay time
        delayTime(600);

        return new Promise((resolve) => {
            RioMeetJS.createLocalTrack(MediaType.AUDIO, deviceId, undefined, {})
            .then( async (track) => {
                await this.replaceTrack(track, MediaType.AUDIO);
                return resolve(track);
            })
            .catch(err => {
                logger.warn(`Error: RioRoom offAudioEffect: ${err?.message}`);
                return Promise.reject(err);
            });
        });
    } catch (err) {
        logger.warn(`Error: RioRoom offAudioEffect: ${err?.message}`);
    }
};

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @param {Object} jitsiTrack - Represents the jitsi track that will have backgraund effect applied.
 * @param {Object} options - Represents the virtual background setted options.
 * @returns {Promise}
 */
 RioRoom.prototype.toggleBackgroundEffect = async function(jitsiTrack, options) {
    try {
        if (!jitsiTrack || (typeof jitsiTrack != 'object')) {
            return Promise.resolve();
        }
        //set filter flag
        const {toggleFilter} = this.options || {};
        if ( (toggleFilter.enabled && options.enabled)
            || (!toggleFilter.enabled && !options.enabled)) {
            
            return Promise.resolve();
        }
        this.isProcessing = true;
        let effectedOptions = {
            type: 'video',
            enabled: false,
            blurValue: options?.blurValue,
        }
        const cloneOptions = {...this.options};
        const {constraints} = cloneOptions;
        const mediaOptions = cloneOptions?.mediaOptions || {};
        const {deviceId } = constraints;

        logger.debug(`toggleBackgroundEffect:`, constraints);
        RioTimer.start('toggleFilter', true);
        RioAppInterface.onEmitEvent('onToggleFilter');

        if (options.enabled) {
            const { endpoints, ...otherOptions } = appConfig;
            const libPath = (options?.libPath || endpoints?.sdk);
            
            const virtualBackground = {
                enabled: true,
                virtualSource: options?.url,
                blurValue: options?.blurValue,
                backgroundType: options?.backgroundType,
                selectedThumbnail: options?.selectedThumbnail,
                canvasId: options?.canvasId,
                libPath: libPath,
                deviceId: deviceId
            };

            mediaOptions.onFilter = true;
            RoomListener.sendCallInfo(mediaOptions);
            //set filter flag
            this.options['toggleFilter'] = virtualBackground;
            await this._toggleBackgroundEffect(jitsiTrack, virtualBackground);
            //set enabled true
            effectedOptions.enabled = true;
        } else {
            //set filter flag
            this.options['toggleFilter'] = {};
            mediaOptions.onFilter = false;
            RoomListener.sendCallInfo(mediaOptions);

            await this._toggleBackgroundEffect(jitsiTrack, {
                enabled: false
            });
        }
        delete this.isProcessing;
        return Promise.resolve();
    } catch (err) {
        delete this.isProcessing;
        logger.warn(`Error on apply background effect: ${err?.message}`);
        return Promise.resolve();
    }
}

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @param {Object} jitsiTrack - Represents the jitsi track that will have backgraund effect applied.
 * @param {Object} options - Represents the virtual background setted options.
 * @returns {Promise}
 */
 RioRoom.prototype._disposeTrack = async function(jitsiTrack) {
    return await new Promise(resolve => {
        let interval;
        interval = setInterval( async() => {
            let disposed = jitsiTrack?.disposed;
            try {
                logger.debug(`_disposeTrack: ${jitsiTrack.disposed}`, jitsiTrack.toString());

                if ( !disposed && !jitsiTrack?._stopStreamInProgress) {
                    await jitsiTrack.dispose();
                    disposed = true;
                }
            } catch (err) {
                disposed = true;
                logger.warn(`Error _disposeTrack: ${err?.message}`);
            }

            if (!jitsiTrack || disposed ) {
                clearInterval(interval);
                return resolve(true);
            };
        }, 600);
    });
}

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @param {Object} jitsiTrack - Represents the jitsi track that will have backgraund effect applied.
 * @param {Object} options - Represents the virtual background setted options.
 * @returns {Promise}
 */
 RioRoom.prototype._toggleBackgroundEffect = async function(jitsiTrack, options) {
    try {
        let effectedOptions = {
            type: 'video',
            enabled: false,
            blurValue: options?.blurValue,
        }
        logger.debug('_toggleBackgroundEffect', JSON.stringify(options));

        if (options.enabled) {
            // await jitsiTrack.setEffect(await createVirtualBackgroundEffect(options));
            
            // In case we have an audio track that is being enhanced with an effect, we still want it to be applied,
            // even if the track is muted. Where as for video the actual track doesn't exists if it's muted.
            if (jitsiTrack?.disposed) {
                
                options.mediaType = MediaType.VIDEO;
                options.videoType = VideoType.CAMERA;
                const {virtualSource} = options;

                const newTrack = RioMedia.createLocalTrack(virtualSource, options);
                await this.replaceTrack(newTrack, MediaType.VIDEO);

                this._attachMediaStream(newTrack, {
                    isLocal: true,
                    mediaType: options.mediaType
                });
            } else {
                const streamEffect = new DeepArEffect(jitsiTrack, options);
                await jitsiTrack.setEffect(await streamEffect.setEffect(options));
            }

            RioTimer.debug("toggleBackgroundEffect success");
            RioAppInterface.onEmitEvent('onToggleFilterSuccess');
            //set enabled true
            effectedOptions.enabled = true;
        } else {
            await jitsiTrack.setEffect(undefined);
            setTimeout(() => {
                this.changeVideoQuality(undefined);
            }, 300);
        }

        if (this.mode !== Constants.MODE.API) {
            SocketService.instance.toggleEffect(effectedOptions);
        }

        return Promise.resolve();
    } catch (err) {
        logger.warn(`Error on apply background effect: ${err?.message}`);
    }
}

/**
 * get new local audio track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
 RioRoom.prototype.switchOutputAudio = async function(mediaOptions) {
    const {
        micDeviceId,
    } = mediaOptions;

    logger.debug(`RioRoom switchOutputAudio: ${JSON.stringify(mediaOptions)}`);
    return JitsiMediaDevices.setAudioOutputDevice(micDeviceId);
};

/**
 * get new local audio track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
 RioRoom.prototype.switchInputAudio = function(mediaOptions) {
    const {
        micDeviceId,
        options
    } = mediaOptions;

    const { isProcessing } = this;
    logger.debug(`switchInputAudio: ${isProcessing}`, JSON.stringify(mediaOptions));
    if (this.isDisableAudio()) {
        //iOS app
        logger.debug('[debug] isDisableAudio iOS');
        return Promise.resolve();
    }

    if (isProcessing === true) {
        return Promise.resolve();
    }
    const cloneOptions = {...this.options};
    const _mediaOptions = cloneOptions?.mediaOptions || {};
    
    return new Promise(async (resolve, reject) => {
        this.isProcessing = true;
        const tracks = this.getLocalTracks(MediaType.AUDIO);
        const jitsiTrack = tracks.length ? tracks.shift() : false;
        if ( jitsiTrack ) {
            await this._disposeTrack(jitsiTrack);
            jitsiTrack.stopStream();
        }
        //delay time
        delayTime(100);

        await RioMeetJS.createLocalTrack(MediaType.AUDIO, micDeviceId, 0)
        .then( async (jitsiTrack) => {
            this.options.constraints.micDeviceId = micDeviceId;
            const cloneMediaOptions = Object.assign({}, 
                _mediaOptions,
                {
                    micDeviceId: micDeviceId,
                },
                options || {}
            );
            //if found
            if (jitsiTrack) {
                await this.replaceTrack(jitsiTrack, MediaType.AUDIO);

                const { audioMuted } = cloneMediaOptions;
                if ( audioMuted === true ) {
                    jitsiTrack.mute();
                }

                logger.debug('switchInputAudio success', jitsiTrack.toString());
                this._attachMediaStream(jitsiTrack, cloneMediaOptions);
                //send callInfo
                //RoomListener.sendCallInfo(cloneMediaOptions);
                this.options['mediaOptions'] = cloneMediaOptions;

                delete this.isProcessing;
                return resolve(jitsiTrack);
            } else {
                delete this.isProcessing;
                return resolve();
            }
        })
        .catch(err => {
            logger.warn(`Error: RioRoom switchInputAudio: ${err?.message}`);
            delete this.isProcessing;
            reject(err);
        });
    });
};

/**
 * get new local video track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
 RioRoom.prototype.switchCamera = async function(mediaOptions) {
     const {
        cameraDeviceId,
        options
    } = mediaOptions;

    const { isProcessing } = this;
    logger.debug(`switchCamera: ${isProcessing}`, JSON.stringify(mediaOptions));
    RioTimer.start('switchcamera');

    if (isProcessing === true) {
        return Promise.resolve();
    }
    const {toggleFilter} = this.options || {};
    const sTime = new Date().getTime();

    const cloneOptions = {...this.options};
    const _mediaOptions = cloneOptions?.mediaOptions || {};
    
    RioAppInterface.onEmitEvent('onSwitchCamera');
    return new Promise(async (resolve, reject) => {
        this.isProcessing = true;
        const tracks = this.getLocalTracks(MediaType.VIDEO);
        const jitsiTrack = tracks.length ? tracks.shift() : false;
        if ( jitsiTrack ) {
            logger.debug(`switchCamera jitsiTrack sTime ${sTime}`, JSON.stringify(toggleFilter));
            await this._disposeTrack(jitsiTrack);
            jitsiTrack.stopStream();
            this.options['toggleFilter'] = {};
        }
        //wait 300ms
        //delay time
        delayTime(100);
        const eTime = new Date().getTime();
        logger.debug(`switchCamera createLocalTrack eTime ${eTime} ${(eTime-sTime) / 1000}`);

        const _cameraDeviceId = RioHelper.obtainCameraId(cameraDeviceId);
        await RioMeetJS.createLocalTrack(MediaType.VIDEO, _cameraDeviceId, 0)
        .then( async (jitsiTrack) => {
            this.options.constraints.cameraDeviceId = _cameraDeviceId;
            //this.callInfo.callType = Constants.CallType.VIDEO;
            const cloneMediaOptions = Object.assign({}, 
                _mediaOptions,
                {
                    deviceId: _cameraDeviceId,
                },
                options || {}
            );
            //if found
            if (jitsiTrack) {
                await this.replaceTrack(jitsiTrack, MediaType.VIDEO);

                const { videoMuted } = cloneMediaOptions;
                if ( videoMuted === true ) {
                    jitsiTrack.mute();
                    //show avatar instead of stream
                    RioMedia.showAvatar({
                        isLocal: true
                    });
                }

                RioTimer.debug("switchCamera success");
                logger.debug('switchCamera success', jitsiTrack.toString());
                RioAppInterface.onEmitEvent('onSwitchCameraSuccess');
                this._attachMediaStream(jitsiTrack, cloneMediaOptions);
                //send callInfo
                RoomListener.sendCallInfo(cloneMediaOptions);
                this.options['mediaOptions'] = cloneMediaOptions;
                RioMedia.hideAvatar({
                    isLocal: true
                });

                delete this.isProcessing;
                return resolve(jitsiTrack);
            } else {
                delete this.isProcessing;
                return resolve();
            }
        })
        .catch(err => {
            logger.warn(`Error: RioRoom switchCamera: ${err?.message}`);
            delete this.isProcessing;
            reject(err);
        });
    });
};

/**
* switch Speaker on/off
* @param {boolean} isSpeaker
*
* @returns {Promise}
*/
RioRoom.prototype.toggleSpeaker = function(isSpeaker) {
    const version = RioHelper.browserVersion();
    const {toggleSpeaker} = this.options;

    logger.debug(`toggleSpeaker ${isSpeaker} ${version} ${toggleSpeaker}`);

    if (RioHelper.isIosBrowser() && version >= 17 && !toggleSpeaker) {
        this.options['toggleSpeaker'] = true;

        this.setReloaded(false, false);
        RoomListener.sendReloadCamera();
        return this.reloadTrack({
            mediaType: MediaType.VIDEO
        });
    }

    return Promise.resolve();
}

/**
 * recreate new local video track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
 RioRoom.prototype.reloadTrack = async function(params) {
     const {
        mediaType,
    } = params;

    const { isProcessing } = this;
    const { callInfo } = {...this};
    const { callType } = callInfo;
    const cloneOptions = {...this.options};
    const reloadFlg = (mediaType === MediaType.VIDEO) 
        ? cloneOptions?.reloadVideo 
        : cloneOptions?.reloadAudio;

    if (isProcessing === true 
        || reloadFlg === true 
        || !mediaType
        || (mediaType === MediaType.VIDEO && callType != Constants.CallType.VIDEO)
        || !this.room ) {

        logger.info(`reloadTrack reloadFlg:${reloadFlg} mediaType:${mediaType} isProcessing:${isProcessing}`);
        return Promise.resolve();
    }
    
    const {constraints} = cloneOptions;
    const mediaOptions = cloneOptions?.mediaOptions || {};
    
    let deviceId = constraints?.cameraDeviceId;
    if (mediaType === MediaType.AUDIO ) {
        deviceId = constraints?.micDeviceId;
    }
    logger.info(`reloadTrack deviceId:${deviceId}`, cloneOptions, constraints, mediaOptions);

    return new Promise(async (resolve, reject) => {
        this.isProcessing = true;
        this.setReloaded(true, (mediaType === MediaType.AUDIO));

        const tracks = this.getLocalTracks(mediaType);
        const jitsiTrack = tracks.length ? tracks.shift() : false;
        if ( jitsiTrack ) {
            await this._disposeTrack(jitsiTrack);
            jitsiTrack.stopStream();
        }
        //wait 300ms
        delayTime(100);
        await RioMeetJS.createLocalTrack(mediaType, deviceId, 0)
        .then( async (jitsiTrack) => {
            if (jitsiTrack) {
                await this.replaceTrack(jitsiTrack, mediaType);
                logger.info('reloadTrack success', jitsiTrack.toString());
                
                if (mediaType !== MediaType.AUDIO ) {
                    mediaOptions.videoMuted = false;
                    mediaOptions.isVideo = true;
                    this.options['mediaOptions'] = mediaOptions;
                    this._attachMediaStream(jitsiTrack, mediaOptions);
                    //send callInfo
                    RoomListener.sendCallInfo(mediaOptions);
                    RioMedia.hideAvatar({
                        isLocal: true
                    });
                }

                delete this.isProcessing;
                return resolve(jitsiTrack);
            } else {
                delete this.isProcessing;
                return resolve();
            }
        })
        .catch(err => {
            logger.warn(`Error: reloadTrack: ${err?.message}`);
            delete this.isProcessing;
            reject(err);
        });
    });
};

/**
* @inheritdoc
*
* Stops sending the media track. And removes it from the HTML. NOTE: Works for local tracks only.
*
* @extends JitsiTrack#dispose
* @returns {Promise}
*/
RioRoom.prototype.disposeTrack = async function(mediaOptions) {
    const {
        mediaType
    } = mediaOptions;

    const { isProcessing } = this;
    logger.debug(`disposeTrack: ${isProcessing}`, JSON.stringify(mediaOptions));

    if (isProcessing === true) {
        return Promise.resolve();
    }

    delayTime(100);
    this.isProcessing = true;
    const tracks = this.getLocalTracks(mediaType);
    const jitsiTrack = tracks.length ? tracks.shift() : false;
    if ( jitsiTrack ) {
        await this._disposeTrack(jitsiTrack);
    }
    delayTime(300);
    delete this.isProcessing;
    return Promise.resolve();
}

/**
 * check output audio 
 * 
 * @param options.micDeviceId device 
 * @param options.voice effected 
 * @param options.timeout number second recording
 * 
 * @returns {Promise} returns the new track.
 */
 RioRoom.prototype.checkOutputAudio = async function(mediaOptions) {
    const {
        micDeviceId,
        voice,
        timeout,
        stream
    } = mediaOptions;

    const options = {
        stream,
        micDeviceId: micDeviceId,
        timeout: timeout ? Number(timeout) : 10,
        value: (voice !== undefined && voice !== '' && voice !== null)
            ? Number(voice)
            : 0
    }

    try {
        this.audioRecording = new AudioRecording();
        await this.audioRecording.setEffect(options);
        return this.audioRecording.start();
    }
    catch (err) {
        logger.warn(`Error: RioRoom checkOutputAudio: ${err?.message}`);
        return Promise.reject(err);
    }
};

/**
 * stop mediarecord output audio 
 * 
 * @returns {void}
 */
 RioRoom.prototype.stopCheckOutputAudio = function() {
    if(this.audioRecording) {
        this.audioRecording.abort();
    };
};

/**
 * Replaces oldTrack with newTrack and performs a single offer/answer
 *  cycle after both operations are done.  Either oldTrack or newTrack
 *  can be null; replacing a valid 'oldTrack' with a null 'newTrack'
 *  effectively just removes 'oldTrack'
 * @param {JitsiLocalTrack} newTrack the new stream to use
 * @param {MediaType} mediaType type of track audio, video
 * 
 * @returns {Promise} resolves when the replacement is finished
 */
 RioRoom.prototype.replaceTrack = async function(newTrack, mediaType) {
    if (!newTrack || !this.room) {
        return Promise.resolve();
    }
    //const oldTracks = this.localTracks.slice();
    const idx = this.localTracks.findIndex(stream => stream.type == mediaType);
    const found = this.localTracks.filter((stream) => {
        return (stream.type && stream.type == mediaType);
    });
    //if found
    if (found.length) {
        let promise;
        const oldTrack = this.localTracks[idx];

        if (oldTrack?.disposed) {
            promise = await this.room.replaceTrack(null, newTrack);
        } else {
            promise = await this.room.replaceTrack(oldTrack, newTrack);
        }

        found.map(track => {
            if (track?.disposed) {
                return;
            }
            if (track.isVideoTrack()) {
                RioMedia.detach(track);
            }
            track.dispose();
            track = null;
        });

        this.localTracks[idx] = newTrack;
        return promise;
    } else {
        this.localTracks.push(newTrack);
        return this.room.addTrack(newTrack);
    }
};

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @returns {Promise}
 */
 RioRoom.prototype.startRecording = async function() {
    const { roomName, callId } = this.callInfo;
    if (!this.room || !callId) {
        return Promise.resolve();
    }

    const recordingOpts = {
        callId: callId,
        roomName: roomName,
        conference: this.room
    };

    if (!this.serverRecording) {
        this.serverRecording = new RioServerRecording(recordingOpts);
    }

    return this.serverRecording.startRecording();
}

/**
* Obtains local audio track.
* @param {MediaType} mediaType - audio, video
* @return {JitsiLocalTrack|null}
*/
RioRoom.prototype.getLocalTrack = function(mediaType = 'audio') {
    if (!this.room) {
        return null;
    }

    if (mediaType == Constants.MediaType.VIDEO) {
        return this.room.getLocalVideoTrack();
    } else {
        return this.room.getLocalAudioTrack();
    }
}

/**
    * Mute or unmute audio. When muted, the ongoing local recording should
    * produce silence.
    *
    * @param {boolean} muted - If the audio should be muted.
    * @param {MediaType} mediaType - audio, video
    * @returns Promise
*/
RioRoom.prototype._setMute = async function(muted, mediaType = 'audio') {
    if (!this.room) {
        return Promise.resolve();
    }
    const options = {...this.options};
    const {mediaOptions} = options;
    logger.debug(`setMute ${muted} - ${mediaType}`);


    if (this.isDisableAudio() && mediaType == Constants.MediaType.AUDIO) {
        //iOS app
        RioAppInterface.onToggleMuted(muted);
        return Promise.resolve();
    }

    if ( mediaType == Constants.MediaType.VIDEO) {
        if ( muted ) {
            //show avatar instead of stream
            RioMedia.showAvatar({
                isLocal: true
            });
        } else {
            this.setReloaded(false);
            return this.reloadTrack({
                mediaType: MediaType.VIDEO
            });
        }
    }

    return new Promise( async (resolve) => {
        const track = this.getLocalTrack(mediaType);
        if ( track ) {
            if ( muted ) {
                await track.mute();
                if ( mediaType == Constants.MediaType.VIDEO ) {
                    mediaOptions.videoMuted = true;
                } else {
                    mediaOptions.audioMuted = true;
                }
            } else {
                await track.unmute();
                if ( mediaType == Constants.MediaType.VIDEO ) {
                    mediaOptions.videoMuted = false;
                    mediaOptions.isVideo = true;
                } else {
                    mediaOptions.audioMuted = false;
                }
            }
            this.options['mediaOptions'] = mediaOptions;
            RoomListener.sendCallInfo(mediaOptions);
            if (mediaType == Constants.MediaType.AUDIO) {
                RioAppInterface.onToggleMuted(muted);
            }
        }
        //delayTime(600);
        //this.changeVideoQuality(undefined);
        delayTime(600);
        this.changeVideoQuality(undefined);
        resolve(track);
    });
}

RioRoom.prototype._getAppConfig = function(quality) {
    //the user option get from API
    const {resolutions} = this.options;
    
    if (resolutions && resolutions[quality] !== undefined ) {
        return resolutions[quality];
    }

    return {resolution: 0};
};

RioRoom.prototype._getMaxResolution = function() {
    const maxCustoms = this._getAppConfig(Constants.Quality.HIGH);
    const {resolution} = maxCustoms;

    return resolution || Constants.MAX_RESOLUTION;
};

RioRoom.prototype._prepareTracks = function(tracks, options) {
    const {videoMuted, audioMuted} = options;
    if (tracks){
        tracks.map(track => {
            if (audioMuted && track.isAudioTrack() ) {
                 track.mute();
            }

            if (videoMuted && track.isVideoTrack() ) {
                track.mute();
            }
        });
    }
    return tracks;
};

/**
 * This function is called when the connection fail.
 */
RioRoom.prototype._onConnectionFailed = function (err) {
    this.retry = this.retry+1;
    logger.warn('[STEP] 6 onConnectionFailed', err);

    if(this.retry > Constants.CONNECTION_RETRY) {
        //this.retry = 0;
        clearTimeout(this.timeoutInternal);
        this.state = STATE.WAITING;
        RioMeetJS.videoroom.onConnectionFailed(err);
        RioAppInterface.onConnectionFailed(err); // eslint-disable-line no-undef
        RioAppInterface.onEmitEvent('onConnectionFailed');
    } else {
        RioMeetJS.videoroom.onDisconnect(this.retry);
        //remove all event lister
        clearTimeout(this.timeoutInternal);
        this.timeoutInternal = setTimeout( () => {
            this._connect();
        }, 1000);
    }
}

/**
 * This function is called when the connection fail.
 */
RioRoom.prototype._onConferenceJoined = function () {
    if (!this.room) {
        return;
    }

    this.isJoined = true;
    const { callQuality } = this.callData;
    //check enable server recording
    const { autoRecord } = this.options;
    const { callInfo } = {...this};
    const { callId, initiatorID, currentUserID, roomName } = callInfo;

    this._updateReceiverVideoConstraints(callQuality, true);
    setTimeout(() => {
        RioMeetJS.videoroom.onConnectionSuccess(callInfo);
        RioAppInterface.onConnectionSuccess(callInfo);
    }, 300);

    //check and capture screen
    if (initiatorID == currentUserID) {
        //this.room.setSubject(roomName);
        setTimeout( () => {
            if ( this.screenshot ) {
                this.screenshot.autoScreenshot();
            }
        }, 1000);
    }

    const {serverRecording} = conferenceConfig;

    if (serverRecording === true
        && autoRecord === true  
        && initiatorID == currentUserID ) {
        
        setTimeout( () => {
            this.startRecording();
        }, 1000);
    }
    
    RioAppInterface.onEmitEvent('onConferenceJoined');
    logger.info(`[STEP] 6 _onConferenceJoined ${callId}`, callInfo);
}

/**
 * This function is called when the connection fail.
 */
 RioRoom.prototype._updateReceiverVideoConstraints = function (type, firstJoin) {
    if (!this.room) {
        return;
    }
    const {remoteTracks} = this;
    const customs = this._getAppConfig(type);
    const maxCustoms = this._getAppConfig(Constants.Quality.HIGH);

    const {resolution} = customs;
    const maxSizes = Constants.Resolutions[maxCustoms?.resolution || Constants.DEFAULT_RESOLUTION];
    const sizes = Constants.Resolutions[resolution];

    //const screenSizes = RioHelper.getScreenSize();
    //const {ideal} = screenSizes.video.height;

    let width = sizes?.width || Constants.DEFAULT_WIDTH;
    let height = sizes?.height || Constants.DEFAULT_HEGHT;
    let mHeight = maxSizes?.height || Constants.DEFAULT_HEGHT;

    if (resolution > mHeight) {
        mHeight = resolution;
    }

    const receiverConstraints = {
        constraints: {},
        //onStageEndpoints: [],
        defaultConstraints: {maxHeight: mHeight}
    };

    Object.keys(remoteTracks).forEach( (pId) => {
        receiverConstraints.constraints[pId] = { 
            'width': width,
            'height': height,
            'maxHeight': resolution || mHeight
        };
    });
    if (receiverConstraints.constraints.length > 0) {
        logger.debug(`RioRoom _updateReceiverVideoConstraints: ${type} ${JSON.stringify(maxSizes)} ${JSON.stringify(receiverConstraints)}`);
        this.room.setReceiverConstraints(receiverConstraints);
    }
    if( firstJoin === true ) {
        //https://github.com/jitsi/lib-jitsi-meet/issues/1333
        setTimeout(() => {
            this.room.setSenderVideoConstraint(Constants.INIT_RESOLUTION);
        }, 100);
    }
}

/**
 *
 * @param id
 */
 RioRoom.prototype._onUserJoined = function(id, participant) {
    this.numParticipants++;
    //this._updateLastN();
    this.remoteTracks[id] = [];
    logger.debug(`RioRoom _onUserJoined: ${id} getRole ${participant.getRole()} isModerator ${participant.isModerator()} getJid ${participant.getJid()}`);
}

/**
 *
 * @param id
 */
RioRoom.prototype._onUserLeft = function(id) {
    this.numParticipants--;

    const {room, remoteTracks} = this;
    logger.debug(`RioRoom _onUserLeft: ${id}`);

    if (!remoteTracks[id]) {
        return;
    }
    
    try {
        //this._updateLastN();
        // Object.keys(remoteTracks).forEach( (pId) => {
        //     room.removeTrack(track)
        //         .catch(err => {
        //         })
        // });
        this.remoteTracks[id] = [];
    } catch (err) {
        logger.warn(`Error: RioRoom onUserLeft: ${err?.message}`);
    }
}

/**
 *
 * @param id
 */
 RioRoom.prototype._updateLastN = function() {
    if (!this.isJoined) {
        return;
    }
    const {channelLastN, lastNLimits} = conferenceConfig;

    let lastN = typeof channelLastN === 'undefined' ? -1 : channelLastN;

    const limitedLastN = this._limitLastN(this.numParticipants, this._validateLastNLimits(lastNLimits));

    if (limitedLastN !== undefined) {
        lastN = lastN === -1 ? limitedLastN : Math.min(limitedLastN, lastN);
    }

    if (lastN === this.room.getLastN()) {
        return;
    }

    this.room.setLastN(lastN);
}
/**
 * Returns "last N" value which corresponds to a level defined in the {@code lastNLimits} mapping. See
 * {@code config.js} for more detailed explanation on how the mapping is defined.
 *
 * @param {number} participantsCount - The current number of participants in the conference.
 * @param {Map<number, number>} [lastNLimits] - The mapping of number of participants to "last N" values. NOTE that
 * this function expects a Map that has been preprocessed by {@link validateLastNLimits}, because the keys must be
 * sorted in ascending order and both keys and values should be numbers.
 * @returns {number|undefined} - A "last N" number if there was a corresponding "last N" value matched with the number
 * of participants or {@code undefined} otherwise.
 */
RioRoom.prototype._limitLastN = function(participantsCount, lastNLimits) {
    if (!lastNLimits || !lastNLimits.keys) {
        return undefined;
    }

    let selectedLimit;

    for (const participantsN of lastNLimits.keys()) {
        if (participantsCount >= participantsN) {
            selectedLimit = participantsN;
        }
    }

    return selectedLimit ? lastNLimits.get(selectedLimit) : undefined;
}

/**
 * Checks if the given Object is a correct last N limit mapping, coverts both keys and values to numbers and sorts
 * the keys in ascending order.
 *
 * @param {Object} lastNLimits - The Object to be verified.
 * @returns {undefined|Map<number, number>}
 */
 RioRoom.prototype._validateLastNLimits = function(lastNLimits) {
    // Checks if only numbers are used
    if (typeof lastNLimits !== 'object'
        || !Object.keys(lastNLimits).length
        || Object.keys(lastNLimits)
            .find(limit => limit === null || isNaN(Number(limit))
                || lastNLimits[limit] === null || isNaN(Number(lastNLimits[limit])))) {
        return undefined;
    }

    // Converts to numbers and sorts the keys
    const sortedMapping = new Map();
    const orderedLimits = Object.keys(lastNLimits)
        .map(n => Number(n))
        .sort((n1, n2) => n1 - n2);

    for (const limit of orderedLimits) {
        sortedMapping.set(limit, Number(lastNLimits[limit]));
    }

    return sortedMapping;
}

/**
 * Attaches a handler for events(For example - "participant joined".) in the
 * conference. All possible event are defined in JitsiConferenceEvents.
 * @param eventId the event ID.
 * @param handler handler for the event.
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
RioRoom.prototype.on = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};

/**
 * Removes event listener
 * @param eventId the event ID.
 * @param [handler] optional, the specific handler to unbind
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
RioRoom.prototype.off = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.removeListener(eventId, handler);
    }
};

// Common aliases for event emitter
RioRoom.prototype.addEventListener = RioRoom.prototype.on;
RioRoom.prototype.removeEventListener = RioRoom.prototype.off;

RioRoom.prototype.log = function(...args) {
    var is_debug = true;
    if (is_debug && args) {
        args.unshift('[debug]');

        console.log.apply(console, args);
    }
}

/**
 * get remoteTracks
 *
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
 RioRoom.prototype.getRecordingOptions = function() {

    if (this.room) {
        const { callInfo } = {...this};
        const { callId } = callInfo;
        logger.debug('[debug] startLocalRecording ', callInfo);
        //const { callQuality } = this.callData;
        // const customs = this._getAppConfig(callQuality);
        // const {resolution} = customs;
        // const sizes = Constants.Resolutions[resolution];
        return {
            // video: sizes,
            remoteTracks: this.getRemoteTracks(),
            localTracks: this.getLocalTracks(),
            callId: callId
        }
    }
    return false;
}

RioRoom.prototype.getCallInfo = function() {
    const { callInfo } = {...this};
    return callInfo;
}

RioRoom.prototype.getRemoteOptions = function() {
    const options = {...this.options};
    let remoteOptions = options?.remoteOptions || {};
    remoteOptions.userId = this.userId || '';
    logger.debug('getRemoteOptions ', remoteOptions);

    return remoteOptions;
}

/**
 * return current state of room
 *
 * @returns {STATE} value
 */
RioRoom.prototype.getState = function() {
    const { state } = {...this};
    return state;
}

/**
 * attach new local video track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
RioRoom.prototype.attachMediaStream = async function(el, stream, options) {
    logger.debug('[STEP] 8 RioRoom attachMediaStream');
    RioTimer.debug(`attachMediaStream ${stream?.getType()}`);

    if (stream?.isVideoTrack()) {

        //const remoteTracks = this.getRemoteTracks();
        //const found = remoteTracks.filter((stream) => {
        //    return (stream.type && stream.type == MediaType.VIDEO);
        //});
        //const jitsiTrack = found.length ? found.shift() : false;
        //logger.debug('[STEP] 8 attachMediaStream exist stream');
        //if found
        //if (jitsiTrack) {
        //    await RioMedia.detach(jitsiTrack, true);
        //}
    }

    RioMedia.attachMediaStream(el, stream, options);
    if (stream?.getType() === 'video') {
        //clearTimeout(this.intervalRemote);
        //this.intervalRemote = setTimeout(() => {
        //    this.checkRemotePlaying(0);
        //}, 3000);
    }
};

/**
 * attach new local video track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
RioRoom.prototype.checkLocalPlaying = function(cnt) {
    if (!this.isJoined && this.intervalLocal) {
        clearTimeout(this.intervalLocal);
        return;
    }
    if (!cnt) {
        cnt = 0;
    }

    const options = {...this.options};
    const mediaOptions = options?.mediaOptions || {};
    const {isVideo, videoMuted} = mediaOptions;
    let isPlaying = !RioHelper.isTrue(isVideo);
    if(RioHelper.isTrue(videoMuted)) {
        isPlaying = true;
    }

    const {localOptions, remoteOptions} = RioMedia.getMediaOptions();
    const playing = localOptions?.playing;
    const reloadCnt = localOptions?.reloadCnt;

    logger.debug(`checkPlaying`, cnt, isPlaying, playing, localOptions, remoteOptions, mediaOptions);
    if ( playing
        || isPlaying 
        || reloadCnt > 3) {
        clearTimeout(this.intervalLocal);
        return;
    }
    if (cnt++ > 3) {
        clearTimeout(this.intervalLocal);
        RioMedia.incReloadCnt(false);

        return this.reloadTrack({
            mediaType: MediaType.VIDEO
        });
    }
    this.intervalLocal = setTimeout(() => {
        this.checkLocalPlaying(cnt);
    }, 1000);
};

/**
 * attach new local video track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
RioRoom.prototype.checkRemotePlaying = function(cnt) {
    if (!this.isJoined && this.intervalRemote) {
        clearTimeout(this.intervalRemote);
        return;
    }
    if (!cnt) {
        cnt = 0;
    }

    const options = {...this.options};
    const mediaOptions = options?.remoteOptions || {};
    const {isVideo, videoMuted, onFilter} = mediaOptions;
    let isPlaying = !RioHelper.isTrue(isVideo);
    if(RioHelper.isTrue(videoMuted)) {
        isPlaying = true;
    }

    const {localOptions, remoteOptions} = RioMedia.getMediaOptions();
    const playing = remoteOptions?.playing;
    const reloadCnt = remoteOptions?.reloadCnt;

    logger.debug(`checkPlaying`, cnt, isPlaying, playing, localOptions, remoteOptions, mediaOptions);
    if ( playing
        || isPlaying
        || RioHelper.isTrue(onFilter)
        || reloadCnt > 3
         ) {
        clearTimeout(this.intervalRemote);
        return;
    }
    if (cnt++ > 3) {
        clearTimeout(this.intervalRemote);
        RioMedia.incReloadCnt(true);
        return RoomListener.sendReloadCamera();
    }
    this.intervalRemote = setTimeout(() => {
        this.checkRemotePlaying(cnt);
    }, 1000);
};

/**
 * attach new local video track and replace old track to new track
 * 
 * @returns void returns the new track.
 */
 RioRoom.prototype._attachMediaStream = function(track, options) {
     const {
        isLocal,
    } = options;

    if (!this.room) {
        return;
    }
    const newOptions = Object.assign({}, options, {
        isSwitch: true,
        reattach: true
    });
    const {localStreamId} = this.options;
    let streamId = localStreamId;
    if (isLocal === false) {
        streamId = RioMedia.getRemoteStreamId();
    }
    
    RioMedia.attachMediaStream(streamId, track, newOptions);
    setTimeout(() => {
        this.changeVideoQuality(undefined);
    }, 600);
};

/**
 * This function is called when the connection fail.
 */
RioRoom.prototype.attachRemoteStream = function (remoteId) {
    if (!this.room) {
        return;
    }
    const { callInfo } = {...this};
    const { initiatorID, currentUserID, acceptCallType, callType } = callInfo;
    
    let isVideo = false;
    if (initiatorID == currentUserID) {
        isVideo = (acceptCallType === Constants.CallType.VIDEO);
    } else {
        isVideo = (callType === Constants.CallType.VIDEO);
    }
    logger.debug(`attachRemoteStream ${isVideo}`, callInfo);

    //show avatar instead of stream
    RioMedia.showAvatar({
        isLocal: false
    });
}

/**
 * Application send to background
 * @param {Object} options
 */
RioRoom.prototype.onPause = function(options) {
    logger.debug(`onPause`);
    const state = this.getState();
    const cloneOptions = {...this.options};
    const { pausedFlg } = cloneOptions;

    if (state !== STATE.IN_CALL || pausedFlg) {
        return true;
    }

    this.setReloaded(false);
    this.setPaused(true);
    return RoomListener.sendPause();
}

/**
 * Application resume from background
 * @param {Object} options
 */
RioRoom.prototype.onResume = function(options) {
    logger.debug(`onResume`);
    const state = this.getState();
    const cloneOptions = {...this.options};
    const { pausedFlg } = cloneOptions;

    if (state !== STATE.IN_CALL || !pausedFlg) {
        return true;
    }

    this._disposeRemoteTrack();
    this.setPaused(false);
    RoomListener.sendResume();
    return this.reloadTrack({
        mediaType: MediaType.VIDEO
    });
}

/**
 * dispose Remote Track
 */
RioRoom.prototype._disposeRemoteTrack = async function() {
    logger.debug(`_disposeRemoteTrack`);
    //show avatar instead of stream
    RioMedia.showAvatar({
        isLocal: false
    });
    //const remoteTracks = this.getRemoteTracks();
    //const found = remoteTracks.filter((stream) => {
    //    return (stream.type && stream.type == MediaType.VIDEO);
    //});
    //const jitsiTrack = found.length ? found.shift() : false;
    //if found
    //if (jitsiTrack) {
    //    await RioMedia.detach(jitsiTrack, true);
    //    await jitsiTrack.dispose();
    //}
}

/**
 * Application resume from background
 * @param {Object} options
 */
RioRoom.prototype.setReloaded = function(value, isAudio) {
    const flag = RioHelper.isTrue(value) ? true : false;
    if ( isAudio === true ) {
        this.options['reloadAudio'] = flag;
    } else {
        this.options['reloadVideo'] = flag;
    }
}

/**
 * Application resume from background
 * @param {Object} options
 */
RioRoom.prototype.setPaused = function(value) {
    if ( RioHelper.isTrue(value) ) {
        this.options['pausedFlg'] = true;
    } else {
        this.options['pausedFlg'] = false;
    }
}

/**
 * That function is called when connection is established successfully
 */
RioRoom.prototype._onRemotePauseResume = function (value) {
    logger.debug(`_onRemotePauseResume`, value);

    if ( RioHelper.isTrue(value) ) {
        this.setReloaded(false);
        RioMedia.showAvatar({
            isLocal: false
        });
    } else {
        this.reloadTrack({
            mediaType: MediaType.VIDEO
        });
    }
}

/**
 * listen on remote send call info
 */
RioRoom.prototype._onRemoteCallInfo = function (options) {
    const {videoMuted, isVideo} = options;
    this.options['remoteOptions'] = options;
    logger.debug(`onRemoteCallInfo`, options);

    setTimeout( () => {
        RioMeetJS.videoroom.onRemoteUpdated(options);
    }, 0);
    
    if ( RioHelper.isTrue(videoMuted) || !RioHelper.isTrue(isVideo)) {
        //show avatar instead of stream
        RioMedia.showAvatar({
            isLocal: false
        });
    }
    if ( !RioHelper.isTrue(videoMuted) && RioHelper.isTrue(isVideo)) {
        RioMedia.hideAvatar({
            isLocal: false
        });
        //check remote play
        //this.intervalRemote = setTimeout(() => {
        //    this.checkRemotePlaying(0);
        //}, 3000);
    }
}

/**
 * That function is called when connection is established successfully
 */
RioRoom.prototype._onReloadCamera = function (value) {
    logger.debug(`_onReloadCamera`, value);
    if ( RioHelper.isTrue(value) ) {
        this.setReloaded(false, false);
        return this.reloadTrack({
            mediaType: MediaType.VIDEO
        });
    }
}

/**
 * check call type is valid
 * @param type {String}
 * @param callType {String}
 */
 RioRoom.prototype._getCallType = function(type, callType) {
    if ((!type || type === 'undefined') && callType) {
        type = callType;
    }

    if (type === Constants.CallType.AUDIO) {
        return Constants.CallType.AUDIO;
    }
    return Constants.CallType.VIDEO;
 }

/**
 * check is iOS app
 *
 * @returns {boolean}
 */
 RioRoom.prototype.iosApp = function() {
    if (this.mode === Constants.MODE.API 
        // && RioHelper.isIosBrowser() 
        ) {
        
        return true;
    }

    return false;
 }

/**
 * check is iOS app
 *
 * @returns {boolean}
 */
 RioRoom.prototype.isDisableAudio = function() {
    if (this.iosApp()) {
        return true;
    }

    return false;
 }
/* global __filename, $, Promise */
import { getLogger } from '@jitsi/logger';
//import * as Tone from "tone";
import ToneEffect from "./ToneEffect";
import {RecordRTC} from "./RecordRTC";
import RioHelper from '../../extends/RioHelper';
import { audioElement } from '../../../functions';

const logger = getLogger(__filename);
const isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const auId = "audio-recorded";

/**
 * Possible audio formats MIME types
 */
const AUDIO_WEBM = 'audio/webm;codecs=pcm'; // Supported in chrome
//const AUDIO_OGG = 'audio/ogg;codecs=opus'; // Supported in firefox

export default class AudioRecording {

    /**
     * Number
     */
    _started = false;

    /**
     * Number
     */
    _timeout = null;

    /**
     * Number
     */
    _interval = null;

    /**
     * Number
     */
    _mimeType = "audio/mp4";
    
    /**
     * MediaStream resulted from mixing.
     */
    _mixedMediaStream = undefined;

    /**
     * Tone.UserMedia
     */
    _audioMixer = undefined;

    /**
     * MediaRecorder
     */
    recorder = undefined;

    _value = 0;

    _isError = false;

    _effectStream = undefined;
    _audio = undefined;
    /**
     * Creates AudioRecording.
     *
     */
    constructor() {
        this._audioMixer = '';
        this.shifter  =  '';
        this.chunks = [];
        const mediaTypes = this.getSupportedMimeTypes();
        
        if(!MediaRecorder.isTypeSupported) {
            this._mimeType = "audio/mp4";
        } else if(mediaTypes.length > 0) {
            this._mimeType = mediaTypes.shift();
        } else if(MediaRecorder.isTypeSupported(AUDIO_WEBM)) {
            this._mimeType = AUDIO_WEBM;
        } else if(MediaRecorder.isTypeSupported('audio/webm')) {
            this._mimeType = 'audio/webm';
        }
        //logger.info('mimeType', this._mimeType, mediaTypes);
    }

    async setEffect(options) {
        const {value, timeout} = options;

        this._value = value;
        this._timeout = (timeout * 1000);

        this._effectStream = new ToneEffect(options);
        await this._effectStream.setEffect(options);
        return this;
    }

    async getStream() {
        if(typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
            alert('This browser does not supports WebRTC getUserMedia API.');

            if(!!navigator.getUserMedia) {
                alert('This browser seems supporting deprecated getUserMedia API.');
            }
        }

        if (!this._mixedMediaStream) {
            this._audio = audioElement(null, {id: auId});

            await navigator.mediaDevices.getUserMedia({
                audio: isEdge ? true : {
                    echoCancellation: false
                }
            }).then((stream) => {
                if(isSafari) {
                    this._audio = audioElement(null, {id: auId});

                    this._audio.muted = true;
                    this._audio.srcObject = stream;
                }
                this._mixedMediaStream = stream;
            }).catch(function(err) {
                this._isError = true;
                logger.log(`Error: Unable to capture your microphone: ${err?.message}`);
            });

            return await this.getStream();
        }

        this._audio = audioElement(null, {id: auId});
        this._audio.muted = true;
        this._audio.srcObject = this._mixedMediaStream;

        return this;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    async start() {
        try {
            this._started = true;
            if (this._isError) {
                return;
            }

            const options = {
                mimeType: this._mimeType,
                sampleRate: 48000,
                bufferSize: 16384,
                numberOfAudioChannels: 1
            };
            if (RioHelper.isIosBrowser()) {
                options.sampleRate = 44100;
                options.bufferSize = 16384;
                options.numberOfAudioChannels = 2;
                options.videoBitsPerSecond = 1048576;
                options.audioBitsPerSecond = 128000;
            }

            const recorder = new MediaRecorder(
                this._effectStream.getStream()
                , options
            );

            recorder.ondataavailable = (e) => {
                this.chunks.push(e.data);
            }
            recorder.onstop = (e) => {
                //or if you want to allow the user to save to a local file:
                //URL.createObjectURL(new Blob(recorderChunks), { 'type': 'audio/x-matroska' })...
                //alert(`onstop: AudioRecording recorder: ${this.chunks.length} ${this._mimeType}`);
                if (this.chunks.length && this._started) {
                    this.stopEffect();
                    const blobURL = window.URL.createObjectURL(new Blob(this.chunks, { 'type': this._mimeType }));
                    if (RioHelper.isIosBrowser()) {
                        return this._play(blobURL);
                    } else {
                        this._audio = new Audio(blobURL);
                        this._audio.play()
                        .then(() => {
                            this.stop()
                        }).catch(err => {
                            logger.log(`Error: AudioRecording recorder: ${err?.message}`);
                        });
                    }
                } else {
                    this.stopEffect();
                    this.stop();
                }
            }
            recorder.start();
            this._interval = setTimeout(() => {
                recorder.stop();
            }, this._timeout);

            this.recorder = recorder;
            return recorder;
        } catch (err) {
            logger.log(`Error: AudioRecording start: ${err?.message}`);
        }
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    async startIOS() {
        var recorder; // globally accessible
        var options = {
            type: 'audio',
            numberOfAudioChannels: isEdge ? 1 : 2,
            checkForInactiveTracks: true,
            bufferSize: 16384,
            disableLogs: false
        };

        if(navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
            options.sampleRate = 48000; // or 44100 or remove this line for default
        }

        if(isSafari) {
            options.sampleRate = 44100;
            options.bufferSize = 4096;
            options.numberOfAudioChannels = 2;
        }

        if(recorder) {
            recorder.destroy();
            recorder = null;
        }

        // a reference to user's recordRTC object
        var self = this;
        try {
            const stream = this._effectStream.startEffect(this._mixedMediaStream);
            
            recorder = new RecordRTC(stream, options);
            recorder.startRecording();
            this._interval = setTimeout(() => {
                recorder.stopRecording(stopRecordingCallback);
            }, this._timeout);

            this.recorder = recorder;
            return recorder;
        } catch (err) {
            alert(`Error: AudioRecording start: ${err?.message}`);
            logger.log(`Error: AudioRecording start: ${err?.message}`, err);
        }

        function stopRecordingCallback() {
            const blobURL = window.URL.createObjectURL(recorder.getBlob());
            self._audio = audioElement(blobURL, {id: auId});
            
            let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            let xhr = new XMLHttpRequest();
            xhr.open('GET', blobURL);
            xhr.responseType = 'arraybuffer';
            xhr.addEventListener('load', () => {
                let playsound = (audioBuffer) => {
                    let source = audioCtx.createBufferSource();
                    source.buffer = audioBuffer;
                    source.connect(audioCtx.destination);
                    source.loop = false;
                    source.start();
                };
                audioCtx.decodeAudioData(xhr.response).then(playsound);
            });
            xhr.send();
            return;
            setTimeout(function() {
                if(!self._audio.paused) {
                    self.stop();
                    return;
                }

                setTimeout(function() {
                    if(!self._audio.paused) {
                        self.stop();
                        return;
                    }
                    self._audio.play();
                }, 1000);
                
                self._audio.play();
            }, 300);

            self._audio.play();
        }
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
    */
    _play(blobURL) {
        try {
            let audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            let xhr = new XMLHttpRequest();
            xhr.open('GET', blobURL);
            xhr.responseType = 'arraybuffer';
            xhr.addEventListener('load', () => {
                let playsound = (audioBuffer) => {
                    const audio = audioCtx.createBufferSource();
                    audio.buffer = audioBuffer;
                    audio.connect(audioCtx.destination);
                    audio.loop = false;
                    this._audio = audio;

                    this._audio.start();
                };
                audioCtx.decodeAudioData(xhr.response).then(playsound);
                this._interval = setTimeout(() => {
                    this.stop();
                }, this._timeout);
            });
            xhr.send();
        }
        catch (err) {
        }
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    abort() {
        try {
            if(this._interval) {
                clearTimeout(this._interval);
            }

            if (this._audio) {
                this._audio.pause();
                this._audio.currentTime = 0;
                this._audio = undefined;
            }

            if (this.recorder && this._started) {
                if (RioHelper.isIosBrowser()) {
                    this.recorder.destroy();
                } else {
                    this.recorder.stop();
                }
                this._started = false;
            }
            this._isError = false;
        }
        catch (err) {
        }
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stopEffect() {
        if(this._effectStream) {
            this._effectStream.dispose();
            delete this._effectStream;
        }
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stop() {
        this._started = false;
        this.chunks = [];
        if(this._effectStream) {
            this._effectStream.dispose();
            delete this._effectStream;
        }

        if(this._audio) {
            if (RioHelper.isIosBrowser()) {
                //this._audio.srcObject = null;
                this._audio.disconnect();
            }
            delete this._audio;
        }

        if(this._mixedMediaStream) {
            if (!RioHelper.isIosBrowser()) {
                this._mixedMediaStream.disconnect();
            }
            delete this._mixedMediaStream;
        }

        this._isError = false;
        if(this._interval) {
            clearTimeout(this._interval);
        }
    }

    /**
     * Determines which kind of audio recording the browser supports
     * chrome supports "audio/webm" and firefox supports "audio/ogg"
     */
    getSupportedMimeTypes() {
        const mediaTypes = ['audio', 'video'];
        const FILE_EXTENSIONS = ['mp4', 'webm', 'ogg' ]
        const CODECS = ['h264', 'h.264', 'opus', 'vp9', 'vp9.0', 'vp8', 'vp8.0', 'h265', 'h.265' ]
        
        return [...new Set(
            mediaTypes.flatMap(mediaType => 
                FILE_EXTENSIONS.flatMap(ext =>
                    CODECS.flatMap(codec =>
                    [
                        `${mediaType}/${ext};codecs=${codec},pcm`,
                        `${mediaType}/${ext};codecs=${codec},wav`,
                        `${mediaType}/${ext};codecs=${codec}`,
                        `${mediaType}/${ext};codecs:${codec}`,
                        `${mediaType}/${ext}`,
                    ]),
                ),
            ),
        )].filter(variation => MediaRecorder.isTypeSupported(variation))
    }
}

import Logger from '@jitsi/logger';

import { appConfig } from "./config";
import Constants from './Constants';
import * as JitsiConferenceErrors from './JitsiConferenceErrors';
import * as JitsiConferenceEvents from './JitsiConferenceEvents';
import JitsiConnection from './JitsiConnection';
import * as JitsiConnectionErrors from './JitsiConnectionErrors';
import * as JitsiConnectionEvents from './JitsiConnectionEvents';
import JitsiMediaDevices from './JitsiMediaDevices';
import * as JitsiMediaDevicesEvents from './JitsiMediaDevicesEvents';
import JitsiTrackError from './JitsiTrackError';
import * as JitsiTrackErrors from './JitsiTrackErrors';
import * as JitsiTrackEvents from './JitsiTrackEvents';
import * as JitsiTranscriptionStatus from './JitsiTranscriptionStatus';
import RTCUtils from './modules/RTC/RTCUtils';
import RTC from './modules/RTC/RTC';
import browser from './modules/browser';
import NetworkInfo from './modules/connectivity/NetworkInfo';
import { TrackStreamingStatus } from './modules/connectivity/TrackStreamingStatus';
import getActiveAudioDevice from './modules/detection/ActiveDeviceDetector';
import * as DetectionEvents from './modules/detection/DetectionEvents';
import TrackVADEmitter from './modules/detection/TrackVADEmitter';
import FeatureFlags from './modules/flags/FeatureFlags';
import ProxyConnectionService
    from './modules/proxyconnection/ProxyConnectionService';
import recordingConstants from './modules/recording/recordingConstants';
import Settings from './modules/settings/Settings';
import LocalStatsCollector from './modules/statistics/LocalStatsCollector';
import precallTest from './modules/statistics/PrecallTest';
import Statistics from './modules/statistics/statistics';
import AuthUtil from './modules/util/AuthUtil';
import GlobalOnErrorHandler from './modules/util/GlobalOnErrorHandler';
import ScriptUtil from './modules/util/ScriptUtil';
import * as VideoSIPGWConstants from './modules/videosipgw/VideoSIPGWConstants';
import AudioMixer from './modules/webaudio/AudioMixer';
import { MediaType } from './service/RTC/MediaType';
import * as ConnectionQualityEvents
    from './service/connectivity/ConnectionQualityEvents';
import * as E2ePingEvents from './service/e2eping/E2ePingEvents';
import { createGetUserMediaEvent } from './service/statistics/AnalyticsEvents';

import logger from './modules/extends/RioLogger';
import RioHelper from './modules/extends/RioHelper';
import RioTimer from './modules/extends/RioTimer';
import RioAuth from './modules/extends/RioAuth';
import RioAppClient from './modules/extends/RioAppClient';
import VideoRoom from './modules/extends/VideoRoom';

import RioSession from './RioSession';
import RioApp from './RioApp';

/**
 * The amount of time to wait until firing
 * {@link JitsiMediaDevicesEvents.PERMISSION_PROMPT_IS_SHOWN} event.
 */
const USER_MEDIA_SLOW_PROMISE_TIMEOUT = 1000;

/**
 * Extracts from an 'options' objects with a specific format (TODO what IS the
 * format?) the attributes which are to be logged in analytics events.
 *
 * @param options gum options (???)
 * @returns {*} the attributes to attach to analytics events.
 */
function getAnalyticsAttributesFromOptions(options) {
    const attributes: any = {};

    attributes['audio_requested'] = options.devices.includes('audio');
    attributes['video_requested'] = options.devices.includes('video');
    attributes['screen_sharing_requested'] = options.devices.includes('desktop');

    if (attributes.video_requested) {
        attributes.resolution = options.resolution;
    }

    return attributes;
}

interface ICreateLocalTrackOptions {
    cameraDeviceId?: string;
    devices?: any[];
    firePermissionPromptIsShownEvent?: boolean;
    fireSlowPromiseEvent?: boolean;
    micDeviceId?: string;
    resolution?: string;
}

interface IJitsiMeetJSOptions {
    enableAnalyticsLogging?: boolean;
    enableWindowOnErrorHandler?: boolean;
    externalStorage?: Storage;
    flags?: {
        runInLiteMode?: boolean;
        ssrcRewritingEnabled?: boolean;
        sourceNameSignaling?: boolean;
        sendMultipleVideoStreams?: boolean;
        receiveMultipleVideoStreams?: boolean;
    },
    creds?: {
        secret_id?: string;
        secret_key?: string;
    },
    appSettings?: {
    }
}

/**
 * The public API of the Jitsi Meet library (a.k.a. {@code JitsiMeetJS}).
 */
export default {

    version: '{#COMMIT_HASH#}',

    Connection: JitsiConnection,

    /**
     * {@code ProxyConnectionService} is used to connect a remote peer to a
     * local Jitsi participant without going through a Jitsi conference. It is
     * currently used for room integration development, specifically wireless
     * screensharing. Its API is experimental and will likely change; usage of
     * it is advised against.
     */
    ProxyConnectionService,

    constants: Object.assign({}, Constants, {
        recording: recordingConstants,
        sipVideoGW: VideoSIPGWConstants,
        transcriptionStatus: JitsiTranscriptionStatus,
        trackStreamingStatus: TrackStreamingStatus
    }),
    events: {
        conference: JitsiConferenceEvents,
        connection: JitsiConnectionEvents,
        detection: DetectionEvents,
        track: JitsiTrackEvents,
        mediaDevices: JitsiMediaDevicesEvents,
        connectionQuality: ConnectionQualityEvents,
        e2eping: E2ePingEvents
    },
    errors: {
        conference: JitsiConferenceErrors,
        connection: JitsiConnectionErrors,
        track: JitsiTrackErrors
    },
    errorTypes: {
        JitsiTrackError
    },
    logLevels: Logger.levels,
    mediaDevices: JitsiMediaDevices as unknown,
    analytics: Statistics.analytics as unknown,
    videoroom: new VideoRoom(),
    _options: {},
    init(options: IJitsiMeetJSOptions = {}) {
        // @ts-ignore
        logger.info(`[STEP] 0 init Release version: ${this.version}. This appears to be ${browser.getName()}, ver: ${browser.getVersion()}`);

        const appSettings = options.appSettings || {};
        //set cred information
        RioAuth.setCreds(options);

        const initOptions = Object.assign({}, options, appConfig, appSettings);
        const flags = initOptions.flags || {};
        
        Settings.init(initOptions.externalStorage);
        Statistics.init(initOptions);
        this._options = initOptions;

        //flags.sourceNameSignaling = false;
        //flags.sendMultipleVideoStreams = false;
        //flags.receiveMultipleVideoStreams = false;

        // Configure the feature flags.
        FeatureFlags.init(flags);

        // Initialize global window.connectionTimes
        // FIXME do not use 'window'
        if (!window.connectionTimes) {
            window.connectionTimes = {};
        }

        if (options.enableAnalyticsLogging !== true) {
            logger.warn('Analytics disabled, disposing.');
            this.analytics.dispose();
        }

        if (options.enableWindowOnErrorHandler !== false) {
            GlobalOnErrorHandler.addHandler(
                this.getGlobalOnErrorHandler.bind(this));
        }

        if (this.version) {
            const logObject = {
                id: 'component_version',
                component: 'lib-rio-meet',
                version: this.version
            };

            Statistics.sendLog(JSON.stringify(logObject));
        }
        this.localTracks = null;
        
        //create instance
        RioHelper.instance();
        document.addEventListener('DOMContentLoaded', this.domLoaded);

        return RTC.init(initOptions);
    },

    async auth(creds) {
        if (!this._session || !RioAuth.setCreds(creds)) {
            return Promise.resolve();
        }

        return this._session.auth();
    },

    createSession(creds) {
        if (!this._session) {
            this._session = new RioSession({});
        }
        if (creds) {
            this.auth(creds);
        }

        return this._session;
    },

    createRioApp(options) {
        if (!this._rioApp) {
            this._rioApp = new RioApp(options || {});
        }

        return this._rioApp;
    },

    initInstance() {
        //create instance RioAppClient
        RioHelper.instance();
        RioAppClient.instance();
    },

    /**
     * Returns whether the desktop sharing is enabled or not.
     *
     * @returns {boolean}
     */
    isDesktopSharingEnabled() {
        return RTC.isDesktopSharingEnabled();
    },

    /**
     * Returns whether the current execution environment supports WebRTC (for
     * use within this library).
     *
     * @returns {boolean} {@code true} if WebRTC is supported in the current
     * execution environment (for use within this library); {@code false},
     * otherwise.
     */
    isWebRtcSupported() {
        return RTC.isWebRtcSupported();
    },

    setLogLevel(level) {
        Logger.setLogLevel(level);
    },

    /**
     * Sets the log level to the <tt>Logger</tt> instance with given id.
     *
     * @param {Logger.levels} level the logging level to be set
     * @param {string} id the logger id to which new logging level will be set.
     * Usually it's the name of the JavaScript source file including the path
     * ex. "modules/xmpp/ChatRoom.js"
     */
    setLogLevelById(level, id) {
        Logger.setLogLevelById(level, id);
    },

    /**
     * Registers new global logger transport to the library logging framework.
     *
     * @param globalTransport
     * @see Logger.addGlobalTransport
     */
    addGlobalLogTransport(globalTransport) {
        Logger.addGlobalTransport(globalTransport);
    },

    /**
     * Removes global logging transport from the library logging framework.
     *
     * @param globalTransport
     * @see Logger.removeGlobalTransport
     */
    removeGlobalLogTransport(globalTransport) {
        Logger.removeGlobalTransport(globalTransport);
    },

    /**
    * Sets global options which will be used by all loggers. Changing these
    * works even after other loggers are created.
    *
    * @param options
    * @see Logger.setGlobalOptions
    */
    setGlobalLogOptions(options) {
        Logger.setGlobalOptions(options);
    },

    /**
     * Creates the media tracks and returns them trough the callback.
     *
     * @param options Object with properties / settings specifying the tracks
     * which should be created. should be created or some additional
     * configurations about resolution for example.
     * @param {Array} options.effects optional effects array for the track
     * @param {boolean} options.firePermissionPromptIsShownEvent - if event
     * JitsiMediaDevicesEvents.PERMISSION_PROMPT_IS_SHOWN should be fired
     * @param {boolean} options.fireSlowPromiseEvent - if event
     * JitsiMediaDevicesEvents.USER_MEDIA_SLOW_PROMISE_TIMEOUT should be fired
     * @param {Array} options.devices the devices that will be requested
     * @param {string} options.resolution resolution constraints
     * @param {string} options.cameraDeviceId
     * @param {string} options.micDeviceId
     * @param {intiger} interval - the interval (in ms) for
     * checking whether the desktop sharing extension is installed or not
     * @param {Function} checkAgain - returns boolean. While checkAgain()==true
     * createLocalTracks will wait and check on every "interval" ms for the
     * extension. If the desktop extension is not install and checkAgain()==true
     * createLocalTracks will finish with rejected Promise.
     * @param {Function} listener - The listener will be called to notify the
     * user of lib-jitsi-meet that createLocalTracks is starting external
     * extension installation process.
     * NOTE: If the inline installation process is not possible and external
     * installation is enabled the listener property will be called to notify
     * the start of external installation process. After that createLocalTracks
     * will start to check for the extension on every interval ms until the
     * plugin is installed or until checkAgain return false. If the extension
     * is found createLocalTracks will try to get the desktop sharing track and
     * will finish the execution. If checkAgain returns false, createLocalTracks
     * will finish the execution with rejected Promise.
     *
     * @deprecated old firePermissionPromptIsShownEvent
     * @returns {Promise.<{Array.<JitsiTrack>}, JitsiConferenceError>} A promise
     * that returns an array of created JitsiTracks if resolved, or a
     * JitsiConferenceError if rejected.
     */
    createLocalTracks(options = {} as any, oldfirePermissionPromptIsShownEvent) {
        let promiseFulfilled = false;

        logger.info('[STEP] 7 createLocalTracks', options);
        const { firePermissionPromptIsShownEvent, fireSlowPromiseEvent, ...restOptions } = options;
        const firePermissionPrompt = firePermissionPromptIsShownEvent || oldfirePermissionPromptIsShownEvent;

        if (firePermissionPrompt && !RTC.arePermissionsGrantedForAvailableDevices()) {
            // @ts-ignore
            JitsiMediaDevices.emitEvent(JitsiMediaDevicesEvents.PERMISSION_PROMPT_IS_SHOWN, browser.getName());
        } else if (fireSlowPromiseEvent) {
            window.setTimeout(() => {
                if (!promiseFulfilled) {
                    JitsiMediaDevices.emitEvent(JitsiMediaDevicesEvents.SLOW_GET_USER_MEDIA);
                }
            }, USER_MEDIA_SLOW_PROMISE_TIMEOUT);
        }

        if (!window.connectionTimes) {
            window.connectionTimes = {};
        }
        window.connectionTimes['obtainPermissions.start']
            = window.performance.now();

        return RTC.obtainAudioAndVideoPermissions(restOptions)
            .then(tracks => {
                promiseFulfilled = true;

                window.connectionTimes['obtainPermissions.end']
                    = window.performance.now();

                Statistics.sendAnalytics(
                    createGetUserMediaEvent(
                        'success',
                        getAnalyticsAttributesFromOptions(restOptions)));

                if (this.isCollectingLocalStats()) {
                    for (let i = 0; i < tracks.length; i++) {
                        const track = tracks[i];

                        if (track.getType() === MediaType.AUDIO) {
                            Statistics.startLocalStats(track,
                                track.setAudioLevel.bind(track));
                        }
                    }
                }

                // set real device ids
                const currentlyAvailableMediaDevices
                    = RTC.getCurrentlyAvailableMediaDevices();

                if (currentlyAvailableMediaDevices) {
                    for (let i = 0; i < tracks.length; i++) {
                        const track = tracks[i];

                        track._setRealDeviceIdFromDeviceList(
                            currentlyAvailableMediaDevices);
                    }
                }

                return tracks;
            })
            .catch(error => {
                promiseFulfilled = true;

                if (error.name === JitsiTrackErrors.SCREENSHARING_USER_CANCELED) {
                    // User cancelled action is not really an error, so only
                    // log it as an event to avoid having conference classified
                    // as partially failed
                    const logObject = {
                        id: 'screensharing_user_canceled',
                        message: error?.message
                    };

                    Statistics.sendLog(JSON.stringify(logObject));

                    Statistics.sendAnalytics(
                        createGetUserMediaEvent(
                            'warning',
                            {
                                reason: 'extension install user canceled'
                            }));
                } else if (error.name === JitsiTrackErrors.NOT_FOUND) {
                    // logs not found devices with just application log to cs
                    const logObject = {
                        id: 'usermedia_missing_device',
                        status: error.gum.devices
                    };

                    Statistics.sendLog(JSON.stringify(logObject));

                    const attributes
                        = getAnalyticsAttributesFromOptions(options);

                    attributes.reason = 'device not found';
                    attributes.devices = error.gum.devices.join('.');
                    Statistics.sendAnalytics(
                        createGetUserMediaEvent('error', attributes));
                } else {
                    // Report gUM failed to the stats
                    Statistics.sendGetUserMediaFailed(error);

                    const attributes
                        = getAnalyticsAttributesFromOptions(options);

                    attributes.reason = error.name;
                    Statistics.sendAnalytics(
                        createGetUserMediaEvent('error', attributes));
                }

                window.connectionTimes['obtainPermissions.end']
                    = window.performance.now();

                return Promise.reject(error);
            });
    },

    /**
     * Create a TrackVADEmitter service that connects an audio track to an VAD (voice activity detection) processor in
     * order to obtain VAD scores for individual PCM audio samples.
     * @param {string} localAudioDeviceId - The target local audio device.
     * @param {number} sampleRate - Sample rate at which the emitter will operate. Possible values  256, 512, 1024,
     * 4096, 8192, 16384. Passing other values will default to closes neighbor.
     * I.e. Providing a value of 4096 means that the emitter will process 4096 PCM samples at a time, higher values mean
     * longer calls, lowers values mean more calls but shorter.
     * @param {Object} vadProcessor - VAD Processors that does the actual compute on a PCM sample.The processor needs
     * to implement the following functions:
     * - <tt>getSampleLength()</tt> - Returns the sample size accepted by calculateAudioFrameVAD.
     * - <tt>getRequiredPCMFrequency()</tt> - Returns the PCM frequency at which the processor operates.
     * i.e. (16KHz, 44.1 KHz etc.)
     * - <tt>calculateAudioFrameVAD(pcmSample)</tt> - Process a 32 float pcm sample of getSampleLength size.
     * @returns {Promise<TrackVADEmitter>}
     */
    createTrackVADEmitter(localAudioDeviceId, sampleRate, vadProcessor) {
        return TrackVADEmitter.create(localAudioDeviceId, sampleRate, vadProcessor);
    },

    /**
     * Create AudioMixer, which is essentially a wrapper over web audio ChannelMergerNode. It essentially allows the
     * user to mix multiple MediaStreams into a single one.
     *
     * @returns {AudioMixer}
     */
    createAudioMixer() {
        return new AudioMixer();
    },

    /**
     * Go through all audio devices on the system and return one that is active, i.e. has audio signal.
     *
     * @returns Promise<Object> - Object containing information about the found device.
     */
    getActiveAudioDevice() {
        return getActiveAudioDevice();
    },

    /**
     * Checks if its possible to enumerate available cameras/microphones.
     *
     * @returns {Promise<boolean>} a Promise which will be resolved only once
     * the WebRTC stack is ready, either with true if the device listing is
     * available available or with false otherwise.
     * @deprecated use JitsiMeetJS.mediaDevices.isDeviceListAvailable instead
     */
    isDeviceListAvailable() {
        logger.warn('This method is deprecated, use '
            + 'RioMeetJS.mediaDevices.isDeviceListAvailable instead');

        return this.mediaDevices.isDeviceListAvailable();
    },

    /**
     * Returns true if changing the input (camera / microphone) or output
     * (audio) device is supported and false if not.
     *
     * @param {string} [deviceType] - type of device to change. Default is
     * {@code undefined} or 'input', 'output' - for audio output device change.
     * @returns {boolean} {@code true} if available; {@code false}, otherwise.
     * @deprecated use JitsiMeetJS.mediaDevices.isDeviceChangeAvailable instead
     */
    isDeviceChangeAvailable(deviceType) {
        logger.warn('This method is deprecated, use '
            + 'RioMeetJS.mediaDevices.isDeviceChangeAvailable instead');

        return this.mediaDevices.isDeviceChangeAvailable(deviceType);
    },


    /**
     * Checks if the current environment supports having multiple audio
     * input devices in use simultaneously.
     *
     * @returns {boolean} True if multiple audio input devices can be used.
     */
    isMultipleAudioInputSupported() {
        return this.mediaDevices.isMultipleAudioInputSupported();
    },

    /**
     * Checks if local tracks can collect stats and collection is enabled.
     *
     * @param {boolean} True if stats are being collected for local tracks.
     */
    isCollectingLocalStats() {
        return Statistics.audioLevelsEnabled && LocalStatsCollector.isLocalStatsSupported();
    },

    /**
     * Executes callback with list of media devices connected.
     *
     * @param {function} callback
     * @deprecated use RioMeetJS.mediaDevices.enumerateDevices instead
     */
    enumerateDevices(callback) {
        logger.warn('This method is deprecated, use '
            + 'RioMeetJS.mediaDevices.enumerateDevices instead');
        this.mediaDevices.enumerateDevices(callback);
    },

    /* eslint-disable max-params */

    /**
     * @returns function that can be used to be attached to window.onerror and
     * if options.enableWindowOnErrorHandler is enabled returns
     * the function used by the lib.
     * (function(message, source, lineno, colno, error)).
     */
    getGlobalOnErrorHandler(message, source, lineno, colno, error) {
        logger.error(
            `UnhandledError: ${message}`,
            `Script: ${source}`,
            `Line: ${lineno}`,
            `Column: ${colno}`);
        //logger.error('StackTrace: ', error);
        //logger.info('getGlobalOnErrorHandler: ', window?.connectionTimes);
        
        Statistics.reportGlobalError(error);
    },

    /**
     * Informs lib-jitsi-meet about the current network status.
     *
     * @param {object} state - The network info state.
     * @param {boolean} state.isOnline - {@code true} if the internet connectivity is online or {@code false}
     * otherwise.
     */
    setNetworkInfo({ isOnline }) {
        NetworkInfo.updateNetworkInfo({ isOnline });
    },

    /**
     * Queries for connected A/V input and output devices and updates the redux
     * state of known devices.
     *
     * @returns {Function}
     */
    async getAvailableDevices(kind) {
        return new Promise(resolve => {
            const { mediaDevices } = this;

            if (mediaDevices.isDeviceListAvailable()
                    && mediaDevices.isDeviceChangeAvailable()) {
                mediaDevices.enumerateDevices(devices => {
                    logger.log("[debug] getAvailableDevices", JSON.stringify(devices));
                    if (kind !== undefined) {
                        const mapDevices = devices.filter(device => device.kind === kind);
                        resolve(mapDevices);
                    } else {
                        resolve(devices);
                    }
                });
            } else {
                resolve([]);
            }
        });
    },

    /**
    * Creates a {@link JitsiLocalTrack} model from the given device id.
    *
    * @param {string} type - The media type of track being created. Expected values
    * are "video" or "audio".
    * @param {string} deviceId - The id of the target media source.
    * @param {number} [timeout] - A timeout for the RioMeetJS.createLocalTracks function call.
    * @param {Object} additionalOptions - Extra options to be passed to lib-jitsi-meet's {@code createLocalTracks}.
    *
    * @returns {Promise<JitsiLocalTrack>}
    */
    createLocalTrack(type, deviceId, timeout=0, additionalOptions = {}) {
        const otherOptions = (additionalOptions) ? {...additionalOptions} : {};
        const screenSizes = RioHelper.getScreenSize();

        if (timeout === undefined) {
            // On Electron there is no permission prompt for granting permissions. That's why we don't need to
            // spend much time displaying the overlay screen. If GUM is not resolved within 15 seconds it will
            // probably never resolve.
            //timeout = browser.isElectron() ? 15000 : 60000;
        }

        const constraints = Object.assign({}, {
            devices: [ type ],
            deviceId: deviceId,
            timeout
        }, otherOptions
        ,{
            //constraints: screenSizes
        }) as any;
        
        if (type === Constants.MediaType.VIDEO) {
            constraints.cameraDeviceId = deviceId;
        } else if (type === Constants.MediaType.AUDIO) {
            constraints.micDeviceId = deviceId;
        }
        const requestConstraints = RioHelper.obtainFacingMode(constraints);

        logger.info('[STEP] 7-1 createLocalTrack', requestConstraints, JSON.stringify(constraints));
        return (RTC.obtainAudioAndVideoPermissions(requestConstraints)
            .then(tracks => {
                // set real device ids
                const currentlyAvailableMediaDevices
                    = RTC.getCurrentlyAvailableMediaDevices();

                if (currentlyAvailableMediaDevices) {
                    for (let i = 0; i < tracks.length; i++) {
                        const track = tracks[i];

                        track._setRealDeviceIdFromDeviceList(
                            currentlyAvailableMediaDevices);
                    }
                }
                if (this.isCollectingLocalStats()) {
                    for (let i = 0; i < tracks.length; i++) {
                        const track = tracks[i];

                        if (track.getType() === MediaType.AUDIO) {
                            Statistics.startLocalStats(track,
                                track.setAudioLevel.bind(track));
                        }
                    }
                }
                return tracks;
            })
            .then(([ localTrack ]) => localTrack));
    },

    /**
     * get getConstraints detail
     *
     * @param {array} options.devices The devices that will be requested.
     * @param {string} options.resolution Resolution constraints.
     * @param {string} options.cameraDeviceId
     * @param {string} options.micDeviceId
     * @returns {Object} return object constraints
    */
    obtainConstraints(options: any) {
        const {
            audio, 
            video, 
            deviceId,
            micDeviceId,
            cameraDeviceId,
            facingMode,
            resolution,
            ...otherOptions
        } = options;

        let devices = options.devices || [];
        if (audio === true) {
            devices.push('audio');
        }
        if (video === true) {
            devices.push('video');
        }
        const umDevices = devices.filter(device => device === 'audio' || device === 'video' || device === 'desktop');
        const mediaConstraints = Object.assign({}, {
                devices: umDevices,
                deviceId: deviceId || 'default',
                micDeviceId: micDeviceId || 'default',
                cameraDeviceId: cameraDeviceId || 'default',
                facingMode: facingMode
            },
            otherOptions
        ) as any;
        if (!resolution) {
            mediaConstraints.resolution = this.getResolution();
        }

        const requestConstraints = RioHelper.obtainFacingMode(mediaConstraints);
        const constraints = RTCUtils.obtainConstraints(requestConstraints);

        logger.info('obtainConstraints', options, JSON.stringify(mediaConstraints), constraints);
        return constraints;
    },

    /* eslint-disable */
    /**
     * set current resolution
     */
    setResolution(resolution) {
        this._options['resolution'] = resolution;
    },

    /* eslint-disable */
    /**
     * get current resolution
     */
    getResolution() {
        return this._options?.resolution || 720;
    },

    /**
     * init timer tracking on document loaded
     */
    domLoaded() {
        RioTimer.instance();
    },

    /* eslint-disable */
    /**
     * Check the brower is support Picture-in-Picture
     */
    isPIP() {
        // check for support
        let pipEnabled = true;
        if (!('pictureInPictureEnabled' in document)) {
            pipEnabled = false;
            logger.info('The Picture-in-Picture API is not available.');
        } else if (!document.pictureInPictureEnabled) {
            pipEnabled = false;
            logger.info('The Picture-in-Picture API is disabled.');
        }
        return pipEnabled;
    },

    /**
     * This function is called when the connection fail.
     */
    _onPreviewTracks(tracks, localStreamId) {
        for (let i = 0; i < tracks.length; i++) {
            const videoEle = document.getElementById(localStreamId) as HTMLVideoElement;
            const stream = tracks[i];
            stream.attach(videoEle);
            videoEle.onloadedmetadata = (e) => {
                videoEle.style.visibility = "visible";
                videoEle.play();
            };
        }
    },

    precallTest,

    /* eslint-enable max-params */

    /**
     * Represents a hub/namespace for utility functionality which may be of
     * interest to lib-jitsi-meet clients.
     */
    util: {
        AuthUtil,
        ScriptUtil,
        browser,
        logger: logger.instance()
    }
};

/* global __filename, $, Promise */

import EventEmitter from 'events';
import { appConfig } from "./config";
import Constants from './Constants';
import { MediaType } from './service/RTC/MediaType';
import HttpService from './modules/http/HttpService';
import SocketService from './modules/socket/SocketService';
import * as SocketEvent from './modules/socket/SocketEvent';
import RioRoom from './RioRoom';
import RioRecording from './RioRecording';
import RioMedia from './modules/extends/RioMedia';
import JitsiMediaDevices from './JitsiMediaDevices';
import * as JitsiMediaDevicesEvents from './JitsiMediaDevicesEvents';
import { delayTime } from './functions';
import RioAuth from './modules/extends/RioAuth';

const {STATE} = Constants;

import logger from './modules/extends/RioLogger';
import RioTimer from './modules/extends/RioTimer';

/**
 * Creates a RioSession object with the given name and properties.
 * Note: this constructor is not a part of the public API (objects should be
 * created using JitsiConnection.createConference).
 * @param options.config properties / settings related to the conference that
 * will be created.
 * @param options.name the name of the conference
 * @param options.connection the JitsiConnection object for this
 * RioSession.
 * @param {number} [options.config.avgRtpStatsN=15] how many samples are to be
 * collected by {@link AvgRTPStatsReporter}, before arithmetic mean is
 * calculated and submitted to the analytics module.
 * @param {boolean} [options.config.enableIceRestart=false] - enables the ICE
 * restart logic.
 * @param {boolean} [options.config.p2p.enabled] when set to <tt>true</tt>
 * the peer to peer mode will be enabled. It means that when there are only 2
 * participants in the conference an attempt to make direct connection will be
 * made. If the connection succeeds the conference will stop sending data
 * through the JVB connection and will use the direct one instead.
 * @param {number} [options.config.p2p.backToP2PDelay=5] a delay given in
 * seconds, before the conference switches back to P2P, after the 3rd
 * participant has left the room.
 * @param {number} [options.config.channelLastN=-1] The requested amount of
 * videos are going to be delivered after the value is in effect. Set to -1 for
 * unlimited or all available videos.
 * @param {number} [options.config.forceJVB121Ratio]
 * "Math.random() < forceJVB121Ratio" will determine whether a 2 people
 * conference should be moved to the JVB instead of P2P. The decision is made on
 * the responder side, after ICE succeeds on the P2P connection.
 * @constructor
 *
 * FIXME Make all methods which are called from lib-internal classes
 *       to non-public (use _). To name a few:
 *       {@link RioSession.onLocalRoleChanged}
 *       {@link RioSession.onUserRoleChanged}
 *       {@link RioSession.onMemberLeft}
 *       and so on...
 */
export default function RioSession(options) {
    this.options = {};
    this.eventEmitter = new EventEmitter();
    this._init();
    $(window).one('beforeunload', ev => {
        this.destroy();
        delayTime(600);
    });
}

// FIXME convert JitsiConference to ES6 - ASAP !
RioSession.prototype.constructor = RioSession;

/**
 * Initializes the conference object properties
 * @param options {object}
 */
RioSession.prototype._init = function() {
    // Override connection and xmpp properties (Useful if the connection
    // reloaded)
    this.isConnected = false;
    this.isAuth = false;
    this.initiatorID = null;
    this.currentUserID = null;
    this.calleeId = null;
    this.state = STATE.INITIAL;
    this.isLogged = false;
    HttpService.init(appConfig);
    this.rioRecording = null;
    this.callOptions = {
        language: ''
    };

    this._onSocketError
        = this._onSocketError.bind(this);

    this._onUserJoined
        = this._onUserJoined.bind(this);

    this._onUserLeave
        = this._onUserLeave.bind(this);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_ERROR, this._onSocketError);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_JOIN, this._onUserJoined);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_LEAVE, this._onUserLeave);

    this._unRegistCallEvent();

    this.deviceChangeListener = devices =>
        window.setTimeout(() => this._onDeviceListChanged(devices), 0);

    JitsiMediaDevices.addEventListener(
        JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
        this.deviceChangeListener);
};

/**
 * Initializes the conference object properties
 * @param options {object}
 */
 RioSession.prototype.auth = async function() {
    if (!this.isAuth || !this.options.appId) {
        const creds = RioAuth.getCreds();

        if (!creds.secret_id || !creds.secret_key) {
            const errmsg = 'Invalid secret_id or secret_key';
            logger.error(errmsg);
            this.onAuthListener(this, false);
            return Promise.reject('Unauthorized');
        }

        await HttpService.auth(creds)
        .then( (data) => {
            this.isAuth = true;
            const { token, app } = data;
            const { app_id, app_name, ...restOptions } = app;

            const appOptions = Object.assign({}
                , this.options
                , {
                    appId: app_id, 
                    appName: app_name
                }
            );
            const allOptions = Object.assign({}
                , appOptions
                , restOptions
            );

            this.options = appOptions;
            RioRoom.instance.init(allOptions);
            return Promise.resolve();
        })
        .catch( err => {
            logger.log(`Error: RioSession auth: ${err?.message}`, err);
            this.onAuthListener(this, false);
            return Promise.reject('Login failed');
        });
    }

    if (!this.isConnected && this.options.appId !== undefined) {
        const socketOptions = Object.assign({}, {
            socket: appConfig.endpoints.socket,
        }
        , this.options);

        SocketService.instance.init(socketOptions);
        SocketService.instance.once(
            SocketEvent.WS_EVENT_CONNECTION, (succ) => {
                if (succ) {
                    this.isConnected = true;
                    this.onAuthListener(this, true);
                } else {
                    this.onAuthListener(this, false);
                }
            });
        await SocketService.instance.connect(socketOptions);
    }
    return Promise.resolve();
};

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
 RioSession.prototype.login = async function(sessionId) {
    this.isLogged = false;
    let ret = {};
    try {
        await HttpService.login(sessionId)
        .then( (data) => {
            this.isLogged = true;
            this.currentUserID = sessionId;
            const { avatarUrl } = data || {};
            if (avatarUrl) {
                RioMedia.setAvatar(avatarUrl);
            }
        })
        .catch( err => {
            logger.log(`Error: RioSession login: ${sessionId} ${err?.message}`);
            return Promise.reject('Login failed');
        });

        if(this.isLogged === true) {
            await SocketService.instance.register(this.currentUserID)
            .then( (data) => {
                //register event
                this._registCallEvent();
                ret = data;
            });
        } else {
            return Promise.reject('Login failed');
        }
    } catch (err) {
        logger.log(`Error: RioSession Login failed: ${err?.message}`, err);
        return Promise.reject('Login failed');
    }
    return Promise.resolve(ret);
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
 RioSession.prototype.logout = async function() {
    try {
        this.destroy();
        this._init();
    } catch (err) {
        logger.log(`Error: RioSession logout: ${err?.message}`);
        return Promise.reject('Error');
    }

    return Promise.resolve();
}

/**
 * get list user for application
 * @param params {object}
 * @params.user_id {string alphabet or email}
 * @arams.name {string }
 */
 RioSession.prototype.registUser = async function(params) {
    if (!this.isConnected) {
        this.log('Application not authorized');
        return Promise.reject('Application not authorized');
    }
    if (!params.user_id) {
        this.log('user_id not empty');
        return Promise.reject('user_id not empty');
    }

    await HttpService.register(params)
    .then( (data) => {
        return Promise.resolve(data);
    })
    .catch( err => {
        logger.log(`Error: RioSession registUser: ${params.user_id} ${err?.message}`);
        return Promise.reject('Can not register user');
    });
};

/**
 * get list user for application
 * @param params {object}
 */
 RioSession.prototype.userList = async function(params = {}) {
    if (!this.isConnected) {
        this.log('Application not authorized');
        return Promise.reject('Application not authorized');
    }
    return await HttpService.userList(params)
    .then( (data) => {
        return Promise.resolve(data);
    })
    .catch( err => {
        logger.log(`Error: RioSession userList: ${err?.message}`);
        return Promise.reject('Can not get user data');
    });
};

/**
 * Removes all event listeners bound to the remote video track and clears
 * any related timeouts.
 *
 * @param {JitsiRemoteTrack} remoteTrack - The remote track which is being
 * removed from the conference.
 */
RioSession.prototype.attachMediaStream = async function (el, stream, options = {}) {
    try {
        RioMedia.setRemoteStreamId(el);
        RioRoom.instance.attachRemoteStream(el);
        await RioRoom.instance.attachMediaStream(el, stream, options);
    } catch (err) {
        logger.log(`Error: RioMedia attachMediaStream: ${err?.message}`);
    }
    return Promise.resolve();
}

/**
 * Attaches a handler for events(For example - "participant joined".) in the
 * conference. All possible event are defined in JitsiConferenceEvents.
 * @param eventId the event ID.
 * @param handler handler for the event.
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
 RioSession.prototype.on = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};

/**
 * Removes event listener
 * @param eventId the event ID.
 * @param [handler] optional, the specific handler to unbind
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
 RioSession.prototype.off = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.removeListener(eventId, handler);
    }
};

// Common aliases for event emitter
RioSession.prototype.addEventListener = RioSession.prototype.on;
RioSession.prototype.removeEventListener = RioSession.prototype.off;

/**
 * Creates and joins new conference.
 * @param name the name of the conference; if null - a generated name will be
 * provided from the api
 * @param options Object with properties / settings related to the conference
 * that will be created.
 * @returns {Promise} returns the new conference object.
 */
RioSession.prototype.getUserMedia = async function(mediaOptions = {}) {
    return RioRoom.instance.getUserMedia(mediaOptions);
};

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Promise}
 */
RioSession.prototype.setSpeaker = function(muted) {
    return new Promise(resolve => {
        const remoteTracks = RioRoom.instance.getRemoteTracks();
        remoteTracks.map(track => {
            if ( track.isAudioTrack() ) {
                return track.setMute();
            }
        });
        resolve(remoteTracks);
    });
}

/**
 * get new local audio track and replace old track to new track
 * 
 * @returns {Promise} returns the new track.
 */
RioSession.prototype.onToggleSpeaker = async function(isSpeaker) {
    return RioRoom.instance.toggleSpeaker(isSpeaker);
};

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Promise}
 */
RioSession.prototype.setMute = function(muted, type = 'audio') {
    return RioRoom.instance._setMute(muted, type);
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
 RioSession.prototype._registCallEvent = function() {
    this._onEventCallStart
        = this._onEventCallStart.bind(this);

    this._onCallListener
            = this._onCallListener.bind(this);

    this._onStopCallListener
        = this._onStopCallListener.bind(this);

    this._onEventCallResponse
        = this._onEventCallResponse.bind(this);

    this._onChangeQuality
        = this._onChangeQuality.bind(this);

    this._onVoiceChange
        = this._onVoiceChange.bind(this);

    this._onToggleEffect
        = this._onToggleEffect.bind(this);

    this._onEventCallReconnect
        = this._onEventCallReconnect.bind(this);

    SocketService.instance.on(
            SocketEvent.WS_EVENT_CALL_INCOMING,
            this._onCallListener);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_RESPONSE, this._onEventCallResponse);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_CALL_STOP, this._onStopCallListener);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_CHANGE_QUALITY, this._onChangeQuality);

    SocketService.instance.on(
            SocketEvent.WS_EVENT_VOICE_CHANGE, this._onVoiceChange);

    SocketService.instance.on(
        SocketEvent.WS_EVENT_TOGGLE_EFFECT, this._onToggleEffect);
        
    SocketService.instance.on(
        SocketEvent.WS_EVENT_CALL_RECONNECT, this._onEventCallReconnect);
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @param options object
 * @returns {Function}
 */
RioSession.prototype.call = async function(options = {}) {
    RioTimer.start('start');

    if (!this.isLogged ) {
        return Promise.reject(new Error('You not authozired'));
    } else if (this.state !== STATE.INITIAL) {
        return Promise.reject(new Error('You are calling'));
    }

    if (typeof options != 'object') {
        return Promise.reject(new Error('Parameter options not a object'));
    }

    try {
        const {userId, language, callType} = options;
        //clear MediaRecorder
        RioRoom.instance.stopCheckOutputAudio();

        this.callOptions = Object.assign({}
            , this.callOptions
            , {
                calleeId: userId,
                initiatorID: this.currentUserID,
                language: language,
                callType: callType
            }
        );

        const token = HttpService.getToken();

        this.state = STATE.WAITING;
        this.calleeId = userId;
        this.initiatorID = this.currentUserID;
        SocketService.instance.call(token, this.callOptions);
        RioRoom.instance.setUserId(this.calleeId);

        SocketService.instance.once(
            SocketEvent.WS_EVENT_CALL_START, this._onEventCallStart);

        return Promise.resolve();
    } catch (err) {
        logger.log(`Error: RioSession call: ${err?.message}`);
        return Promise.reject(new Error('An error occurred'));
    }
}

RioSession.prototype._onEventCallReconnect = function(wsJson) {
    RioRoom.instance.reconnect();
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
RioSession.prototype._onEventCallStart = function(type, wsJson) {
    const {callOptions} = {...this};
    logger.log('[debug] _onEventCallStart', type, wsJson);
    callOptions.initiatorID = this.initiatorID;
    callOptions.currentUserID = this.currentUserID;

    RioTimer.debug('onEventCallStart');
    RioTimer.start('startcall');

    RioRoom.instance.createConnection(wsJson, callOptions);
    if (type === 'caller') {
        const {from, to} = wsJson;
        const callInfo = {
           from,
           to,
           userId: to,
           currentUserID: this.currentUserID
        }
        RioMeetJS.videoroom.onAcceptCallListener(callInfo);
    }
}

RioSession.prototype._onEventCallResponse = function(type, wsJson) {
    const {userId, from, to, initiatorID} = wsJson;
    //logger.log('[debug] _onEventCallResponse', type, wsJson);
    //clear MediaRecorder
    RioRoom.instance.stopCheckOutputAudio();

    const callInfo = {
        from,
        to,
        userId: userId,
        initiatorID: initiatorID || from,
        currentUserID: this.currentUserID
    }

    if (type === SocketEvent.WS_CALL_REJECTED) {
        this.state = STATE.INITIAL;
        RioMeetJS.videoroom.onRejectCallListener(callInfo);
    } else if ( type === SocketEvent.WS_CALL_TIMEOUT
        || type === SocketEvent.WS_CALL_CANCEL
        ) {
        this.state = STATE.INITIAL;
        RioMeetJS.videoroom.onUserNotAnswerListener(callInfo);
    } else if (type === SocketEvent.WS_CALL_BUSY
            && this.currentUserID === from) {
        this.state = STATE.INITIAL;
        RioMeetJS.videoroom.onUserBusyListener(callInfo);
    } else if ( type === SocketEvent.WS_CALL_ACCEPTED ) {
        if (this.currentUserID === from) {
            this.state = STATE.IN_CALL
        } else {
            this.state = STATE.INITIAL
        }

        callInfo.userId = to;
        RioMeetJS.videoroom.onAcceptCallListener(callInfo);
    }
}

RioSession.prototype._onCallListener = function(wsJson) {
    const {from, calleeId, callType, language} = wsJson;
    this.initiatorID = from;
    this.calleeId = calleeId;
    //clear MediaRecorder
    RioRoom.instance.stopCheckOutputAudio();
    
    this.callOptions = Object.assign({}
        , this.callOptions
        , {
            from,
            to: calleeId,
            calleeId: calleeId,
            initiatorID: from,
            language: language,
            callType: callType
        }
    );

    this.state = STATE.WAITING;
    const {callOptions} = {...this};

    RioMeetJS.videoroom.onCallListener(this, callOptions);
    // SocketService.instance.once(
    //     SocketEvent.WS_EVENT_CALL_STOP,
    //     this._onStopCallListener);
}

RioSession.prototype._onStopCallListener = function(stopId, reason) {
    if (this.state !== STATE.INITIAL) {
        if ( stopId == this.calleeId
            || stopId == this.initiatorID
            || stopId == SocketEvent.FORCE_STOP_ID ) {

            if ( this.currentUserID != stopId ) {
                RioMeetJS.videoroom.onStopCallListener(stopId);
            } else if ( (this.currentUserID == stopId) && reason) {
                RioMeetJS.videoroom.onStopCallListener(stopId, reason);
            }
        }
    }
}

RioSession.prototype._onChangeQuality = function(msg) {
    RioRoom.instance.remoteChangeQuality(msg);
}

RioSession.prototype._onVoiceChange = function(options) {
    RioMeetJS.videoroom.onVoiceChange(options);
}

RioSession.prototype._onToggleEffect = function(options) {
    RioMeetJS.videoroom.onToggleEffect(options);
}

RioSession.prototype._onSocketError = function(msg) {
    const { errorAct, errorMsg } = msg;
    if ( errorAct == SocketEvent.WS_SEND_CALL 
        || errorAct == SocketEvent.WS_SEND_ROOM_START
        || errorAct == SocketEvent.WS_SEND_INCOMING_RESPONSE ) {
        
        RioMeetJS.videoroom.onError({
            errorMsg
        });
    }
}

RioSession.prototype._onUserJoined = function(userId) {
    RioMeetJS.videoroom.onUserJoined(userId);
}

RioSession.prototype._onUserLeave = function(userId) {
    RioMeetJS.videoroom.onUserLeave(userId);
}
/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
RioSession.prototype.accept = function(options) {
    RioTimer.start('start');
    if (!this.isLogged ) {
        return false;
    }
    this.state = STATE.IN_CALL;
    //detect language
    const {callType} = {...this.callOptions};
    const {language, acceptCallType} = options;
    this.callOptions = Object.assign({}
        , this.callOptions
        , {
            language,
            acceptCallType: acceptCallType || callType
        }
    );

    SocketService.instance.accept(this.currentUserID, this.callOptions);
    RioRoom.instance.setUserId(this.initiatorID);

    SocketService.instance.once(
        SocketEvent.WS_EVENT_CALL_START, this._onEventCallStart);
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
RioSession.prototype.reject = function() {
    if (!this.isLogged ) {
        return false;
    }
    this.state = STATE.INITIAL;

    SocketService.instance.reject(this.currentUserID);
    SocketService.instance.off(
        SocketEvent.WS_EVENT_CALL_START, this._onEventCallStart);
}

/**
 * Queries for connected A/V input and output devices and updates the redux
 * state of known devices.
 *
 * @returns {Function}
 */
RioSession.prototype.stop = function() {
    logger.log(`RioSession stop state:${this.state}`);
    if (this.state !== STATE.INITIAL) {
        this.state = STATE.INITIAL;

        RioRoom.instance._disconnect();
        SocketService.instance.stopCall(this.currentUserID);
    }

    SocketService.instance.off(
        SocketEvent.WS_EVENT_CALL_START, this._onEventCallStart);
    
    //check is stop recording
    this.stopLocalRecording(true);
}

RioSession.prototype.getCallInfo = function() {
    const {callOptions} = {...this};
    const callInfo = RioRoom.instance.getCallInfo();
    
    return Object.assign({}
        , callOptions
        , callInfo
    );
}

RioSession.prototype.getRemoteOptions = function() {
    return RioRoom.instance.getRemoteOptions();
}

RioSession.prototype.onAuthListener = function() {
    this.log(`onAuthListener`);
}

RioSession.prototype.destroy = function() {
    this.stop();
    HttpService.destroy();
    
    SocketService.instance.off(
        SocketEvent.WS_EVENT_ERROR, this._onSocketError);
    
    SocketService.instance.off(
        SocketEvent.WS_EVENT_JOIN, this._onUserJoined);

    SocketService.instance.off(
        SocketEvent.WS_EVENT_LEAVE, this._onUserLeave);
    
    SocketService.instance.destroy();
    
    JitsiMediaDevices.removeEventListener(
        JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED,
        this.deviceChangeListener);
    //this._init();
}

RioSession.prototype._unRegistCallEvent = function() {
    SocketService.instance.off(
        SocketEvent.WS_EVENT_CALL_INCOMING,
        this._onCallListener);

    SocketService.instance.off(
        SocketEvent.WS_EVENT_RESPONSE, this._onEventCallResponse);

    SocketService.instance.off(
        SocketEvent.WS_EVENT_CALL_STOP, this._onStopCallListener);

    SocketService.instance.off(
        SocketEvent.WS_EVENT_CHANGE_QUALITY, this._onChangeQuality);

    SocketService.instance.off(
            SocketEvent.WS_EVENT_VOICE_CHANGE, this._onVoiceChange);
    
    SocketService.instance.off(
        SocketEvent.WS_EVENT_TOGGLE_EFFECT, this._onToggleEffect);
        
    SocketService.instance.off(
        SocketEvent.WS_EVENT_CALL_RECONNECT, this._onEventCallReconnect);
}

/**
 * Sets the maximum video size the local participant should send to remote
 * participants.
 * @param {number} maxFrameHeight - The user preferred max frame height.
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioSession.prototype.changeVideoQuality = function(type) {
    
    return RioRoom.instance.changeVideoQuality(type);
}

RioSession.prototype.voiceChange = async function(options) {
    const {enabled, value} = options;
    const effectOptions = {
        type: 'audio',
        enabled: true,
        value: Number(value),
    }

    if (value === 0 || value === '' ) {
        effectOptions.enabled = false;
    }
    
    RioRoom.instance.onToggleAudioEffect(effectOptions);
    SocketService.instance.toggleEffect(effectOptions);
}

/**
 * Switch input audio device
 * 
 * @param options.micDeviceId micDeviceId
 * @param options.options array option
 *  
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioSession.prototype.switchInputAudio = async function(options) {
    return RioRoom.instance.switchInputAudio(options);
}

/**
 * Switch ouput audio device
 * 
 * @param options.micDeviceId micDeviceId
 *  
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioSession.prototype.switchOutputAudio = async function(options) {
    return RioRoom.instance.switchOutputAudio(options);
}

/**
 * Switch stream video
 * 
 * @param options.cameraDeviceId device 
 * 
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioSession.prototype.switchCamera = async function(options) {
    return RioRoom.instance.switchCamera(options);
}

/**
* @inheritdoc
*
* Stops sending the media track. And removes it from the HTML. NOTE: Works for local tracks only.
*
* @extends JitsiTrack#dispose
* @returns {Promise}
*/
RioSession.prototype.disposeTrack = async function(options) {
    return RioRoom.instance.disposeTrack(options);
}

/**
 * check output audio 
 * 
 * @param options.micDeviceId device 
 * @param options.voice effected 
 * @param options.timeout number second recording 
 * 
 * @returns {Promise} promise that will be resolved when the operation is
 * successful and rejected otherwise.
 */
RioSession.prototype.checkOutputAudio = async function(options) {
    return RioRoom.instance.checkOutputAudio(options);
}

RioSession.prototype.toggleSubtitle = function(options) {
    
    return RioRoom.instance.toggleSubtitle(options);
}

/**
 * Signals the local participant activate the virtual background video or not.
 *
 * @param {Object} jitsiTrack - Represents the jitsi track that will have backgraund effect applied.
 * @param {Object} options - Represents the virtual background setted options.
 * @returns {Promise}
 */
RioSession.prototype.toggleBackgroundEffect = async function(track, options) {
    const {enabled, backgroundType} = options;
    const effectOptions = {
        enabled: Boolean(enabled),
        blurValue: options?.blurValue,
        backgroundType: (backgroundType == 'image') ? 'image' : 'video',
        backgroundEffectEnabled: (enabled) ? true : false,
        url: options?.url,
        canvasId: options?.canvasId,
        libPath: options?.libPath,
    };
    let videoTrack = track;
    if (track instanceof Array) {
        const found = track.filter((stream) => {
                return (stream.type && stream.type == 'video');
        });
        videoTrack = found.length ? found.shift() : false;
    }

    if (!videoTrack) {
        return Promise.reject(new Error('Stream invalid'));
    }
    return await RioRoom.instance.toggleBackgroundEffect(videoTrack, effectOptions);
}

RioSession.prototype.log = function(...args) {
    var is_debug = true;
    if (is_debug && args) {
        args.unshift('[debug]');

        console.log.apply(console, args);
    }
}

RioSession.prototype.startLocalRecording = function (options) {
    const trackOption = RioRoom.instance.getRecordingOptions();

    return new Promise((resolve, reject) => {
        if (trackOption) {
            const recordOptions = Object.assign({}, options, trackOption);
            
            this.rioRecording = new RioRecording(recordOptions);
            RioRoom.instance.startRecording().then(() => {
                this.rioRecording.start();
                resolve();
            });
        } else {
            return reject('starting record was failed');
        }
    });
}

/**
 * stop local recording the current calling.
 *
 * @param options.upload Boolean auto upload to server
 * @returns {Promise<never>|*}
 */
RioSession.prototype.stopLocalRecording = function (isLocal) {
    if (this.rioRecording) {
        const {rioRecording} = this;
        this.rioRecording = null;

        return rioRecording.stop();
    }
    if (isLocal === true) {
        return true;
    } else {
        return Promise.reject(new Error('Recording not start'));
    }
}

/**
 * get list user for application
 * @param params {object}
 */
RioSession.prototype.getRecordsByUserId = async function (params = {}) {
    const {user_id} = params;

    if (!this.isConnected) {
        this.log('Application not authorized');
        return Promise.reject('Application not authorized');
    }
    
    if (!user_id) {
        return Promise.reject('userId invalid');
    }

    return await HttpService.recordList({user_id})
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(err => {
            logger.log(`Error: RioSession getRecordsByUserId: ${err?.message}`);
            return Promise.reject('Can not get record data');
        });
};

/**
* Event listener for JitsiMediaDevicesEvents.DEVICE_LIST_CHANGED to
* handle change of available media devices.
* @private
* @param {MediaDeviceInfo[]} devices
* @returns {Promise}
*/
RioSession.prototype._onDeviceListChanged = function(devices) {
    RioMeetJS.videoroom.onDeviceListChanged(devices);
}

/* global config */
class UtilStore {
    _key = "__rio__";
    options = {};
    constructor() {
      this.setSalt(this._key);
    }
    // Properties & Methods
    init = (options) => {
        if (options && options.salt) {
          const {salt} = options;
          this.setSalt(salt);
        }
    };
}

UtilStore.prototype.setSalt = function(salt) {
  this.options.salt = salt;
};

UtilStore.prototype.set = function(key, val) {
  if (typeof val == 'object') {
    val = JSON.stringify(val);
  }
  sessionStorage.setItem(this.key(key), this.crypt(val));
};

UtilStore.prototype.getItem = function(key, def=null) {
  try {
    const val = sessionStorage.getItem(this.key(key));
    return val || def;
  } catch (error) {
    return def;
  }
};

UtilStore.prototype.get = function(key, def=null) {
  try {
    const val = sessionStorage.getItem(this.key(key));
    const ret = JSON.parse(this.decrypt(val));
    return ret || def;
  } catch (error) {
    return def;
  }
};

UtilStore.prototype.remove = function(key) {
  sessionStorage.removeItem(this.key(key));
};

UtilStore.prototype.key = function(key) {
    return `${this._key}${key}`;
};

UtilStore.prototype.strCrypt = function(text) {
  if (typeof text == 'object') {
    text = JSON.stringify(text);
  }
  return this.crypt(text);
}

UtilStore.prototype.strDecrypt = function(str, def=null) {
  try {
    const ret = JSON.parse(this.decrypt(str));

    return ret || def;
  } catch (err) {
    return def;
  }
}

UtilStore.prototype.crypt = function(text) {
  const {salt} = this.options;
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};

UtilStore.prototype.decrypt = function(encoded) {
  const {salt} = this.options;
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};

//const utilStore = new UtilStore()
//Object.freeze(utilStore)

export default UtilStore;

/** define voice type */
export const VoiceType = {
    None : {name : 'none', value : ''},
    Pitch_0 : {name : 'mountain troll', value : -6},
    Pitch_1 : {name : 'extremely low pitch', value : -5},
    Pitch_2 : {name : 'much lower pitch', value : -4},
    Pitch_3 : {name : 'even lower pitch', value : -3},
    Pitch_4 : {name : 'lower pitch', value : -2},
    Pitch_5 : {name : 'slightly lower pitch', value : -1},
    Pitch_6 : {name : 'slightly higher pitch', value : 1},
    Pitch_7 : {name : 'higher pitch', value : 2},
    Pitch_8 : {name : 'even higher pitch', value : 3},
    Pitch_9 : {name : 'much higher pitch', value : 4},
    Pitch_10 : {name : 'extremely high pitch', value : 5},
    Pitch_11 : {name : 'chipmunk', value : 6},
}

export const SAMPLE_RATE = 44100;


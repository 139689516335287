/**
 * The events for the web socket.
 */
module.exports = Object.freeze({
    WS_TIMEOUT: 30,
    FORCE_STOP_ID: '999999999',

    //list event type
    WS_EVENT_CONNECTION: 'connection',
    WS_EVENT_REGISTER: 'register',
    WS_EVENT_CALL_INCOMING: 'incoming',
    WS_EVENT_RESPONSE: 'response',
    WS_EVENT_CALL_START: 'start',
    WS_EVENT_CALL_STOP: 'stop',
    WS_EVENT_CHANGE_QUALITY: 'change.quality',
    WS_EVENT_CALL_RECONNECT: 'call.reconnected',
    WS_EVENT_VOICE_CHANGE: 'voice.change',
    WS_EVENT_TOGGLE_EFFECT: 'toggle.effect',
    WS_EVENT_UPLOAD_SCREENSHOT: 'upload.screenshot',
    WS_EVENT_ERROR: 'error',
    WS_EVENT_JOIN: 'joined',
    WS_EVENT_LEAVE: 'leave',

    //action send from websocket server
    WS_REGISTER_REJECTED: 'register.accepted',
    WS_CALL_ACCEPTED: 'accepted',
    WS_CALL_ACCEPTED_OTHER: 'other.accepted',
    WS_CALL_REJECTED: 'rejected',
    WS_CALL_REJECTED_OTHER: 'other.rejected',
    WS_CALL_TIMEOUT: 'timeout',
    WS_CALL_BUSY: 'busy',
    WS_CALL_CANCEL: 'cancel',
    WS_ACT_REGISTER: 'register',
    WS_ACT_LIST: 'list',
    WS_ACT_CALL_INCOMING: 'call.incoming',
    WS_ACT_CALL_START: 'call.start',
    WS_ACT_CALL_STOP: 'call.stop',
    WS_ACT_CALL_FORCE_STOP: 'call.force.stop',
    WS_ACT_CALL_RESPONSE: 'call.response',
    WS_ACT_CALL_RESPONSE_CB: 'call.response.callback',
    WS_ACT_ICE_CANDIDATE: 'iceCandidate',
    WS_ACT_MSG_INCOMING: 'msg.incoming',
    WS_ACT_CHANGE_QUALITY: 'change.quality',
    WS_ACT_VOICE_CHANGE: 'voice.change',
    WS_ACT_ERROR: 'error',
    WS_ACT_CALL_INFO: 'call.info',//get call info
    WS_ACT_CALL_INFO_RESPONSE: 'call.info.response',

    /**
     * list action send to web socket
     */
    WS_SEND_REGISTER: 'register',
    WS_SEND_LIST: 'list',
    WS_SEND_STOP: 'stop',
    WS_SEND_ANSWER: 'answer',
    WS_SEND_NOT_ANSWER: 'not.answer',
    WS_SEND_CALL: 'call',
    WS_SEND_PING: 'ping',
    WS_SEND_ON_ICE_CANDIDATE: 'onIceCandidate',
    WS_SEND_ROOM_START: 'room.start',
    WS_SEND_INCOMING_RESPONSE: 'incomingCallResponse',
    WS_SEND_CHANGE_QUALITY: 'change.quality',
    WS_SEND_VOICE_CHANGE: 'voice.change',
});

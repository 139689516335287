/* global __filename, $, Promise */

import { getLogger } from '@jitsi/logger';

const logger = getLogger(__filename);

/**
 * Creates a VideoRoom object with the given name and properties.
 * Note: this constructor is not a part of the public API (objects should be
 * created using JitsiConnection.createConference).
 * @param options.config properties / settings related to the conference that
 * will be created.
 * @param options.name the name of the conference
 * @constructor
 *
 * FIXME Make all methods which are called from lib-internal classes
 *       to non-public (use _). To name a few:
 *       {@link VideoRoom.onLocalRoleChanged}
 *       {@link VideoRoom.onUserRoleChanged}
 *       {@link VideoRoom.onMemberLeft}
 *       and so on...
 */
 export default function VideoRoom() {
    this.options = {};
    this.sessions = [];
}

/**
 * That function is called when connection is established successfully
 */
VideoRoom.prototype.onCallListener = function () {
	logger.debug('onCallListener');
}

VideoRoom.prototype.onAcceptCallListener = function (callInfo) {
	logger.debug('onAcceptCallListener');
}

VideoRoom.prototype.onRejectCallListener = function (userId) {
	logger.debug('onRejectCallListener');
}

VideoRoom.prototype.onStopCallListener = function (userId) {
	logger.debug('onStopCallListener');
}

VideoRoom.prototype.onUserNotAnswerListener = function () {
	logger.debug('onUserNotAnswerListener');
}

VideoRoom.prototype.onUserBusyListener = function () {
	logger.debug('onUserBusyListener');
}

VideoRoom.prototype.onRemoteStreamListener = function () {
	logger.debug('onRemoteStreamListener');
}

VideoRoom.prototype.onRemoteUpdated = function (options) {
	logger.debug('onRemoteUpdated', options);
}

VideoRoom.prototype.onDeviceChangeListener = function () {
	logger.debug('onDeviceChangeListener');
}

VideoRoom.prototype.onStateChangedListener = function () {
	logger.debug('onStateChangedListener');
}

VideoRoom.prototype.onTrackMutedListener = function (track, isMuted) {
	logger.debug('onTrackMutedListener');
}

VideoRoom.prototype.onConnectionSuccess = function () {
	logger.debug('onConnectSuccess');
}
/**
 * This function is called when the connection fail.
 */
VideoRoom.prototype.onConnectionFailed = function () {
	logger.debug('Connection Failed!');
}

/**
 * This function is called when the connection fail.
 */
VideoRoom.prototype.onDisconnect = function (retry) {
	logger.debug('onDisconnect');
}

/**
 * This function is called when the connection fail.
 */
VideoRoom.prototype.onDeviceListChanged = function (devices) {
	logger.debug('onDeviceListChanged', devices);
}

/**
 * This function is called when the conference disconnect.
 */
VideoRoom.prototype.onLeaveRoom = function (userId) {
	logger.debug('onLeaveRoom', );
}

/**
 * This function is called when the user change your voice.
 */
VideoRoom.prototype.onVoiceChange = function () {
	logger.debug('onVoiceChange', );
}

/**
 * This function is called when the user toggle effect video.
 */
VideoRoom.prototype.onToggleEffect = function () {
	logger.debug('onToggleEffect', );
}

VideoRoom.prototype.onError = function () {
	logger.debug('onError');
}

VideoRoom.prototype.onUserJoined = function (userId) {
	logger.debug('onUserJoined', userId);
}

VideoRoom.prototype.onUserLeave = function (userId) {
	logger.debug('onUserLeave', userId);
}

VideoRoom.prototype.onSubtitle = function (userId, msg) {
	logger.debug('onSubtitle', userId, msg);
}

//function from APP
VideoRoom.prototype.onAppInit = function (users) {
	logger.debug('onAppInit', users);
}

VideoRoom.prototype.onChangeAudioDeviceId = function (options) {
	logger.debug('onChangeAudioDeviceId', options);
}

VideoRoom.prototype.onAudioInputChanged = function (data) {
	logger.debug('onAudioInputChanged', data);
}

VideoRoom.prototype.onSpeakerEnable = function (isEnabled) {
	logger.debug('onSpeakerEnable', isEnabled);
}

VideoRoom.prototype.onDestroy = function () {
	logger.debug('onDestroy');
}

VideoRoom.prototype.onEnterPIP = function (isLocal) {
	logger.debug(`onEnterPIP ${isLocal}`);
}

VideoRoom.prototype.onLeavePIP = function (isLocal) {
	logger.debug(`onLeavePIP ${isLocal}`);
}

VideoRoom.prototype.onRemoteReadyState = function (state) {
	logger.debug(`onRemoteReadyState ${state}`);
}

VideoRoom.prototype.onLocalReadyState = function (state) {
	logger.debug(`onLocalReadyState ${state}`);
}

/* global config */
import Logger from '@jitsi/logger';
import HttpService from '../http/HttpService';
import LogMemoryStorage from './LogMemoryStorage';

import logger from './RioLogger';
import { ENABLE_TRACKING_LOG } from "../../config";

class RioLogTracking {
    options = {
        _enabled: null,
        _started: false,
        interval: null,
        options: {},
        connection: null,
        memoryStorage: null
    };

    constructor() {
        let inteval = 0;
        if (ENABLE_TRACKING_LOG) {
            inteval = Number(ENABLE_TRACKING_LOG);
        } else {
            inteval = 10;
        }

        this.timeInterval = parseInt(inteval, 10) *1000;
    }

    /**
     * The list of {@link SocketService} events.
     *
     * @returns {Object}
     */
    static get instance() {
        if (RioLogTracking._instance === undefined) {
            RioLogTracking._instance = new this();
        }
        return RioLogTracking._instance;
    }
}

/**
 * This function is called when we disconnect.
 */
RioLogTracking.prototype.init = function () {
    const memoryStorage = new LogMemoryStorage();
    const debugLogCollector = new Logger.LogCollector(
        memoryStorage, { 
            storeInterval: 3000,
            stringifyObjects: true
        });

    Logger.addGlobalTransport(debugLogCollector);
    debugLogCollector.start();

    this.options.memoryStorage = memoryStorage;
}

/**
 * start tracking log
 */
RioLogTracking.prototype.initRoom = function (connection, options) {
    if (!ENABLE_TRACKING_LOG) {
        logger.log(`ENABLE_TRACKING_LOG : ${ENABLE_TRACKING_LOG}`);
        this.options._enabled = false;
        return;
    }

    const { _started } = this.options;
    if (_started) {
        return;
    }
    this.options.options = options || {};
    this.options.connection = connection;
    const { initiatorID, currentUserID, fromAgentType, toAgentType } = options;

    this.options._started = true;
    if ((initiatorID == currentUserID)
        || (fromAgentType === 'web'
            && toAgentType !== 'web')
        || (fromAgentType !== 'web'
            && toAgentType === 'web')
        ) {
        this.options._enabled = true;
    } else {
        this.options._enabled = true;
        //return;
    }

    //check loop interval time
    clearInterval(this.options.interval);
    this._sendLog();

    this.options.interval = setInterval(() => {
        this._sendLog();
    }, this.timeInterval);
}

/**
 * tracking log
 */
RioLogTracking.prototype._sendLog = function (isStop) {
    const { _enabled, memoryStorage } = this.options;

    if (!_enabled) {
        return;
    }
    const consolelogs = memoryStorage?.getLogs();
    if(!isStop && consolelogs.length < 5) {
        return;
    }

    memoryStorage?.clear();
    let xmppLogs = [];
    if (isStop) {
        const {connection} = this.options;
        xmppLogs = connection.getLogs();
    }
    
    try {
        const {options} = this.options;
        HttpService.trackingLog({
            callInfo: options,
            consolelogs: consolelogs,
            xmppLog: xmppLogs,
        })
        .then( (res) => {
            //logger.log(res);
            if (isStop) {
                this.options.connection = null;
            }
        });
    } catch (err) {
      logger.log(`Error: ${err?.message}`);
    }
}

/**
 * stop auto screenshot
 */
RioLogTracking.prototype.destroy = function () {
    const { _enabled, _started, interval} = this.options;

    if (_started && _enabled) {
        this._sendLog(true);
    }

    this.options._started = false;
    this.options._enabled = false;

    if(interval) {
        clearInterval(this.options.interval);
    }
}

const rioLogTracking = new RioLogTracking()
Object.freeze(rioLogTracking);

export default rioLogTracking;

/* global __filename, $, Promise */
import Constants from '../../Constants';

import { getLogger } from '@jitsi/logger';

const logger = getLogger(__filename);

export default class DeepArEffect {

    /**
     * JitsiLocalTrack that is going to be mixed into the track that uses this effect.
     */
    mixAudio = {};

    /**
     * MediaStream resulted from mixing.
     */
    _mixedMediaStream = undefined;

    /**
     * Original MediaStream from the JitsiLocalTrack that uses this effect.
     */
    _originalStream = {};

    /**
     * MediaStreamTrack obtained from the original MediaStream.
     */
    _originalTrack = {};

    _deviceId = '';

    /**
     * Creates AudioMixerEffect.
     *
     * @param {Object} options - Object option
     */
    constructor(mixAudio, options) {
        if (mixAudio.getType() !== Constants.MediaType.VIDEO) {
            throw new Error('AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!');
        }

        this.mixAudio = mixAudio;
        this._isMuted = this.mixAudio.isMuted();
        this._deviceId = this.mixAudio.getDeviceId();
    }

    /**
     * set setEffect.
     *
     * @param {JitsiLocalTrack} mixAudio - JitsiLocalTrack which will be mixed with the original track.
     */
    async setEffect(options) {
        const {canvasId, virtualSource} = options;
        //const canvas = document.getElementById(options.canvasId);
        this._mixedMediaStream = virtualSource;

        return this;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    startEffect(stream) {
        this._originalStream = stream;
        this._originalTrack = stream.getTracks()[0];

        const effectedTrack = this._mixedMediaStream 
            ? this._mixedMediaStream.getVideoTracks()
            : [];

        const oldTracks   =  stream.getVideoTracks();
        if(oldTracks.length) {
            stream.removeTrack(oldTracks[0]);
        }

        if(effectedTrack.length) {
            stream.addTrack(effectedTrack[0]);
        }
        //logger.log('startEffect', stream, this._mixedMediaStream, this._originalTrack);

        return stream;
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stopEffect() {
        //const {stream} = this.mixAudio;
        //const addTracks = this._originalStream 
        //    ? this._originalStream.getVideoTracks()
        //    : [];

        //const oldTracks   =  stream.getVideoTracks();
        //if(oldTracks.length) {
        //    stream.removeTrack(oldTracks[0]);
        //}

        //if(addTracks.length) {
        //    stream.addTrack(addTracks[0]);
        //}

        //logger.log('stopEffect', stream, this.mixAudio, oldTracks, addTracks);

        return this.dispose();
    }

    /**
     * @inheritdoc
     *
     * Stops sending the media track. And removes it from the HTML.
     * NOTE: Works for local tracks only.
     *
     * @returns {void}
     */
    dispose() {
      logger.log('dispose', this._mixedMediaStream, this._isMuted);

      if(this._mixedMediaStream) {
          delete this._mixedMediaStream;
      }

    }

    /**
     * Checks if the JitsiLocalTrack supports this effect.
     *
     * @param {JitsiLocalTrack} sourceLocalTrack - Track to which the effect will be applied.
     * @returns {boolean} - Returns true if this effect can run on the specified track, false otherwise.
     */
    isEnabled(sourceLocalTrack) {
        // Both JitsiLocalTracks need to be audio i.e. contain an audio MediaStreamTrack
        return sourceLocalTrack.isVideoTrack() && this.mixAudio.isVideoTrack();
    }
    
    /**
     * Change the muted state of the effect.
     *
     * @param {boolean} muted - Should effect be muted or not.
     * @returns {void}
     */
    setMuted(muted = false) {
        this._originalTrack.enabled = !muted;
    }

    /**
     * Check whether or not this effect is muted.
     *
     * @returns {boolean}
     */
    isMuted() {
        return !this._originalTrack.enabled;
    }

    /**
     * Checks whether the MediaStream is active/not ended.
     * When there is no check for active we don't have information and so
     * will return that stream is active (in case of FF).
     * @returns {boolean} whether MediaStream is active.
     */
    isActive() {
        const {stream} = this.mixAudio;
        if (typeof stream.active !== 'undefined') {
            return stream.active;
        }

        return true;
    }
}

/* global config */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import { VideoType } from '../../service/RTC/VideoType';
import RTC from '../RTC/RTC';
import { appSetting } from "../../config";
//import { loadImage, toDataURL } from '../../functions';
import logger from './RioLogger';
import RioHelper from './RioHelper';
import RoomListener from './RoomListener';
import RioTimer from './RioTimer';
import RioAppInterface from './RioAppInterface';

class RioMedia {
    options = {
        localStreamId: 'localStreamId',
        remoteStreamId: 'remoteStreamId',
        avatarImg: false,
        avatarCanvas: undefined,
        avatarStream: undefined,
        avatarCanvasId: 'avatarCanvasId'
    };
    
    colors = [
        '#6A50D3',
        '#FF9B42',
        '#DF486F',
        '#73348C',
        '#B23683',
        '#F96E57',
        '#4380E2',
        '#2AA076',
        '#00A8B3'
    ];

    localOptions = {
        width: 1280,
        height: 720,
        reloadCnt: 0,
        playing: false,
        isPIP: false,
        isListener: false
    };
    remoteOptions = {
        width: 1280,
        height: 720,
        reloadCnt: 0,
        playing: false,
        isPIP: false,
        isListener: false
    };
    
    constructor() {
        //document.addEventListener('DOMContentLoaded', this.domLoaded);
    }

    /**
    * set avatar url
    * @param {String} url
    *
    * @returns {void}
    */
    domLoaded = () => {
        const canvasId = this.options.avatarCanvasId;
        const imgUrl = this.getAvatar();

        const img = document.createElement('img');
        img.id = `${canvasId}-img`;
        img.style.display = "none";
        img.src = imgUrl;
        img.onload = () => {
        }
        
        //document.body.appendChild(img);
		//this.options.avatarCanvas = canvas;
        //this._initAvatar();
    };

    /**
    * set avatar url
    * @param {String} url
    *
    * @returns {void}
    */
    setAvatar = (url) => {
        this.options.avatar = url;
    };

    /**
    * get avatar url
    *
    * @returns {string}
    */
    getAvatar = () => {
        return this.options.avatar || appSetting.POSTER_URL;
    };

    /**
    * set localStreamId
    * @param {String} id - String ID element html attach show local video
    *
    * @returns {void}
    */
    setLocalStreamId = (id) => {
        if (!id) {
            return;
        }
        const videoEle = document.getElementById(id);

        if (!videoEle) {
            return;
        }
        this.options.localStreamId = id;
        this._addListener(id, videoEle);
    };

    /**
    * get localStreamId config
    *
    * @returns {string}
    */
    getLocalStreamId = () => {
        return this.options.localStreamId;
    };

    /**
    * set remoteStreamId
    * @param {String} id - String ID element html attach show local video
    *
    * @returns {void}
    */
    setRemoteStreamId = (id) => {
        if (!id) {
            return;
        }
        const videoEle = document.getElementById(id);
        if (!videoEle) {
            return;
        }
        this.options.remoteStreamId = id;
        this._addListener(id, videoEle);
    };

    /**
    * get remoteStreamId config
    *
    * @returns {string}
    */
    getRemoteStreamId = () => {
        return this.options.remoteStreamId;
    };

    /**
    * Create video tag all event listeners bound to the remote video track and clears
    * any related timeouts.
    *
    * @param {JitsiRemoteTrack} stream - The local/remote track which is being
    * join the conference.
    */
    attachMediaStream = function (el, stream, options = {}) {
        const videoEle = document.getElementById(el);
        const { muted, reattach } = options;
        logger.debug(`[STEP] 10 RioMedia attachMediaStream ${el}:${stream.getType()}:${stream?.disposed}`, options);

        if (!videoEle) {
            logger.warn(`[STEP] 10 Unable to attach media stream, element ${el} is undefined`);
            return;
        }
        try {
            if (stream?.disposed) {
                logger.warn(`[STEP] 10 The stream is disposed`);
                return;
            }
            videoEle.style['pointer-events'] = "none";
            videoEle.controls = false;
            if(muted) {
                //videoEle.muted = true;
            }
            const isLocal = stream.isLocal();
            if (stream.getType() === 'video') {
                if (isLocal && this.localOptions.playing && !reattach) {
                    return;
                } else if (!isLocal && this.remoteOptions.playing && !reattach) {
                    return;
                }

                RioAppInterface.onEmitEvent('onAttachVideoStream');
                videoEle.pause();
                //videoEle.preload = 'auto';
                //videoEle.poster = this.getAvatar();

                videoEle.onloadedmetadata = (e) => {
                    videoEle.removeAttribute("avatar");
                    setTimeout(() => {
                        this.play(videoEle);
                    }, 0);
                    logger.debug(`[STEP] 10 attachMediaStream onloadedmetadata :${el} ${videoEle.videoWidth} ${videoEle.videoHeight}`);
                };

                stream.attach(videoEle);
                const t1 = setTimeout( () => {
                    videoEle.load();
                }, 100);
                videoEle.onplaying = (e) => {
                    clearTimeout(t1);
                };
            } else if (!isLocal) {
                $(videoEle).parent().children("audio[id^='audio-"+`${el}`+"-']").each(function () {
                    this.pause();
                    $(this).remove();
                });

                let remoteAudio = document.createElement("audio");
                remoteAudio.autoplay=1;
                remoteAudio.id= isLocal ? `audio-${el}-${stream.getId()}` : `audio-${el}-${stream.getId()}`;
                remoteAudio.preload = 'auto';

                $(videoEle).parent().append(remoteAudio);
                
                stream.attach(remoteAudio);
                const t2 = setTimeout( () => {
                    remoteAudio.load();
                }, 100);
                remoteAudio.onplaying = (e) => {
                    clearTimeout(t2);
                };
            }
        } catch (err) {
            logger.warn(`Error: [STEP] 10 RioMedia attachMediaStream: ${err?.message}`);
        }
    }

    /**
     * play video stream
     *
     */
    play = function (videoEle) {
        try {
            if(!videoEle) {
                return;
            }
            const promise = videoEle.play();
            if (promise instanceof Promise) {
                promise.then( () => {
                    videoEle.classList.remove("d-hidden");
                    // Automatic playback started!
                    logger.debug(`[STEP] 10 RioMedia play ${videoEle.videoWidth} ${videoEle.videoHeight}`);
                }).catch( (err) => {
                    // Automatic playback failed.
                    logger.warn(`Error: [STEP] 10 RioMedia promise: ${err?.message}`);
                });
            }
        } catch (err) {
            logger.warn(`Error: [STEP] 10 RioMedia Promiseplay: ${err?.message}`);
        }
    }

    /**
     * Removes this JitsiTrack from the passed HTML container.
     *
     * @param container the HTML container to detach from this JitsiTrack. If
     * <tt>null</tt> or <tt>undefined</tt>, all containers are removed. A
     * container can be a 'video', 'audio' or 'object' HTML element instance to
     * which this JitsiTrack is currently attached.
     */
    detach = async function (jitsiTrack, remoteTrack) {
        try {
            const streamId = (remoteTrack === true) 
                ? this.getRemoteStreamId()
                : this.getLocalStreamId();

            const videoEle = document.getElementById(streamId);
            logger.debug(`[STEP] 15 RioMedia detach`);
            if (!videoEle) {
                return;
            }
            if (jitsiTrack.getType() === 'video') {
                videoEle.pause();
            }
            const track = jitsiTrack.getTrack();
            if (track) {
                //track.stop();
                jitsiTrack.detach(videoEle);
            }
        } catch (err) {
            logger.warn(`Error: [STEP] 15 RioMedia detach: ${err?.message}`);
        }
    }

    /**
    * handle enter Picture-in-Picture events
    * requests that the video enter Picture-in-Picture mode
    */
    enterPIP = async function(localTrack) {
        logger.debug('[STEP] 13 Entered Picture-in-Picture');
        try {
            const streamId = (localTrack === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

            const videoEle = document.getElementById(streamId);
            if (!videoEle) {
                return;
            }

            logger.debug(`[STEP] 13 
                streamId ${streamId}
                videoEle.readyState ${videoEle.readyState}
                pictureInPictureEnabled ${document?.pictureInPictureEnabled }
                disablePictureInPicture ${videoEle.disablePictureInPicture}`);
            if (
                videoEle.readyState === 0 || 
                !document?.pictureInPictureEnabled || 
                videoEle.disablePictureInPicture ) {
                return;
            }
            if (document?.pictureInPictureElement) {
                await document?.exitPictureInPicture();
            }
            // Start PiP
            await videoEle.requestPictureInPicture()
            .then((pipWindow) => {
                //pipWindow.onresize = printPipWindowDimensions;
                // listen for window resize
                // pipWindow.addEventListener('resize', this.onPipWindowResize);
                logger.debug(`[STEP] 13 PIP Window size is ${pipWindow.width} x ${pipWindow.height}`);
                setTimeout(() => {
                    videoEle.load();
                    //videoEle.play();
                    this.play(videoEle);
                }, 100);
            })
            .catch(err => {
                // Error handling
                logger.warn(`Error: [STEP] 13 RioMedia requestPictureInPicture: ${err?.message}`);
            });
        } catch (err) {
            logger.warn(`Error: [STEP] 13 RioMedia enterPIP: ${err?.message}`);
        }
    }

    /**
    * handle exit Picture-in-Picture events
    * requests that the video exit Picture-in-Picture mode
    */
    leavePIP = async function(localTrack) {
        logger.debug('[STEP] 14 Leave Picture-in-Picture');
        try {
            const streamId = (localTrack === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

            const videoEle = document.getElementById(streamId);
            if (!videoEle) {
                return;
            }
            if (videoEle.disablePictureInPicture) {
                return;
            }
            // Leave PiP
            if (document?.pictureInPictureElement) {
                await document?.exitPictureInPicture();
            }
            setTimeout(() => {
                videoEle.load();
                this.play(videoEle);
            }, 100);
        } catch (err) {
            logger.warn(`Error: [STEP] 14 RioMedia leavePIP: ${err?.message}`);
        }
    }

    onPipWindowResize = function(evt) {
        // print window size to console
        const pipWindow = evt.target;
        logger.debug(
            `[STEP] 13 onPipWindowResize The floating window dimensions are: ${pipWindow.width}x${pipWindow.height}px`
        );
    }

    /**
    * Creates {@code JitsiLocalTrack} instances from the passed in meta information
    * about MedieaTracks.
    *
    * @param {Object[]} mediaStream - An array of meta information with
    * MediaTrack instances. Each can look like:
    * {{
    *     stream: MediaStream instance that holds a track with audio or video,
    *     track: MediaTrack within the MediaStream,
    *     videoType: "camera" or "desktop" or falsy,
    *     sourceId: ID of the desktopsharing source,
    *     sourceType: The desktopsharing source type,
    *     effects: Array of effect types
    * }}
    */
    createLocalTrack = function(mediaStream, options) {
        //logger.log(`createLocalTrack:`, mediaStream, options);
        const {deviceId, mediaType, videoType} = options;
        const tracks = RTC.createLocalTracks([
            {
                deviceId: deviceId,
                mediaType: mediaType,
                stream: mediaStream,
                track: mediaStream.getVideoTracks()[0],
                videoType: videoType || VideoType.CAMERA
            }
        ]);

        return tracks[0];
    }

    /**
    * create image overlay video
    *
    */
    showAvatar = function(options) {
        const {isLocal} = options;
        const streamId = (isLocal === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

        const videoEle = document.getElementById(streamId);
        if ( !videoEle ) {
            return;
        }

        try {
            logger.debug(`[STEP] 10 showAvatar streamId ${streamId}`);
            const avatarImage = document.getElementById(`${streamId}-img`);
            if (avatarImage) {
                avatarImage.classList.remove("d-hidden");
            }
            videoEle.classList.add("d-hidden");
        } catch (err) {
            logger.warn(`Error: [STEP] 10 showAvatar: ${err?.message}`);
        }
    }

    /**
    * create image overlay video
    *
    */
    hideAvatar = function(isLocal) {
        const streamId = (isLocal === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

        const videoEle = document.getElementById(streamId);
        if ( !videoEle ) {
            return;
        }

        try {
            logger.debug(`[STEP] 10 hideAvatar streamId ${streamId}`);
            const avatarImage = document.getElementById(`${streamId}-img`);
            if (avatarImage) {
                avatarImage.classList.add("d-hidden");
            }
            videoEle.classList.remove("d-hidden");
        } catch (err) {
            logger.warn(`Error: [STEP] 10 showAvatar: ${err?.message}`);
        }
    }

    /**
    * create image overlay video
    *
    */
    showAvatarStream = function(options) {
        const {isLocal} = options;
        const streamId = (isLocal === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

        const videoEle = document.getElementById(streamId);
        if ( !videoEle ) {
            return;
        }
        try {
            //const canvas = this.options.avatarCanvas;
            const params = {streamId: streamId};
            const opts = {...options, ...params};
            const avatarStream = this._initAvatar(opts);
            const avatar = videoEle.getAttribute('avatar');
            if (!avatarStream || avatar) {
                return;
            }
            //const avatarStream = canvas.captureStream(25);
            logger.debug(`[STEP] 10 showAvatar streamId ${streamId}`, opts);
            logger.debug(`[STEP] 10 showAvatar:`, avatarStream);

            videoEle.pause();
            videoEle.srcObject = avatarStream;
            //videoEle.autoplay = true;
            videoEle.setAttribute("avatar", "true");
            const poster = videoEle.getAttribute('poster');
            
            if (!poster) {
                //videoEle.poster = this.getAvatar();
            }
            videoEle.onloadedmetadata = (e) => {
                //videoEle.play();
                this.play(videoEle);
                logger.debug(`[STEP] 10 showAvatar onloadedmetadata :${streamId} ${videoEle.videoWidth} ${videoEle.videoHeight}`);
                videoEle.setAttribute("videoWidth", videoEle.videoWidth);
                videoEle.setAttribute("videoHeight", videoEle.videoHeight);
            };
            videoEle.load();
            videoEle.onplaying = (e) => {
                logger.debug(`[STEP] 10 showAvatar onplaying`);
            };
        } catch (err) {
            logger.warn(`Error: [STEP] 10 showAvatar: ${err?.message}`);
        }
    }

    /**
    * create image overlay video
    *
    */
    _initAvatar = function(options) {
        const canvasId = this.options.avatarCanvasId;
        const tmp = document.getElementById(`${canvasId}`);
        if(tmp) {
            tmp.remove();
        }

        const canvas = document.createElement('canvas');
        canvas.id = canvasId;
        canvas.style.display = "none";

        let width = 500, height = 500;

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext ("2d", { alpha: false });
        if (!ctx) {
            return null;
        }
        ctx.textBaseline = "top";

        //const random = Math.floor(Math.random() * this.colors.length);
        //const color = this.colors[random];
        //ctx.clearRect(0, 0, width, height);
        //ctx.fillStyle = `${color}`;
        //ctx.fillRect(0, 0, width, height);

        const imgUrl = this.getAvatar();
        //const imgUrl = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==";
        //const img = loadImage(imgUrl);
        const img = new Image();
        img.onload = () => {
            // get the scale
            //var scale = Math.max(canvas.width / img.width, canvas.height / img.height);
            const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
            const x = ( canvas.width - img.width*scale ) / 2;
            const y = ( canvas.height - img.height*scale ) / 2;
            ctx.clearRect(0,0,canvas.width, canvas.height);
            ctx.drawImage(img, 0,0, img.width, img.height,
                        x, y,img.width*scale, img.height*scale);

            //logger.log(`showAvatar Image:`, x, y, scale, width, height, img.width, img.height);
        }
        img.src = imgUrl;
        
        this.options.avatarCanvas = canvas;
        //document.body.appendChild(canvas);
        return canvas.captureStream(25);
    }

    /**
    * draw text over canvas
    *
    */
    _drawText = function(canvas, options) {
        const {text} = options;
        let width, height;
        width = parseInt(canvas?.width, 10) || 200;
        height = parseInt(canvas?.height, 10) || 200;

        if (!text) {
            return;
        }

        if (parseInt(width, 10) < 200) {
            ctx.font = "13pt Arial";
        } else {
            ctx.font = "26pt Arial";
        }

        ctx.fillStyle = "red";
        ctx.fillText(`${text}`.toUpperCase(), (width)/2, (height)/2);
    }

    /**
    * display capture image base64 to poster
    *
    * @param {String} base64
    *
    */
    setPoster = function(base64, localTrack) {
        const streamId = (localTrack === true) 
                ? this.getLocalStreamId()
                : this.getRemoteStreamId();

        const videoEle = document.getElementById(streamId);
        if (!videoEle) {
            return;
        }
        videoEle.poster = `${base64}`;
    }

    /**
    * display capture image base64 to poster
    *
    * @param {String} base64
    *
    */
    getMediaOptions = function() {
        //logger.log('getMediaOptions', this.localOptions, this.remoteOptions);
        return {localOptions: {...this.localOptions}, remoteOptions: {...this.remoteOptions}}
    }

    /**
    * incre cnt reload
    *
    * @param {String} id
    *
    */
    incReloadCnt = function(isRemote) {
        if (isRemote === true) {
            this.remoteOptions.reloadCnt += 1;
        } else {
            this.localOptions.reloadCnt += 1;
        }
    }

    /**
    * clear data after stop call
    *
    */
    startCall = function() {
        logger.log(`RioMedia startCall`);

        this.options.state = true;
    }

    /**
    * clear data after stop call
    *
    */
    stopCall = function() {
        logger.log(`RioMedia stopCall`);

        this.options.state = false;

        this.localOptions.playing = false;
        this.localOptions.isListener = false;
        this.localOptions.reloadCnt = 0;

        this.remoteOptions.playing = false;
        this.remoteOptions.isListener = false;
        this.remoteOptions.reloadCnt = 0;
    }

    /**
    * set remoteStreamId
    * @param {String} id - String ID element html attach show local video
    *
    * @returns {void}
    */
    _addListener = (id, videoEle) => {
        let isListener = false;
        if (this.options.remoteStreamId == id) {
            isListener = this.remoteOptions?.isListener;
        } else {
            isListener = this.localOptions?.isListener;
        }
        
        if (isListener) {
            return;
        }

        if (this.options.remoteStreamId == id) {
            this.remoteOptions.isListener = true;

            videoEle.addEventListener('resize', (e) => {
                logger.debug(`[STEP] 11 remote resize, readyState ${videoEle.readyState}`);
                this.remoteOptions.width = e.target.videoWidth;
                this.remoteOptions.height = e.target.videoHeight;
            });
            videoEle.addEventListener('pause', (e) => {
                logger.debug(`[STEP] 11 remote pause, readyState ${videoEle.readyState}`);
                this.remoteOptions.playing = false;
                RioMeetJS.videoroom.onRemoteReadyState(videoEle.readyState);
            });
            videoEle.addEventListener('playing', (e) => {
                this.hideAvatar(false);
                this.remoteOptions.playing = true;
                RioTimer.debug('remote video playing');
                logger.debug(`[STEP] 11 remote playing, readyState ${videoEle.readyState}`);
                RioMeetJS.videoroom.onRemoteReadyState(videoEle.readyState);
                RioAppInterface.onEmitEvent('onRemoteVideoPlaying');
            });
            videoEle.addEventListener('suspend', (e) => {
                const {state} = this.options;
                logger.debug(`[STEP] 11 remote suspend, state ${state}, readyState ${videoEle.readyState}`);
                this.remoteOptions.playing = false;
                //this.remoteOptions.isListener = false;
                RioMeetJS.videoroom.onRemoteReadyState(videoEle.readyState);
                if (state) {
                    this.showAvatar({
                        isLocal: false
                    });
                }
            });
            videoEle.addEventListener('enterpictureinpicture', (e) => {
                logger.debug(`[STEP] 11 remtote in PIP, readyState ${videoEle.readyState}`);
                this.remoteOptions.isPIP = true;
                RioMeetJS.videoroom.onEnterPIP(false);
            });
            videoEle.addEventListener('leavepictureinpicture', (e) => {
                logger.debug(`[STEP] 11 remtote out PIP, readyState ${videoEle.readyState}` );

                this.remoteOptions.isPIP = false;
                RioMeetJS.videoroom.onLeavePIP(false);

                if(RioHelper.isIosBrowser()) {
                    RoomListener.sendReloadCamera();
                }
            });
        } else {
            this.localOptions.isListener = true;

            videoEle.addEventListener('resize', (e) => {
                logger.debug(`[STEP] 10 local resize, readyState ${videoEle.readyState}`);
                this.localOptions.width = e.target.videoWidth;
                this.localOptions.height = e.target.videoHeight;
            });
            videoEle.addEventListener('playing', (e) => {
                this.hideAvatar(true);
                this.localOptions.playing = true;
                RioTimer.debug('local video playing');
                logger.debug(`[STEP] 10 local playing, readyState ${videoEle.readyState}`);
                RioMeetJS.videoroom.onLocalReadyState(videoEle.readyState);
                RioAppInterface.onEmitEvent('onLocalVideoPlaying');
            });
            videoEle.addEventListener('pause', (e) => {
                logger.debug(`[STEP] 10  local pause, readyState ${videoEle.readyState}`);
                this.localOptions.playing = false;
                RioMeetJS.videoroom.onLocalReadyState(videoEle.readyState);
            });
            videoEle.addEventListener('suspend', (e) => {
                const {state} = this.options;
                logger.debug(`[STEP] 10 local suspend, state ${state}, readyState ${videoEle.readyState}`);
                this.localOptions.playing = false;
                //this.localOptions.isListener = false;
                RioMeetJS.videoroom.onLocalReadyState(videoEle.readyState);
                //if (state && !avatar) {
                //    this.showAvatar({
                //        isLocal: true
                //    });
                //}
            });
            videoEle.addEventListener('enterpictureinpicture', (e) => {
                logger.debug(`[STEP] 10 local out PIP, readyState ${videoEle.readyState}`);
                this.localOptions.isPIP = true;
                
                RioMeetJS.videoroom.onEnterPIP(true);
            });
            videoEle.addEventListener('leavepictureinpicture', (e) => {
                logger.debug(`[STEP] 10 local out PIP, readyState ${videoEle.readyState}` );
                this.localOptions.isPIP = false;
                RioMeetJS.videoroom.onLeavePIP(true);

                if(RioHelper.isIosBrowser()) {
                    RoomListener.sendReloadCamera();
                }
            });
        }
    };
}

const rioMedia = new RioMedia();
Object.freeze(rioMedia);

export default rioMedia;

/* global __filename, $, Promise */
import { getLogger } from '@jitsi/logger';

import Jungle from "./jungle";
import { createAudioContext } from '../../../modules/webaudio/WebAudioUtils';

const logger = getLogger(__filename);

export default class EffectStream {
    /**
     * MediaStream resulted from mixing.
     */
    _mixedMediaStream = undefined;

    _value = undefined;

    stream = undefined;

    _output = undefined

    /**
     * Creates AudioMixerEffect.
     *
     * @param {Object} options - Object option
     */
    constructor(options) {
        const {value, output} = options;
        this._value = Number(value);
        this._output = output;
        
        this.shifter = undefined;
        this._currentEffect = null;
        this.audioInput = null;
        this.outputMix = null;
        this.dryGain = null;
        this.wetGain = null;
        this.lpInputFilter = null;
        this.analyser1 = null;
        this.analyser2 = null;

        this.useFeedbackReduction = true;
        this._audioContext = createAudioContext();
    }

    async setEffect(options) {
        //logger.log('setEffect', options)
        return this;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    startEffect(stream) {
        // Create an AudioNode from the stream.
        const input = this._audioContext.createMediaStreamSource(stream);

        this.audioInput = this.convertToMono( input );
        this.analyser1 = this._audioContext.createAnalyser();
        this.analyser1.fftSize = 1024;
        this.analyser2 = this._audioContext.createAnalyser();
        this.analyser2.fftSize = 1024;

        if (this.useFeedbackReduction) {
            this.audioInput.connect( this.createLPInputFilter() );
            this.audioInput = this.lpInputFilter;
        }

        // create mix gain nodes
        this.outputMix = this._audioContext.createGain();
        this.dryGain = this._audioContext.createGain();
        this.wetGain = this._audioContext.createGain();
        this.shifter = this._audioContext.createGain();
        this.audioInput.connect(this.dryGain);
        this.audioInput.connect(this.analyser1);
        this.audioInput.connect(this.shifter);
        this.dryGain.connect(this.outputMix);
        this.wetGain.connect(this.outputMix);
        this.outputMix.connect( this._audioContext.destination);
        this.outputMix.connect(this.analyser2);

        this.crossfade(1);

        if (this._mixedMediaStream) {
            this._mixedMediaStream.disconnect();
        }

        if (this.shifter) {
            this.shifter.disconnect();
        }

        // Pitch shifting
        this._mixedMediaStream = this.createPitchShifter(this._value);
        this.audioInput.connect( this._mixedMediaStream );
        
        if (this._output) {
            this._mixedMSD = this._audioContext.createMediaStreamDestination();
            this._currentEffect.output.connect(this._mixedMSD);
            this.stream = this._mixedMSD.stream;
            return this.stream;
        }

        this.stream = stream;
        return stream;
    }

    /**
     * Starts the effect process.
     *
     * @param {Number} val The number pitch shift.
     * @protected
     */
    createPitchShifter(val) {
        this._currentEffect = new Jungle( this._audioContext );
        const value = Number(val/6).toPrecision(1);

        this._currentEffect.setPitchOffset(value);
        this._currentEffect.output.connect( this.wetGain );
        return this._currentEffect.input;
    };

    convertToMono( input ) {
        const splitter = this._audioContext.createChannelSplitter(2);
        const merger = this._audioContext.createChannelMerger(2);

        input.connect( splitter );
        splitter.connect( merger, 0, 0 );
        splitter.connect( merger, 0, 1 );
        return merger;
    };

    // this is ONLY because we have massive feedback without filtering out
    // the top end in live speaker scenarios.
    createLPInputFilter() {
        this.lpInputFilter = this._audioContext.createBiquadFilter();
        this.lpInputFilter.frequency.value = 2048;
        return this.lpInputFilter;
    };

    crossfade(value) {
        // equal-power crossfade
        var gain1 = Math.cos(value * 0.5*Math.PI);
        var gain2 = Math.cos((1.0-value) * 0.5*Math.PI);

        this.dryGain.gain.value = gain1;
        this.wetGain.gain.value = gain2;
    }

    /**
     * @inheritdoc
     *
     * Stops sending the media track. And removes it from the HTML.
     * NOTE: Works for local tracks only.
     *
     * @returns {void}
     */
    dispose() {
        if(this._currentEffect) {
            delete this._currentEffect;
        }
        
        if(this.shifter) {
            this.shifter.disconnect();
            delete this.shifter;
        }
        if(this._mixedMediaStream) {
            this._mixedMediaStream.disconnect();
            delete this._mixedMediaStream;
        }

        if(this.outputMix) {
            this.outputMix.disconnect();
            delete this.outputMix;
        }

        if(this.dryGain) {
            this.dryGain.disconnect();
            delete this.dryGain;
        }

        if(this.wetGain) {
            this.wetGain.disconnect();
            delete this.wetGain;
        }

        if(this.audioInput) {
            this.audioInput.disconnect();
            delete this.audioInput;
        }

        if(this._mixedMSD) {
            this._mixedMSD.disconnect();
            delete this._mixedMSD;
        }
    }
}

/*global RioTimer */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import { TIMEZONE } from "../../config";
import logger from './RioLogger';
import * as moment from 'moment-timezone';

//const SECOND = 1000;
//const MINUTE = 1000 * 60;
//const HOUR = 1000 * 60 * 60;
//const DAY = 1000 * 60 * 60 * 24;
//const MONTH = 1000 * 60 * 60 * 24 * 30;
//const YEAR = 1000 * 60 * 60 * 24 * 30 * 12;

/**
 * Stores {@link RioTimer} instance.
 * @type {RioTimer}
 */
class RioTimer {
  _options = {
    _key: '__riotimer__',
    groups: [],
    data: {}
  };
  _timeFormat = 'YYYY-MM-DD HH:mm:ss';

  constructor() {
  }

  /**
  * create instance RioTimer
  *
  * @returns {void}
  */
  instance = () => {
    if (RioTimer._instance === undefined) {
        RioTimer._instance = this;
        moment.tz.setDefault(TIMEZONE);
    }
    return RioTimer._instance;
  }

  /**
  * start timer for call
  *
  * @returns {void}
  */
  start = (type, force) => {
    const time = this.time();
    const groups = this._options.groups;

    if ( (type && !groups.includes(type)) 
        || (type && force === true) ) {
      
      if ( !groups.includes(type) ) {
        groups.push(type);
      }
      
      this._options.groups = groups;
      this._options.data[`_${type}`] = time;

      logger.debug(`[Timer] ${type} ${this.format(time)}`);
    }
  }

  debug = (str) => {
    const now = this.time();
    //const diff = this._moment(now).diff(this._moment(_start), 'seconds');
    //moment.duration(now.diff(then)).humanize()
    let strLog = `[Timer] ${str}
      Now time: ${now} ${this.format(now)}`;
     const groups = this._options.groups;
     let thenTime = 0;

     groups.forEach(type => {
        thenTime = this._options.data[`_${type}`];
        const diff = this._moment(now).diff(this._moment(thenTime), 'seconds');

        strLog += `
          [${type}] before: ${this.format(thenTime)} > diff time: ${diff} (seconds)`;
    });
    logger.debug(strLog);
  }

  time = () => {
    return moment().valueOf(); //.format('x');valueOf();
  }
  
  format = (ms) => {
    return moment(ms).format(this._timeFormat);
  }

  now = (format='YYYY-MM-DD HH:mm:ss') => {
    return moment().format(format);
  }

  clear = () => {
    this._options.data = {};
    this._options.groups = [];
  }

  _moment = (ms) => {
    return moment(ms);
  }

  date = (time, format='YYYY-MM-DD') => {
    return moment(time).format(format);
  }

}

// create instance
const rioTimer = new RioTimer();
// lock instance
Object.freeze(rioTimer);

export default rioTimer;

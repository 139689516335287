/* global config */
import { getLogger } from '@jitsi/logger';
import * as JitsiConferenceEvents from './JitsiConferenceEvents';
import RioAppInterface from './modules/extends/RioAppInterface';

const logger = getLogger(__filename);

/**
 * The type of json-message which indicates that json carries a
 * transcription result.
 */
 const JSON_TYPE_TRANSCRIPTION_RESULT = 'transcription-result';

 /**
  * The type of json-message which indicates that json carries a
  * translation result.
  */
 const JSON_TYPE_TRANSLATION_RESULT = 'translation-result';
 
 /**
  * The local participant property which is used to set whether the local
  * participant wants to have a transcriber in the room.
  */
 const P_NAME_REQUESTING_TRANSCRIPTION = 'requestingTranscription';
 
 /**
  * The local participant property which is used to store the language
  * preference for translation for a participant.
  */
 const P_NAME_TRANSLATION_LANGUAGE = 'translation_language';
 
 /**
 * Time after which the rendered subtitles will be removed.
 */
 const REMOVE_AFTER_MS = 3000;

export default class RioSubtitle {
    constructor(room, {...options}) {
        const { userId, ...otherOptions } = options;

        this.userId = userId;
        this.room = room;
        this.enable = false;
        this.lang = '';
        this._transcriptMessages = new Map();

        this._endpointMessageReceived
            = this._endpointMessageReceived.bind(this);
    }
}

/**
 * Start local recording the current calling.
 *
 * @param options
 * @returns {Promise<never>|*}
 */
 RioSubtitle.prototype._toggle = function (options) {
    const { lang, enable, ...otherOptions } = options;

    this.enable = (enable === true || enable == '1') ? true : false;
    if (lang !== this.lang) {
        this.lang = lang;
    }

    if (!this.room) {
        return;
    }
    if (this.enable) {
        this.room.on(
            JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this._endpointMessageReceived);

        if (this.lang && this.lang !== '') {
            this.room.setLocalParticipantProperty(
                P_NAME_TRANSLATION_LANGUAGE,
                this.lang);
        }
    } else {
        this.room.off(
            JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this._endpointMessageReceived);
    }
    if (this.room) {
        this.room.setLocalParticipantProperty(
            P_NAME_REQUESTING_TRANSCRIPTION,
            this.enable);
    }
};

/**
 * destroy class
 * 
 * @private
 * @returns {void}
 */
RioSubtitle.prototype.destroy = function() {
    this.room.off(
        JitsiConferenceEvents.ENDPOINT_MESSAGE_RECEIVED, this._endpointMessageReceived);
    this.room.setLocalParticipantProperty(
        P_NAME_REQUESTING_TRANSCRIPTION, false);
};

/**
 * Toggle the local property 'requestingTranscription'. This will cause Jicofo
 * and Jigasi to decide whether the transcriber needs to be in the room.
 *
 * @private
 * @returns {void}
 */
 RioSubtitle.prototype.toggle = async function (options) {
    this._toggle(options);
    //wait 1000ms
    await new Promise(resolve => setTimeout(resolve, 1000));

    return Promise.resolve();
};

/**
 * Notifies the feature transcription that the action
 * {@code ENDPOINT_MESSAGE_RECEIVED} is being dispatched within a specific redux
 * store.
 *
 * @private
 * @returns {Object} The value returned by {@code next(action)}.
 */
RioSubtitle.prototype._endpointMessageReceived = function (...args) {
    const [ participant, json ] = args;
    
    logger.info(`endpointMessageReceived`, json);
    if (!this.enable || !(json
            && (json.type === JSON_TYPE_TRANSCRIPTION_RESULT
                || json.type === JSON_TYPE_TRANSLATION_RESULT))) {
        return;
    }

    const translationLanguage
        = this.room.getLocalParticipantProperty(P_NAME_TRANSLATION_LANGUAGE);

    try {
        const transcriptMessageID = json.message_id;
        const participantName = json.participant.name;

        if (json.type === JSON_TYPE_TRANSLATION_RESULT
                && json.language === translationLanguage) {
            // Displays final results in the target language if translation is
            // enabled.
            const newTranscriptMessage = {
                clearTimeOut: undefined,
                final: json.text,
                participantName
            };

            this._setClearerOnTranscriptMessage(transcriptMessageID, newTranscriptMessage);
            this._updateTranscriptMessage(transcriptMessageID, newTranscriptMessage);

            RioMeetJS.videoroom.onSubtitle(this.userId, newTranscriptMessage);
            RioAppInterface.onSubtitle(this.userId, newTranscriptMessage); // eslint-disable-line no-undef
        } else if (json.type === JSON_TYPE_TRANSCRIPTION_RESULT
                && !translationLanguage) {
            // Displays interim and final results without any translation if
            // translations are disabled.

            const { text } = json.transcript[0];

            // We update the previous transcript message with the same
            // message ID or adds a new transcript message if it does not
            // exist in the map.
            const newTranscriptMessage = {
                ...this._transcriptMessages.get(transcriptMessageID)
                    || { clearTimeOut: undefined, participantName }
            };
            this._setClearerOnTranscriptMessage(transcriptMessageID, newTranscriptMessage);

            // If this is final result, update the state as a final result
            // and start a count down to remove the subtitle from the state
            if (!json.is_interim) {
                newTranscriptMessage.final = text;

            } else if (json.stability > 0.85) {
                // If the message has a high stability, we can update the
                // stable field of the state and remove the previously
                // unstable results
                newTranscriptMessage.stable = text;
                newTranscriptMessage.unstable = undefined;

            } else {
                // Otherwise, this result has an unstable result, which we
                // add to the state. The unstable result will be appended
                // after the stable part.
                newTranscriptMessage.unstable = text;
            }

            this._updateTranscriptMessage(transcriptMessageID, newTranscriptMessage);

            RioMeetJS.videoroom.onSubtitle(this.userId, newTranscriptMessage);
            RioAppInterface.onSubtitle(this.userId, newTranscriptMessage); // eslint-disable-line no-undef
        }

    } catch (err) {
        logger.warn(`Error: RioSubtitle Error occurred while updating transcriptions: ${err?.message}`);
    }

    return true;
}

/**
 * Set a timeout on a TranscriptMessage object so it clears itself when it's not
 * updated.
 *
 * @param {Function} dispatch - Dispatch remove action to store.
 * @param {string} transcriptMessageID - The id of the message to remove.
 * @param {Object} transcriptMessage - The message to remove.
 * @returns {void}
 */
RioSubtitle.prototype._setClearerOnTranscriptMessage = function (transcriptMessageID, transcriptMessage) {
        
    if (transcriptMessage.clearTimeOut) {
        clearTimeout(transcriptMessage.clearTimeOut);
    }

    transcriptMessage.clearTimeOut
        = setTimeout( () => {
            this._removeTranscriptMessage(transcriptMessageID);
        }, REMOVE_AFTER_MS);
}


/**
 * Reduces a specific Redux action REMOVE_TRANSCRIPT_MESSAGE of the feature
 * transcription.
 *
 * @returns {Object} The new state of the feature transcription after the
 * reduction of the specified action.
 */
 RioSubtitle.prototype._removeTranscriptMessage = function (transcriptMessageID) {
    // Deletes the key from Map once a final message arrives.
    this._transcriptMessages.delete(transcriptMessageID);
}

/**
 * Reduces a specific Redux action UPDATE_TRANSCRIPT_MESSAGE of the feature
 * transcription.
 *
 * @param {Action} action -The Redux action UPDATE_TRANSCRIPT_MESSAGE to reduce.
 * @returns {Object} The new state of the feature transcription after the
 * reduction of the specified action.
 */
 RioSubtitle.prototype._updateTranscriptMessage = function (transcriptMessageID, newTranscriptMessage) {
    // Updates the new message for the given key in the Map.
    this._transcriptMessages.set(transcriptMessageID, newTranscriptMessage);
}

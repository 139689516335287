/*global RioLogger */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
import { appConfig } from "../../config";
import Logger from '@jitsi/logger';
import { getLogger } from '@jitsi/logger';

import {
    STR_USERAGENT_MOBILE,
    STR_USERAGENT_MOBILE2
} from './constants';

import RioLogTracking from './RioLogTracking';

const logger = getLogger();
/**
 * Stores {@link RioLogger} instance.
 * @type {RioLogger}
 */
class RioLogger {
  options = {
    _key: '__riologger__',
  };

  constructor() {
    this.setLogLevel();
    RioLogTracking.init();
  }

  /**
  * create instance RioLogger
  *
  * @returns {void}
  */
  instance = () => {
      if (RioLogger._instance === undefined) {
          RioLogger._instance = this;
      }
      return RioLogger._instance;
  }

  setLogLevel(level) {
      if (!level) {
        const { LOG_LEVEL } = appConfig;
        level = LOG_LEVEL;
      }
      
      if (level !== Logger.levels.TRACE 
        && level !== Logger.levels.DEBUG 
        && level !== Logger.levels.INFO 
        && level !== Logger.levels.LOG 
        && level !== Logger.levels.WARN 
        && level !== Logger.levels.ERROR ) {
          level = Logger.levels.LOG;
      }

      logger.setLevel(level);
      Logger.setLogLevel(level);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  trace = (...args) => {
    if (!args) {
      return;
    }

    const callerInfo = this._getCallerInfo();
    let logPrefixes = [];
    logPrefixes.push(`[trace]`);
    const logArgs = logPrefixes.concat(...args);

    const _logArgs = this._getLogStr(callerInfo, logArgs);
    logger.trace(_logArgs);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  debug = (...args) => {
    if (!args) {
      return;
    }
    
    const callerInfo = this._getCallerInfo();

    let logPrefixes = [];
    logPrefixes.push(`[debug]`);
    const logArgs = logPrefixes.concat(...args);

    const _logArgs = this._getLogStr(callerInfo, logArgs);
    logger.debug(_logArgs);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  info = (...args) => {
    if (!args) {
      return;
    }
    
    const callerInfo = this._getCallerInfo();
    const _logArgs = this._getLogStr(callerInfo, ...args);
    logger.info(_logArgs);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  log = (...args) => {
    if (!args) {
      return;
    }
    
    const callerInfo = this._getCallerInfo();
    const _logArgs = this._getLogStr(callerInfo, ...args);
    logger.log(_logArgs);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  warn = (...args) => {
    if (!args) {
      return;
    }
    
    const callerInfo = this._getCallerInfo();
    const _logArgs = this._getLogStr(callerInfo, ...args);
    logger.warn(_logArgs);
  };

  /**
  * write logger
  *
  * @returns {void}
  */
  error = (...args) => {
    if (!args) {
      return;
    }
    
    const callerInfo = this._getCallerInfo();
    const _logArgs = this._getLogStr(callerInfo, ...args);
    logger.error(_logArgs);
  };

  /**
  * check is mobile return array, else return string log
  *
  * @returns {Array|String}
  */
  _getLogStr = (caller, ...args) => {
    //console.log(caller);
    let logPrefixes = [];
    if (caller && caller.methodName.length > 1) {
      logPrefixes.push(`<${caller.methodName}> at line: ${caller?.line}`);
    }

    const logArgs = logPrefixes.concat(...args);
    if(!this._isMobile()) {
      return logArgs;
    }

    let strLog = '';
    for (let i = 0; i < logArgs.length; i++) {
        if (typeof logArgs[i] === 'object') {
          strLog += ` ${this.stringify(logArgs[i])}` + `
`;
        } else {
          strLog += ` ${logArgs[i]}` + `
`;
        }
    }
    return strLog;
  };

  /**
  * stringify object to JSON string
  *
  * @returns {String}
  */
  stringify = (obj) => {
    if (!obj) {
      return '';
    }
    let retStr = '';
    try {
        if (typeof obj === 'object') {
            retStr = JSON.stringify(obj);
            return retStr;
        } else if (typeof obj === 'string') {
            return obj;
        }
    } catch (err) {
      //console.log(`[Error] stringify: ${err?.message}`);
      retStr = this._jsonCircular(obj);
      return retStr;
    }
  };

  /**
  * stringify object to JSON string
  *
  * @returns {String}
  */
  _jsonCircular = (obj) => {
    // stringify an object, avoiding circular structures
    // https://stackoverflow.com/a/31557814
    var simpleObject = {};
    for (var prop in obj ) {
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }
        if (typeof(obj[prop]) === 'object') {
          simpleObject[prop] = this._getClass(obj[prop]);
          continue;
        }
        if (typeof(obj[prop]) === 'function') {
          simpleObject[prop] = this._getClass(obj[prop]);
          continue;
        }
        simpleObject[prop] = obj[prop];
    }
    return JSON.stringify(simpleObject); // returns cleaned up JSON
  };

  /**
  * stringify object to JSON string
  *
  * @returns {String}
  */
  _getClass = (obj) => {
    if (typeof obj === "undefined") return "[undefined]";
    if (obj === null) return "[null]";
    
    if(obj && obj.constructor && obj.constructor?.name) {
        return `[${obj.constructor.name}]`;
    }
    return `[${Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1]}]`;
  };

 /**
 * https://github.com/jitsi/jitsi-meet-logger/blob/master/lib/Logger.js
 * Parses Error's object stack trace and extracts information about the last
 * caller before the log method was called.
 * @returns JS object with info about the caller - method name, file location,
 * line and column.
 */
  _getCallerInfo = () => {
    var callerInfo = {
        methodName: "",
        fileLocation: "",
        line: null,
        column: null
    };
    //gets the part of the stack without the logger wrappers
    var error = new Error();
    var stack = error.stack? error.stack.split("\n") : [];
    if(!stack || stack.length < 3) {
        return callerInfo;
    }
    var m = null;
    if(stack[3]) {
        m = stack[3].match(/\s*at\s*(.+?)\s*\((\S*)\s*:(\d*)\s*:(\d*)\)/);
    }
    if(!m || m.length <= 4) {
        //Firefox && Safari
        if(stack[2].indexOf("log@") === 0){
            //Safari
            callerInfo.methodName = stack[3].substr(0, stack[3].indexOf("@"));
        } else {
            //Firefox
            callerInfo.methodName = stack[2].substr(0, stack[2].indexOf("@"));
        }
        return callerInfo;
    }

    callerInfo.methodName = m[1];
    callerInfo.fileLocation = m[2];
    callerInfo.line = m[3];
    callerInfo.column = m[4];
    return callerInfo;
  };

  /**
  * check is mobile browser
  *
  * @returns {Boolean}
  */
  _isMobile = () => {
    const isMobile = STR_USERAGENT_MOBILE.test((navigator.userAgent || navigator.vendor || window.opera))
        || STR_USERAGENT_MOBILE2.test((navigator.userAgent || navigator.vendor || window.opera).substr(0, 4));
    
    return isMobile;
  };
}

// create instance
const rioLogger = new RioLogger();
// lock instance
Object.freeze(rioLogger);

export default rioLogger;

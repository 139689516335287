/* global __filename, $, Promise */
import * as Tone from "tone";
import { getLogger } from '@jitsi/logger';

const logger = getLogger(__filename);

export default class ToneEffect {
    /**
     * MediaStream resulted from mixing.
     */
    _effectTrack = undefined;

    _value = undefined;

    /**
     * Creates AudioMixerEffect.
     *
     * @param {Object} options - Object option
     */
    constructor(options) {
        const {value} = options;
        this._value = Number(value);
        
        this.shifter = undefined;
        this._audioMixer = undefined;
    }

    async setEffect(options) {
        const {value, micDeviceId} = options;
        
        this._audioMixer = new Tone.UserMedia();
        await this._audioMixer.open(micDeviceId).then(() => {
            const effectedDest = Tone.context.createMediaStreamDestination();
            // 加工済みの音声を受け取る空のノードを用意
            if (value !== undefined) {
                this.shifter = new Tone.PitchShift(value);
                this._audioMixer.connect(this.shifter);
                this.shifter.connect(effectedDest);
            }

            return effectedDest;
        }).then(effectTrack => {
            this._effectTrack = effectTrack;

            return this._effectTrack;
        }).catch(error => {
            logger.log('setEffect error', error)
        });
        return this;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    startEffect(stream) {
        const effectAudioTrack = this._effectTrack 
            ? this._effectTrack?.stream.getAudioTracks()
            : [];

        const oldAudioTracks   =  stream.getAudioTracks();
        if(oldAudioTracks.length) {
            stream.removeTrack(oldAudioTracks[0]);
        }
        if(effectAudioTrack.length) {
            stream.addTrack(effectAudioTrack[0]);
        }

        return stream;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    getStream() {
        return this._effectTrack?.stream;
    }

    /**
     * @inheritdoc
     *
     * Stops sending the media track. And removes it from the HTML.
     * NOTE: Works for local tracks only.
     *
     * @returns {void}
     */
    dispose() {
        if(this._audioMixer) {
            this._audioMixer.dispose();
            delete this._audioMixer;
        }
        if(this.shifter) {
            this.shifter.dispose();
            delete this.shifter;
        }
        if(this._effectTrack) {
            this._effectTrack.disconnect();
            delete this._effectTrack;
        }
    }
}

/* global config */
import UtilStore from './UtilStore';
import { httpConfig } from "./../../conferenceConfig";

import logger from '../extends/RioLogger';

class HttpService {
    refreshTime = httpConfig.jwtTimeout; //minute
    options = {};
    response_fail = {};
    store = new UtilStore();
    // Properties & Methods
    init = (options) => {
        this.options.urls = httpConfig.urls || {};
        this.options.domain = '';
        this.options.token = '';
        // set the correct url
        this.options.domain = (options.endpoints) ? options.endpoints.api : '';
    };
}

/**
 * This method can be used to send the recorder audio stream and
 * retrieve the answer from the transcription service from the callback
 *
 * @param {RecordingResult} recordingResult a recordingResult object which
 * includes the recorded audio stream as a blob
 * @param {Function} callback  which will retrieve the a RecordingResult with
 *        the answer as a WordArray
 */
HttpService.prototype.auth = async function(creds) {
    this.store.setSalt(`${creds.secret_key}`);
    const strCreds = this.store.strCrypt(creds);
    this.options.creds = strCreds;
    //clear timer
    this._clearTimer();
    return await this._doAuth();
};

HttpService.prototype._doAuth = async function() {
    const creds = this.store.strDecrypt(this.options.creds);

    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.auth),
        data: creds,
    }
    return new Promise ((resolve, reject) => {
        const token = this.getToken(false);
        if (token) {
            const sesAuth = this.getAuth();
            this._setTimer();
            return resolve(sesAuth.data);
        }
        //clear timer
        this._clearTimer();
        const {requestURL, requestObject} = this._getUrlAndParams(params);
        this._sendRequest(requestURL, requestObject)
        .then((response) => {
            const res = this._parseResponse(response);
            const { success, data } = res;
            if ( !success ) {
                return reject('Invalid authentication from server');
            }
            this.setAuth(data);
            this._setTimer();
            resolve(data);
        })
        .catch((err) => {
            this._setTimer();
            logger.log(`Error: HttpService _doAuth: ${err?.message}`);
            reject(err);
        });
    });
};

/**
 * This method can be used to send the recorder audio stream and
 * retrieve the answer from the transcription service from the callback
 *
 * @param {RecordingResult} recordingResult a recordingResult object which
 * includes the recorded audio stream as a blob
 * @param {Function} callback  which will retrieve the a RecordingResult with
 *        the answer as a WordArray
 */
HttpService.prototype.login = async function(userId) {
    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.login),
        data: {
            user_id: userId
        },
    }
    return await this._doRequest(params);
};

/**
 * send call log to api
 */
HttpService.prototype.trackingLog = async function(data) {
    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.call_log),
        data: data,
        ignoreToken: true
    }
    return await this._doRequest(params);
};

HttpService.prototype.register = async function(data, callback) {
    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.register),
        data: {
            user_id: data.user_id,
            name: data.name || '',
        },
    }
    return await this._doRequest(params);
};

HttpService.prototype.userList = async function(data, callback) {
    const params = {
        type: 'GET',
        url: this._getURL(this.options.urls.users),
        data,
    }
    return await this._doRequest(params);
};

HttpService.prototype.uploadRecoding = async function(blob, options={}) {
    const {call_id, fname, type, start_time, end_time} = options;
    const params = {
        type: 'POST',
        processData: false,
        contentType: false,
        url: this._getURL(this.options.urls.upload),
        data: {
            call_id: call_id,
            type: type,
            name: fname,
            file: blob,
            start_time,
            end_time
        },
    }

    return new Promise ((resolve, reject) => {
        const {requestURL, requestObject} = this._getUrlAndParams(params);
        
        this._sendRequest(requestURL, requestObject)
        .then((response) => {
            const res = this._parseResponse(response);
            const { success, data } = res;
            if ( !success ) {
                return reject('Upload failed');
            }
            resolve(data);
        })
        .catch((err) => {
            logger.log(`Error: HttpService uploadRecoding: ${err?.message}`);
            const res = this._parseResponse(err);
            resolve(res);
        });
    });
};

HttpService.prototype.trackRecording = async function(options={}) {
    const {call_id, type, start_time, end_time} = options;
    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.upload),
        data: {
            call_id: call_id,
            type: type,
            name: '',
            start_time,
            end_time
        },
    }

    return new Promise ((resolve, reject) => {
        const {requestURL, requestObject} = this._getUrlAndParams(params);
        
        this._sendRequest(requestURL, requestObject)
        .then((response) => {
            const res = this._parseResponse(response);
            const { success, data } = res;
            if ( !success ) {
                return reject('Tracking record failed');
            }
            resolve(data);
        })
        .catch((err) => {
            logger.log(`Error: HttpService trackRecording: ${err?.message}`);
            const res = this._parseResponse(err);
            resolve(res);
        });
    });
};

HttpService.prototype.callUpdate = async function(options={}) {
    const {call_id, jibri_session_id, jibri_start_time} = options;
    const params = {
        type: 'POST',
        url: this._getURL(this.options.urls.call_update),
        data: {
            call_id: call_id,
            jibri_session_id,
            jibri_start_time
        },
    }

    return new Promise ((resolve, reject) => {
        const {requestURL, requestObject} = this._getUrlAndParams(params);
        
        this._sendRequest(requestURL, requestObject)
        .then((response) => {
            const res = this._parseResponse(response);
            const { success, data } = res;
            if ( !success ) {
                return reject('Update call failed');
            }
            return resolve(data);
        })
        .catch((err) => {
            logger.log(`Error: HttpService callUpdate: ${err?.message}`);
            const res = this._parseResponse(err);
            resolve(res);
        });
    });
};

HttpService.prototype._setTimer = function() {
    const refTime = this.refreshTime * 1000;
    this.options.timer = setTimeout( () => {
        this._doAuth();
    }, refTime);
};

HttpService.prototype.getToken = function(thrw = true) {
    const sesAuth = this.getAuth();
    const {token} = sesAuth;

    if (! token && thrw !== false) {
        throw new Error("Token is expired");
    }
    return token;
};

HttpService.prototype.getAuth = function() {
    const creds = this.store.strDecrypt(this.options.creds);
    const sesAuth = this.store.get(`auth${creds.secret_id}`, {});
    
    const now = new Date().getTime();
    const {token, sTime} = sesAuth;
    const expireTime = (this.refreshTime-1) * 1000;

    if (token && (now > (expireTime + sTime)) ) {
        return {}
    }
    return sesAuth;
};

HttpService.prototype.setAuth = function(data) {
    const creds = this.store.strDecrypt(this.options.creds);
    const params = {
        sTime: new Date().getTime(),
        token: data.token,
        data: data,
        creds: creds,
    }
    this.store.set(`auth${creds.secret_id}`, params);
};

HttpService.prototype.destroy = function() {
    const creds = this.store.strDecrypt(this.options.creds);
    this._clearTimer();
    this.store.remove(`auth${creds.secret_id}`);
}
/**
 * Overrides the formatResponse method from AbstractTranscriptionService
 * It will parse the answer from the server in the expected format
 *
 * @param response the JSON body retrieved from the Sphinx4 server
 */
 HttpService.prototype._parseResponse = function(response) {
    //console.log(`response from server:${response.toString()}`);
    // test if server responded with a string object
    let json;

    if (typeof response === 'string') {
        // test if the string can be parsed into valid JSON
        try {
            json = JSON.parse(response);
            return json;
        } catch (err) {
            logger.log(`Error: HttpService parseResponse: ${err?.message}`);
            return this.response_fail;
        }
    }
    if (!response || typeof response !== 'object') {
        return this.response_fail;
    }

    // everything seems to be in order
    return response;
};

HttpService.prototype._doRequest = async function(params) {
    return new Promise ((resolve, reject) => {
        const {requestURL, requestObject} = this._getUrlAndParams(params);
        
        this._sendRequest(requestURL, requestObject)
        .then((response) => {
            const res = this._parseResponse(response);
            const { success, data } = res;
            if ( !success ) {
                return reject(`Send request API failed`);
            }
            resolve(data);
        })
        .catch((err) => {
            logger.warn(`Error: HttpService _doRequest: ${err?.message}`, requestURL, params);
            reject(err);
        });
    });
};

/**
 * Gets the URL to the Sphinx4 server from the config file. If it's not there,
 * it will throw an error
 *
 * @returns {string} the URL to the sphinx4 server
 */
 HttpService.prototype._getURL = function(path) {
    const message = 'config does not contain an url to a Sphinx4 https server';
    if (this.options.urls === undefined
        || this.options.domain === undefined) {
        logger.info(message);
    } else {
        const toReturn = (path)
            ? this.options.domain + path
            : this.options.domain;

        if (toReturn.includes !== undefined && toReturn.includes('https://')) {
            return toReturn;
        }
        return toReturn;
    }
};

/**
 * Gets the URL to the Sphinx4 server from the config file. If it's not there,
 * it will throw an error
 *
 * @returns {string} the URL to the sphinx4 server
 */
 HttpService.prototype._sendRequest = async function(requestURL, requestObject) {
    // Default options are marked with *
    const fetchResult = await fetch(
        requestURL,
        requestObject
        // {
        //     method: method, // *GET, POST, PUT, DELETE, etc.
        //     mode: 'cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     //credentials: 'same-origin', // include, *same-origin, omit
        //     headers: headers,
        //     redirect: 'follow', // manual, *follow, error
        //     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //     body: JSON.stringify(data) // body data type must match "Content-Type" header
        // }
    );
    // parses JSON response into native JavaScript objects
    const result = await fetchResult.json();
    if (fetchResult.ok) {
        return result;
    }

    const responseError = {
        type: 'Error',
        message: result.message || 'Error',
        data: result.data || [],
        code: result.code || 400,
    };

    let error = new Error();
    error = { ...error, ...responseError };
    throw (error);
};

HttpService.prototype._getUrlAndParams = function(params) {
    const isGetOrHeadType = !params.type || params.type === 'GET' || params.type === 'HEAD'
    const isPostOrPutType = params.type && (params.type === 'POST' || params.type === 'PUT')
    const {ignoreToken} = params;

    const token = (ignoreToken !== true ) ? this.getToken(false) : '';
    const isMultipartFormData = params.contentType === false

    let requestBody
    let requestURL = params.url
    const requestObject = {}

    requestObject.method = params.type || 'GET'

    if (params.data) {
      requestBody = this._buildBody(params, isMultipartFormData, isPostOrPutType)

      if (isGetOrHeadType) {
        requestURL += '?' + requestBody
      } else {
        requestObject.body = requestBody
      }
    }

    if (!isMultipartFormData) {
      requestObject.headers = {
        'Content-Type':
          isPostOrPutType ?
            'application/json;charset=utf-8' :
            'application/x-www-form-urlencoded; charset=UTF-8'
      }
    } else {
        requestObject.headers = {};
    }

    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // //credentials: 'same-origin', // include, *same-origin, omit
    // headers: headers,
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // body data type must match "Content-Type" header
    if (token) {
        requestObject.headers['Authorization'] = `Bearer ${token}`;
    }
    //requestObject.timeout = config.timeout

    return {requestURL, requestObject}
};

HttpService.prototype._buildBody = function(params, isMultipartFormData, isPostOrPutType) {
    const data = params.data
    let dataObject

    if (isMultipartFormData) {
      dataObject = new FormData();

      Object.keys(data).forEach(function (item) {
        if (params.fileToCustomObject && item === 'file') {
          dataObject.append(item, data[item].data, data[item].name)
        } else {
          dataObject.append(item, params.data[item])
        }
      });
    } else if (isPostOrPutType) {
      dataObject = JSON.stringify(data)
    } else {
      dataObject = Object.keys(data)
        .map(k => {
          if (typeof data[k] == 'object') {
            return Object.keys(data[k])
              .map(v => {
                return (
                  this.encodeURIComponent(k) +
                  '[' +
                  ((typeof data[k] == 'array') ? '' : v) +
                  ']=' +
                  this.encodeURIComponent(data[k][v])
                )
              })
              .sort()
              .join('&')
          } else {
            return (
              this.encodeURIComponent(k) +
              ((typeof data[k] == 'array') ? '[]' : '') +
              '=' +
              this.encodeURIComponent(data[k])
            )
          }
        })
        .sort()
        .join('&')
    }

    return dataObject
};

HttpService.prototype.encodeURIComponent = function(str) {
    return encodeURIComponent(str).replace(/[#$&+,/:;=?@\[\]]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16)
    });
};

HttpService.prototype._clearTimer = function() {
    if (this.options.timer) {
        clearTimeout(this.options.timer);
    }
    this.options.timer = null;
}

HttpService.prototype.recordList = async function(data) {
    const params = {
        type: 'GET',
        url: this._getURL(this.options.urls.records),
        data,
    }
    return await this._doRequest(params);
};

//module.exports = HttpService;
const httpService = new HttpService()
Object.freeze(httpService)

export default httpService

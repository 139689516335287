/*global RioConference */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/
//import Constants from '../../Constants';
//import RioHelper from './RioHelper';
import * as JitsiConferenceEvents from '../../JitsiConferenceEvents';
import RioAppInterface from './RioAppInterface';
import logger from './RioLogger';

class RioConference {
  options = {
      room: undefined,
      mode: '',
      failedCnt: 0,
      callInfo: {}
  };
  users = {
  };

  constructor() {
    this._init();
  }

  /**
  * init data instance
  *
  * @returns {void}
  */
  _init = () => {
    this.options.room = undefined;
    this.options.mode = '';
    this.options.failedCnt = 0;
    this.options.callInfo = {};
  };

  /*
    Start function send to app
  */
  /**
  * start call
  *
  * @returns {void}
  */
  onCreateRoom = (room, options = {}) => {
    try {
      const {mode, ...callInfo } = options;
      this.options.room = room;
      this.options.mode = mode;
      this.options.callInfo = callInfo;

    //  logger.log(`RioConference:`, room, mode, callInfo);
      this._setupListeners();
    } catch (err) {
      logger.log(`Error: ${err?.message}`);
    }
  };

  /**
  * Setup interaction between conference and UI.
  */
  _setupListeners = () => {
    try {
        const {room} = this.options;
        room.on(JitsiConferenceEvents.CONFERENCE_LEFT,
          (...args) => {
              logger.log(`CONFERENCE_LEFT`, ...args);
          });

        room.on(JitsiConferenceEvents.SUSPEND_DETECTED, () => {
            logger.log(`SUSPEND_DETECTED`);
        });

        room.on(JitsiConferenceEvents.CONNECTION_RESTORED, () => {
            logger.log(`CONNECTION_RESTORED`);
        });

        room.on(JitsiConferenceEvents.USER_ROLE_CHANGED, (id, role) => {
            logger.info(`USER_ROLE_CHANGED, id ${id} role:${role} ${room.isModerator()}`);
        });

        room.on(JitsiConferenceEvents.CONNECTION_INTERRUPTED, () => {
            const {failedCnt} = this.options;
            if(failedCnt > 3) {
                RioMeetJS.videoroom.onConnectionFailed();
                RioAppInterface.onStop('disconnect', 'CONNECTION_INTERRUPTED');
            }
            this.options.failedCnt = failedCnt+1;
            logger.log(`CONNECTION_INTERRUPTED`);
        });

        room.on(JitsiConferenceEvents.CALL_ENDED,
          (reason, reasonText) => {
              logger.log(`CALL_ENDED`, reason, reasonText);
              RioAppInterface.onStop(reason, reasonText);
          });

        room.on(JitsiConferenceEvents.USER_JOINED,(id, user) => {
            logger.log(`USER_JOINED`, id);
            if (user.isHiddenFromRecorder()) {
                return;
            }

            if (user.isHidden()) {
                return;
            }
            this.users[id] = user;
        });

        room.on(JitsiConferenceEvents.USER_LEFT,
          (id, user) => {
              logger.log(`USER_LEFT`, id);
              if (user.isHiddenFromRecorder()) {
                  return;
              }

              if (user.isHidden()) {
                  return;
              }

              const numParticipants = room.getParticipants();
              logger.log(`numParticipants: ${numParticipants}`);
              this.users[id] = null;
          });

    } catch (err) {
      logger.warn(`Error: ${err?.message}`);
    }
  };

  /**
  * destroy clear instance
  */
  destroy = () => {
    const {room} = this.options;
    this._init();
  };

}

// create instance
const rioConference = new RioConference();
// lock instance
Object.freeze(rioConference);

export default rioConference;

/*global RioHelper */
/*eslint-disable no-unused-vars, no-var, max-len */
/*eslint no-undef: "error"*/

import browser from '../browser';
import CameraFacingMode from '../../service/RTC/CameraFacingMode';
import logger from './RioLogger';

import {
    STR_USERAGENT_MOBILE,
    STR_USERAGENT_MOBILE2
} from './constants';

class RioHelper {
  options = {
    _key: '__rio__',
  };

  constructor() {
    const { userAgent } = navigator;
    logger.debug(
            `RioHelper instance`
            + ` userAgent ${userAgent}`
            + ` isAndroidBrowser ${browser.isAndroidBrowser()}`
            + ` isChromiumBased ${browser.isChromiumBased()}`
            + ` isMobileDevice ${browser.isMobileDevice()}`
            + ` isIosBrowser ${browser.isIosBrowser()}`
            + ` supportsVP9 ${browser.supportsVP9()}`
            + ` isWebKitBased ${browser.isWebKitBased()}`
            + ` isSupported ${browser.isSupported()}`
            + ` isSupportedAndroidBrowser ${browser.isSupportedAndroidBrowser()}`
            + ` isSupportedIOSBrowser ${browser.isSupportedIOSBrowser()}`
            + ` getBrowserVersion ${this.browserVersion()}`
            + ` window.location.href ${window.location.href}`
        );
  }

  /**
  * create instance RioHelper
  *
  * @returns {void}
  */
  instance = () => {
    if (!window.RioHelper) {
      window.RioHelper = this;
    }
    
    return window.RioHelper;
  };

  /**
  * check is true
  *
  * @returns {Boolean}
  */
  isTrue = (val) => {
    if ( val === 'true'
        || (val === true)
        || (val === '1')
        ) {
        return true;
    }

    return false;
  };

  /**
  * check is Empty
  *
  * @returns {Boolean}
  */
  isEmpty = (obj) => {

    if (typeof obj === 'object') {
        return JSON.stringify(obj) === "{}";
    } else if (typeof obj === 'string') {
        return obj === "";;
    }
    if (typeof obj == 'undefined'
        || (obj === null)
        || (obj === false)
        || (obj === undefined)
        ) {
        return true;
    }

    return false;
  };

  /**
  * encode object to JSON string
  *
  * @returns {String}
  */
  encode = (obj, notLog) => {
    if (!obj) {
      return '';
    }
    if (notLog !== true) {
      logger.debug('[debug] encode', obj);
    }

    try {
        if (typeof obj === 'object') {
            let json = JSON.stringify(obj);
            return json;
        } else if (typeof obj === 'string') {
            return obj;
        }
    } catch (err) {
      logger.warn(`Error: RioHelper encode: ${err?.message}`);
    }
  };

  /**
  * cecode string JSON to object
  *
  * @returns {Object}
  */
  decode = (str, defVal=null) => {
    this.log(str);
    if (!str) {
      return defVal;
    }
    try {
        if (typeof str === 'string') {
            let json = JSON.parse(str);
            return json;
        } else if (typeof str === 'object') {
            return str;
        }
    } catch (err) {
      logger.warn(`Error: RioHelper decode: ${err?.message}`);
    }
    return defVal;
  };

  /**
  * console log object param
  *
  * @returns {Object}
  */
  log = (str, func = '') => {
    if (!str) {
      return;
    }
    try {
        let found = false;
        let str1 = str;
        if (str.indexOf('creds') !== -1 
          || str.indexOf('credential') !== -1 
        ) {
          found = true;
          str1 = str1.replace(/("secret_id"\:")(\w+)\"/g, '$1xxxxxxxxxxxxxxx"');
          str1 = str1.replace(/("secret_key"\:")(\w+)\"/g, '$1xxxxxxxxxxxxxxx"');
        }
        if (str.indexOf('token') !== -1) {
          found = true;
          str1 = str1.replace(/("token"\:")([\w+\.-]+)\"/g, '$1xxxxxxxxxxxxxxx"');
        }

        if (str.indexOf('png;base64') !== -1) {
          found = true;
          str1 = str1.replace(/(data\:image\/png;base64,)([\w+\.-\/\+\=]+)/g, '$1xxxxxxxxxxxxxxx==');
        }
        if (found === true) {
          logger.debug(`[debug] ${func}`, str1);
          str1 = null;
        } else {
          logger.debug(`[debug] ${func}`, str);
        }
    } catch (err) {
    }
    return;
  };

  /**
  * Checks if the current platform is iOS.
  *
  * @returns {boolean}
  */
  isIosBrowser() {
    const { userAgent, maxTouchPoints, platform } = navigator;

    return Boolean(userAgent.match(/iP(ad|hone|od)/i))
        || (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform));
  }

  /**
  * get agent type of browser
  *
  * @returns {String}
  */
  getAgentType(agentType) {
    if ( agentType 
      && (agentType == 'ios' || agentType == 'android' || agentType == 'web')) {
      
      return agentType;
    }

    if ( this.isIosBrowser()) {
      return 'ios';
    } else if ( this.isMobile()) {
      return 'android';
    }

    return 'web';
  }

  /**
  * Checks if the current platform is iOS.
  *
  * @returns {boolean}
  */
  browserVersion() {
    return browser.getBrowserVersion();
  }

  /**
  * get current screen size
  *
  * @returns {Object}
  */
  getScreenSize() {
    const {width, height, availWidth, availHeight } = window.screen;
    
    return {
        video: {
            height: {
                ideal: availHeight || height,
                max: availHeight || height,
            },
            width: {
                ideal: availWidth || width,
                max: availWidth || width,
            }
        }
     };
  }

  /**
  * get current screen size
  *
  * @returns {Object}
  */
  getMaxHeight(defVal=1080) {
    const {availHeight } = window.screen;
    
    if (this.isMobile() && availHeight) {
        return availHeight;
    }

    return (defVal || 1080);
  }

  setSession = (key, val) => {
    if (typeof val == 'object') {
      val = JSON.stringify(val);
    }

    sessionStorage.setItem(`${this.options._key}`+ key, val);
  };

  getSession = (key) => {
    return sessionStorage.getItem(`${this.options._key}`+ key);
  };

  removeSession = (key) => {
    return sessionStorage.removeItem(`${this.options._key}`+ key);
  };

  setStorage = (key, val) => {
    if (typeof val == 'object') {
      val = JSON.stringify(val);
    }

    localStorage.setItem(`${this.options._key}`+ key, val);
  };

  getStorage = (key) => {
    return localStorage.getItem(`${this.options._key}`+ key);
  };

  removeStorage = (key) => {
    return localStorage.removeItem(`${this.options._key}`+ key);
  };

  obtainCameraId = (cameraDeviceId) => {
    if (this.isMobile()) {
        const _facingMode = (cameraDeviceId === CameraFacingMode.ENVIRONMENT) 
            ? CameraFacingMode.ENVIRONMENT 
            : CameraFacingMode.USER;

        return _facingMode;
    }

    return cameraDeviceId;
  };

  obtainFacingMode = (options) => {
    const {
        deviceId, 
        facingMode,
        ...otherOptions
    } = options;

    const cloneOptions = {...options};
    let _facingMode = '';

    if (this.isMobile()) {
        _facingMode = (facingMode) ? facingMode : deviceId;
        _facingMode = (_facingMode === CameraFacingMode.ENVIRONMENT) 
            ? CameraFacingMode.ENVIRONMENT 
            : CameraFacingMode.USER;

        cloneOptions.facingMode = _facingMode;
        cloneOptions.frameRate = 25;

        delete cloneOptions?.deviceId;
        delete cloneOptions?.cameraDeviceId;
    } else {
        delete cloneOptions?.facingMode;
    }

    logger.debug('obtainFacingMode', options, cloneOptions);
    return cloneOptions;
  };

  /**
  * check is mobile browser
  *
  * @returns {Boolean}
  */
  isMobile = () => {
    const isMobile = STR_USERAGENT_MOBILE.test((navigator.userAgent || navigator.vendor || window.opera))
        || STR_USERAGENT_MOBILE2.test((navigator.userAgent || navigator.vendor || window.opera).substr(0, 4));
    
    return isMobile;
  };

}

// create instance
const rioHelper = new RioHelper();
// lock instance
Object.freeze(rioHelper);

export default rioHelper;

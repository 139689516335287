/* global config */
import Constants from './Constants';
import html2canvas from "html2canvas";

import HttpService from './modules/http/HttpService';
import SocketService from './modules/socket/SocketService';
import RioAppInterface from './modules/extends/RioAppInterface';
import browser from './modules/browser';

import RioHelper from './modules/extends/RioHelper';
import logger from './modules/extends/RioLogger';
import RioMedia from './modules/extends/RioMedia';

export default class RioScreenshot {
    constructor(option) {
        const {timerCapture, mode} = option;

        this.callId = option.callId;
        this.timeInterval = parseInt(timerCapture, 10) * 1000;
        this.interval = '';
        this._started = false;
        this.mode = mode;
    }

    /**
     * The list of {@link SocketService} events.
     *
     * @returns {Object}
     */
    static get instance() {
        if (RioScreenshot._instance === undefined) {
            RioScreenshot._instance = new this();
        }
        return RioScreenshot._instance;
    }

}

/**
 * take screen into calling
 * @param options
 */
RioScreenshot.prototype.autoScreenshot = function (options = {}) {
    const self = this;
    if (this._started) {
        return;
    }
    this._started = true;
    logger.log(`RioScreenshot screenshot #${this.callId}`);
    //capture screenshot
    self.screenshot();
    //check loop interval time
    if (self.timeInterval) {
        self.interval = setInterval(() => {
            self.screenshot();
        }, this.timeInterval)
    }
}

/**
 * take screen
 * @param option
 */
RioScreenshot.prototype.screenshot = function() {
    const self = this;
    try {
        const $chatScreen = document.getElementsByTagName('body')[0];
        const $videos = document.getElementsByTagName('video');
        const screenSizes = RioHelper.getScreenSize();
        const width = screenSizes.video.width.max;
        const height = screenSizes.video.height.max;
        
        const options = {
            allowTaint: false, //default canvas
            //backgroundColor: "#000000",//#ffffff
            ignoreElements: function( element ) {
                /* Remove all elements with class="html2canvas-ignore" */
                if( element.classList.contains( 'html2canvas-ignore' )) {
                    return true;
                } else if( (`${element?.tagName}`.toLowerCase() == 'video') ) {
                    if( element.classList.contains( 'videochat-stream' ) 
                    || (element.classList.contains( 'videochat-remote-stream' ) 
                        && self.mode !== Constants.MODE.API )
                    ) {
                        return false;
                    }
                    return true;
                }
            },
            scale: window.devicePixelRatio
            //x: 0, //Element x-offset
            //y: 0, //Element y-offset
        };

        //if (browser.isIosBrowser() || browser.isAndroidBrowser()) {
        //    options.width = width; //Element width;
        //    options.height = height; //Element height;
        //    //options.useCORS = true;
        //    options.scale = Math.min(window.devicePixelRatio, 2);
        //};
        if ($chatScreen && self.callId) {
            html2canvas($chatScreen, options).then(
                async function (canvas) {
                if (self.mode === Constants.MODE.API) {
                    let context = canvas.getContext('2d');
                    await self.addVideoStreams(context, $videos);
                }
                //canvas.toBlob(self.blobCallback(self.callId), 'image/png', 0.95);
                self.sendFileBase64(self.callId, canvas.toDataURL())
            }).catch(err => {
                logger.log(`Error: RioScreenshot screenshot err: ${err?.message}`);
            });
        }
    } catch (err) {
        logger.warn(`Error: screenshot ${err?.message}`);
    }
}

RioScreenshot.prototype.addVideoStreams = async function (context, $videos) {
    const self = this;
    const remoteVideo = document.getElementById(RioMedia.getRemoteStreamId());
    if (remoteVideo) {
        self.addVideoStream(context, remoteVideo);
    }

    const localVideo = document.getElementById(RioMedia.getLocalStreamId());
    if (localVideo) {
        self.drawLocalVideo(context, localVideo);
    }

    //if ($videos.length > 0) {
    //    $videos = await this.sortDescWidthVideo($videos);
    //    $videos.forEach(function ($video) {
    //        self.addVideoStream(context, $video)
    //    })
    //}
}

RioScreenshot.prototype.addVideoStream = function (context, $eleStream) {
    if ($eleStream && context) {
        //const screenSizes = RioHelper.getScreenSize();
        //const maxWidth = screenSizes.video.width.max;
        //const maxHeight = screenSizes.video.height.max;
        //const imgUrl = RioMedia.getAvatar();

        const config = $eleStream.getBoundingClientRect();
        const {x, y, width, height} = config;

        logger.debug(`RioScreenshot ${window.devicePixelRatio}`, config);
        if (width > 0 && height > 0) {
            let videoY = Math.floor(y), 
                videoW = Math.floor(width), 
                videoH = Math.floor(height);

            const videoWidth = $eleStream.videoWidth || 480;
            const videoHeight = $eleStream.videoHeight || 640;
            
            //logger.debug(`RioScreenshot addVideoStream ${x} ${y} ${width} ${height} ${videoWidth} ${videoHeight} `);
            //context.fillStyle = '#000000';
            //context.fillRect(x, y, width, height);
            //context.drawImage($eleStream, x, y, vWidth, vHeight);
            context.drawImage($eleStream,
                0,
                videoY,
                videoW,
                videoH
            );
        }
    }
}

RioScreenshot.prototype.drawLocalVideo = function (context, $eleStream) {
    if ($eleStream && context) {
        const config = $eleStream.getBoundingClientRect();
        const {x, y, width, height} = config;
        const videoWidth = $eleStream.videoWidth || 100;
        const videoHeight = $eleStream.videoHeight || 120;
        
        const vWidth = (width > videoWidth) ? videoWidth : width;
        const vHeight = (height > videoHeight) ? videoHeight : height;

        //context.fillStyle = '#000000';
        //context.fillRect(x, y, width, height);
        context.drawImage($eleStream, x, 64, vWidth, vHeight);
    }
}

/**
 * sort width video by desc
 * @param videos
 * @returns {*}
 */
RioScreenshot.prototype.sortDescWidthVideo = function (videos) {
    videos = Object.values(videos);
    let tg;
    for (let i = 0; i <= videos.length - 2; i++) {
        const configI = videos[i].getBoundingClientRect();
        for (let j = i + 1; j <= videos.length - 1; j++) {
            const configJ = videos[j].getBoundingClientRect();
            if (configI.width < configJ.width) {
                tg = videos[i];
                videos[i] = videos[j];
                videos[j] = tg;
            }
        }
    }
    return videos;
}

RioScreenshot.prototype.sendFileBase64 = function (callId, base64) {
    const token = HttpService.getToken();
    const date = new Date();
    const fname = `screenshot_${date.getTime()}.png`;

    if (this.mode === Constants.MODE.API) {
        RioAppInterface.onScreenshot({callId, base64: base64, filename: fname});
    } else {
        SocketService.instance.sendBlob({callId, base64: base64, token, name: fname});
    }
}

/**
 * stop auto screenshot
 */
RioScreenshot.prototype.stopScreenshot = function () {
    this._started = false;

    if(this.interval) {
        clearInterval(this.interval);
    }
}

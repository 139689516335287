/* eslint-disable no-unused-vars, no-var */

const hostDomain = 'meet.rio-video-call.click';
//const hostDomain = 'alpha.jitsi.net';

export const appConfig = {
    hosts: {
        domain: hostDomain,
        muc: 'conference.'+ hostDomain
    },
    serviceUrl: '//' + hostDomain + '/http-bind',
    endpoints: {
        sdk: '//sdk.rio-video-call.click',
        api: 'https://api.rio-video-call.click/server/api/v1',
        socket: {
            url: 'wss://api.rio-video-call.click',
            path: '/socket/app'
        }
    },
    //second auto capture
    timerCapture: 30,
    DEFAULT_TRANSCRIBER_LANG: 'ja-JP',
    WT_ENABLE: false,
    LOG_LEVEL: "debug", // error warn log info debug trace
    serverRecording: true, // start server recording
};

export const ENABLE_TRACKING_LOG = 3; //0: disable, enable set number
export const TIMEZONE = "Asia/Tokyo"; //"Asia/Tokyo"

export const appSetting = {
  POSTER_URL: 'https://sdk.rio-video-call.click/images/avatar.png',
}

export const VOICE_TYPE = 1; //1: Tone, 2: Jungle

/* eslint-enable no-unused-vars, no-var */

/* global config */
import EventEmitter from 'events';
import clonedeep from 'lodash.clonedeep';
import * as SocketEvent from './SocketEvent';
import { io } from "socket.io-client";
import Constants from '../../Constants';

import logger from '../extends/RioLogger';
import RioTimer from '../extends/RioTimer';

const {STATE} = Constants;

export default class SocketService {
    data = {};
    options = {reconnect: true};
    state = STATE.INITIAL;
    eventEmitter = new EventEmitter();
    onCallInterval = null;
    retry = 0;
    maxRetry = 5;
    /**
     * The list of {@link SocketService} events.
     *
     * @returns {Object}
     */
    static get instance() {
        if (SocketService._instance === undefined) {
            SocketService._instance = new this();
        }
        return SocketService._instance;
    }

    /**
     * @returns {Object}
     */
    init = (options) => {
        this.options = Object.assign({}
            , this.options
            , options
        );

        this._initWebSocket
        = this._initWebSocket.bind(this);

        this._handleMessage
            = this._handleMessage.bind(this);

        this.idle
            = this.idle.bind(this);
    }
}

SocketService.prototype.connect = async function() {
    this.setState(STATE.INITIAL);
    return await this._initWebSocket(0);
};

SocketService.prototype._initWebSocket = function(count) {
    const self = this;
    const socketOptions = this.options.socket;
    const emitter = this.eventEmitter;
    const loop = count + 1;
    this._clearOnCallTimer();
    const reconnect = Boolean(self.options.reconnect);

    return new Promise((resolve, reject) => {
        //let wsClient = new WebSocket(socketOptions.url);
        const socket = io(`${socketOptions.url}${socketOptions.path}/${this.options.appId}`, {
        //const socket = io(`${socketOptions.url}`, {
            path: `${socketOptions.path}/${this.options.appId}`,
            timeout: 20000,
            pingInterval: 25000,
            pingTimeout: 18000,
            autoConnect: false,
            reconnection: reconnect,
            reconnectionDelay: 1000,
            reconnectionAttempts: 60,
            withCredentials: true,
            transports: [ "websocket", "polling" ],
            auth: {
                "X_APP_ID": this.options.appId
            }
        });
        socket.connect();

        socket.on("connect", () => {
            self.ws = socket;
            self._clearOnCallTimer();
            const {currentUserId} = this.data;
            if (currentUserId) {
                this.reRegister();
                if (self.state != STATE.INITIAL) {
                    //self._inError({message: "System reconnect"});
                }
            }
            emitter.emit(SocketEvent.WS_EVENT_CONNECTION, true);
            resolve(socket);
        });
        socket.on("message", (msg) => {
            self._handleMessage(msg);
        });
        socket.on("reconnect_attempt", (attempt) => {
            logger.info('reconnect_attempt', attempt);
        });
        socket.on("reconnect_failed", () => {
            emitter.emit(SocketEvent.WS_EVENT_CONNECTION, false);
            resolve(false);
        });
        socket.on("connect_error", (err) => {
            self.retry++;
            if (reconnect) {
                if (self.retry > (self.maxRetry)) {
                    //self._inError({message: "System error"});
                    emitter.emit(SocketEvent.WS_EVENT_CONNECTION, false);
                    resolve(false);
                }
            } else {
                if (loop > self.maxRetry) {
                    self._clearOnCallTimer();
                    //self._inError({message: "System error"});
                    emitter.emit(SocketEvent.WS_EVENT_CONNECTION, false);
                    resolve(false);
                } else {
                    self.onCallInterval = setTimeout( () => {
                        self._initWebSocket(loop);
                    }, 1000);
                }
            }
        });
    });
};

/**
 * signaling communication
 */
SocketService.prototype._handleMessage = function(msg) {
    try {
        if (typeof msg === 'string') {
          msg = JSON.parse(msg);
        }
    } catch (err) {
        logger.log(`Error: SocketService _handleMessage: ${err?.message}`);
    }
    
    logger.info(`handleMessage ws:in: ${this.state}`, msg);
    switch (msg.act) {
        case SocketEvent.WS_ACT_REGISTER:
            this._registerResponse(msg);
            break;
        
        case SocketEvent.WS_EVENT_JOIN:
            this._userJoined(msg);
            break;
        
        case SocketEvent.WS_EVENT_LEAVE:
            this._userLeave(msg);
            break;

        case SocketEvent.WS_ACT_CALL_INCOMING:
            //send callback to other socket server
            this._callCallback({...msg});
            this._incomingCall(msg);
            
            break;

        case SocketEvent.WS_ACT_MSG_INCOMING:
            break;

        case SocketEvent.WS_ACT_CALL_START:
            const {callId} = msg;
            //send callback to other socket server
            this._callCallback({...msg});

            //@TODO DEMO
            //if (!callId) {
            //    const copyMsg = {...msg};
            //    copyMsg.act = SocketEvent.WS_ACT_CALL_INFO;
            //    copyMsg.userId = this.data?.currentUserId;
            //    this.sendMessage(copyMsg);
            //} else {
            //    this._startCall(msg, 'callee');
            //}
            this._startCall(msg, 'callee');
            RioTimer.debug("callee WS_ACT_CALL_START");
            break;

        case SocketEvent.WS_ACT_CALL_STOP:
        case SocketEvent.WS_ACT_CALL_FORCE_STOP:
            if (this.state !== STATE.INITIAL) {
                //send callback to other socket server
                this._callCallback({...msg});
            }
            this._stop(msg);
            break;

        case SocketEvent.WS_ACT_CALL_RESPONSE:
            const {type} = msg;
            //send callback to other socket server
            this._callCallback({...msg});
            const {currentSessionId} = this.data;

            logger.info(`type ${type} currentSessionId ${currentSessionId} sessionId ${msg?.sessionId} toSessionId ${msg?.toSessionId}`);
            //if ((type == 'caller'
            //        || (type == 'callee' && currentSessionId == msg?.sessionId) )
            //    && msg.response === SocketEvent.WS_CALL_ACCEPTED) {
            if (type == 'caller'
                && msg.response === SocketEvent.WS_CALL_ACCEPTED) {

                this._startCall(msg, type);
                RioTimer.debug("caller WS_CALL_ACCEPTED");
            } else {
                this._callResponse(msg);
            }

            break;

        case SocketEvent.WS_ACT_CHANGE_QUALITY:
            this._receiveQuality(msg);
            break;

        case SocketEvent.WS_ACT_VOICE_CHANGE:
            this._receiveVoiceChange(msg);
            break;
    
        case SocketEvent.WS_EVENT_TOGGLE_EFFECT:
            this._receiveToggleEffect(msg);
            break;
            
        case SocketEvent.WS_ACT_ICE_CANDIDATE:
            this.remoteIceCandidate(msg.candidate);
            break;

        case SocketEvent.WS_ACT_CALL_INFO_RESPONSE:
            logger.debug(`WS_ACT_CALL_INFO_RESPONSE`, msg);

            break;

        case SocketEvent.WS_ACT_ERROR:
            this._inError(msg);
            break;
    }
}

SocketService.prototype.reRegister = function() {
    const {currentUserId} = this.data;
    this.sendMessage({
        act: SocketEvent.WS_SEND_REGISTER,
        name: currentUserId,
        reconnection: true,
    });
};

SocketService.prototype.register = async function(name) {
    this.data['currentUserId'] = name;

    this.sendMessage({
        act: SocketEvent.WS_SEND_REGISTER,
        name: name,
        reconnection: false,
    });
    
    return new Promise( async (resolve, reject) => {
        await this._onRegisterCallResponse().then( (data) => {
            resolve(data);
        })
        .catch( err => {
            logger.log(`Error: SocketService register: ${err?.message}`);
            reject('register failed');
        });
    });
};

SocketService.prototype._onRegisterCallResponse = async function() {
    return new Promise((resolve, reject) => {
        this.once(
            SocketEvent.WS_EVENT_REGISTER, (type, data) => {
                if (type === SocketEvent.WS_CALL_ACCEPTED) {
                    resolve(data);
                } else if (type === SocketEvent.WS_CALL_TIMEOUT) {
                    reject();
                } else {
                    reject();
                }
            });
    });
}


/**
 * send call options to socket server

 * @param token string token authentication
 * @param options.calleeId to userId
 * @param options.initiatorID from start call
 * @param options.callType type of call, video or audio
 * @param options.language language of calling
 *
 */
SocketService.prototype.call = function(token, options) {
    const {calleeId, initiatorID, language, callType} = options;

    this.addFrom(initiatorID);
    this.addTo(calleeId);
    this.setState(STATE.WAITING);
    this.sendMessage({
        act: SocketEvent.WS_SEND_CALL,
        from: initiatorID,
        to: calleeId,
        token: token,
        callType: callType,
        language: language,
        timeout: SocketEvent.WS_TIMEOUT,
        sdpOffer: {}
    });
    RioTimer.debug("SocketService call");
};

SocketService.prototype.accept = function(userId, options) {
    const {data} = this;
    const {acceptCallType} = options;
    this.addUserId(userId);

    this.setState(STATE.PROCESSING);

    this.sendMessage({
        act: SocketEvent.WS_SEND_INCOMING_RESPONSE,
        userId: userId,
        acceptCallType: acceptCallType,
        callResponse: SocketEvent.WS_CALL_ACCEPTED,
        sdpOffer: {}
    });
    this._clearOnCallTimer();
    RioTimer.debug("SocketService accept");
};

SocketService.prototype.reject = function(userId) {
    const {data} = this;
    this._clearOnCallTimer();
    this.setState(STATE.WAITING);
    this.sendMessage({
        act: SocketEvent.WS_SEND_INCOMING_RESPONSE,
        userId: userId,
        callResponse: SocketEvent.WS_CALL_REJECTED,
        sdpOffer: {}
    });
};

SocketService.prototype.startRoom = function(userId, reconnected = 0) {
    this.sendMessage({
        act: SocketEvent.WS_SEND_ROOM_START,
        userId: userId,
        reconnected: reconnected,
    });
    RioTimer.debug("SocketService startRoom");
};

SocketService.prototype.stopCall = function(userId) {
    if (this.state !== STATE.INITIAL) {
        this.setState(STATE.INITIAL);
        this.sendMessage({
            act: SocketEvent.WS_SEND_STOP,
            userId: userId
        });
    }
};

SocketService.prototype.changeQuality = function(quality) {
    if (this.state != STATE.IN_CALL) {
        return;
    }
    this.sendMessage({
        quality: quality,
        act: SocketEvent.WS_SEND_CHANGE_QUALITY,
    });
};

SocketService.prototype.voiceChange = function(options) {
    if (this.state != STATE.IN_CALL) {
        return;
    }
    this.sendMessage({
        options: options,
        act: SocketEvent.WS_SEND_VOICE_CHANGE,
    });
};

SocketService.prototype.toggleEffect = function(options) {
    if (this.state != STATE.IN_CALL) {
        return;
    }
    this.sendMessage({
        options: options,
        act: SocketEvent.WS_EVENT_TOGGLE_EFFECT,
    });
};

SocketService.prototype.sendBlob = function(options) {
    if (this.state != STATE.IN_CALL) {
        return;
    }

    const objMsg = Object.assign({}, options, {
        act: SocketEvent.WS_EVENT_UPLOAD_SCREENSHOT,
    });

    this.sendMessage(objMsg);
};

SocketService.prototype._callResponse = function(msg) {
    const resType = msg.response;
    this._clearOnCallTimer();

    if ( resType !== SocketEvent.WS_CALL_ACCEPTED ) {
        this._clearCall();
    }

    const emitter = this.eventEmitter;
    emitter.emit(SocketEvent.WS_EVENT_RESPONSE, resType, msg);
};

SocketService.prototype._checkAnswer = function(userId, loop=0) {
    let cnt = loop+1;

    if (cnt > SocketEvent.WS_TIMEOUT) {
        this._notAnswer(userId);
    } else {
        this._clearOnCallTimer();
        this.onCallInterval = setTimeout(() => {
            this._checkAnswer(userId, cnt);
        }, 1000);
    }
}

SocketService.prototype._notAnswer = function(userId) {
    this._clearOnCallTimer();
    if (!this._isOpen()) {
        const msg = {
            from: this.data.from,
            to: this.data.to,
            userId: userId,
            response: SocketEvent.WS_CALL_TIMEOUT,
        }
        this._clearCall();
        return this._callResponse(msg);
    }

    this.setState(STATE.WAITING);
    this.sendMessage({
        userId: userId,
        act: SocketEvent.WS_SEND_NOT_ANSWER,
        callResponse: SocketEvent.WS_CALL_TIMEOUT,
    });
};

SocketService.prototype._clearOnCallTimer = function(wsJson) {
    if (this.onCallInterval) {
        clearTimeout(this.onCallInterval);
    }
    this.onCallInterval = null;
}

SocketService.prototype._userJoined = function(msg) {
    const emitter = this.eventEmitter;
    const {userId} = msg;

    emitter.emit(SocketEvent.WS_EVENT_JOIN, userId);
};

SocketService.prototype._userLeave = function(msg) {
    const emitter = this.eventEmitter;
    const {userId} = msg;

    emitter.emit(SocketEvent.WS_EVENT_LEAVE, userId);
};

SocketService.prototype._registerResponse = function(msg) {
    const emitter = this.eventEmitter;
    this.data['currentSessionId'] = msg.sessionId;

    if (msg.response === SocketEvent.WS_CALL_ACCEPTED) {
        emitter.emit(SocketEvent.WS_EVENT_REGISTER, SocketEvent.WS_CALL_ACCEPTED, msg);
    } else if (msg.response === SocketEvent.WS_CALL_TIMEOUT) {
        emitter.emit(SocketEvent.WS_EVENT_REGISTER, SocketEvent.WS_CALL_TIMEOUT, msg);
    } else {
        emitter.emit(SocketEvent.WS_EVENT_REGISTER, SocketEvent.WS_CALL_REJECTED, msg);
    }
};

SocketService.prototype._incomingCall = function(msg) {
    const {from, to} = msg;
    this.addFrom(from);
    this.addTo(to);
    this.setState(STATE.WAITING);

    const emitter = this.eventEmitter;
    emitter.emit(SocketEvent.WS_EVENT_CALL_INCOMING, msg);

    this._clearOnCallTimer();
    this._checkAnswer(to, 0);
};

SocketService.prototype._startCall = function(msg, type) {
    const {userId, reconnected, sessionId, response} = msg;
    const {currentSessionId} = this.data;

    this._clearOnCallTimer();
    if (this.state !== STATE.INITIAL) {
        this.addUserId(userId);
        const emitter = this.eventEmitter;
        this.setState(STATE.IN_CALL);
        if (reconnected) {
            emitter.emit(SocketEvent.WS_EVENT_CALL_RECONNECT, msg);
        } else {
            emitter.emit(SocketEvent.WS_EVENT_CALL_START, type, msg);
        }
    }
};

SocketService.prototype._stop = function(msg) {
    if (this.state !== STATE.INITIAL) {
        this.setState(STATE.INITIAL);
        const emitter = this.eventEmitter;
        const {message} = msg;
        emitter.emit(SocketEvent.WS_EVENT_CALL_STOP, msg.userId, message);
        this._clearCall();
    }

    this._clearOnCallTimer();
};

SocketService.prototype._receiveQuality = function(msg) {
    const emitter = this.eventEmitter;
    emitter.emit(SocketEvent.WS_EVENT_CHANGE_QUALITY, msg);
};

SocketService.prototype._receiveVoiceChange = function(msg) {
    const emitter = this.eventEmitter;
    const {options} = msg;
    emitter.emit(SocketEvent.WS_EVENT_VOICE_CHANGE, options);
};

SocketService.prototype._receiveToggleEffect = function(msg) {
    const emitter = this.eventEmitter;
    const {options} = msg;
    emitter.emit(SocketEvent.WS_EVENT_TOGGLE_EFFECT, options);
};

SocketService.prototype._inError = function(msg) {
    const emitter = this.eventEmitter;
    emitter.emit(SocketEvent.WS_EVENT_ERROR, msg);
};

SocketService.prototype.setState = function(state) {
    this.state = state;
};

SocketService.prototype._clearCall = function() {
    this.addFrom(null);
    this.addTo(null);
    this.setState(STATE.INITIAL);
    this._clearOnCallTimer();
};

SocketService.prototype._callCallback = function(msg) {
    const {act, ...restMsg} = msg;
    const objMsg = Object.assign({}, restMsg, {
        act: SocketEvent.WS_ACT_CALL_RESPONSE_CB,
    });
    this.sendMessage(objMsg);
};

SocketService.prototype.sendMessage = function(msg) {
    if (!this._isOpen()) {
        throw new Error("WebSocket is already in CLOSING or CLOSED state");
    }
    try {
        const defaultMsg = {
            appId: this.options.appId,
            secretId: this.options.secret_id,
            userId: this.data.userId,
            from: this.data.from,
            to: this.data.to,
            agentType: 'web',
            state: this.state,
        }
        const objMsg = Object.assign({}, defaultMsg, msg);
        const logMsg = clonedeep(objMsg);
        if (logMsg.base64) {
            logMsg.base64 = "data:image/png;base64,xxxxxxxxxxxxxxx";
        }
        if (logMsg.token) {
            logMsg.token = "xxxxxxxxxxxxxxx";
        }

        logger.info('ws:out:', logMsg);
        this.ws.emit('message', JSON.stringify(objMsg));
    } catch (err) {
        logger.log(`Error: SocketService sendMessage: ${err?.message}`);
        throw new Error("Can not send to socket");
    }
};

SocketService.prototype._isOpen = function() {
    return ( this.ws.connected === true
            && (!window.navigator || (window.navigator && window.navigator.onLine === true)) )
};

SocketService.prototype.addTo = function(to) {
    this.data['to'] = to;
};

SocketService.prototype.addFrom = function(from) {
    this.data['from'] = from;
};

SocketService.prototype.addUserId = function(userId) {
    this.data['userId'] = userId;
};

SocketService.prototype._getKey = function(from, to) {
    return `${this.options.appId}${from}${to}`;
};
/**
 * Subscribes the passed listener to the event.
 * @param event {SocketService} the connection event.
 * @param listener {Function} the function that will receive the event
 */
 SocketService.prototype.addListener = function(event, listener) {
    this.ws.addEventListener(event, listener);
};

/**
 * Unsubscribes the passed handler.
 * @param event {SocketService} the connection event.
 * @param listener {Function} the function that will receive the event
 */
 SocketService.prototype.removeListener = function(event, listener) {
    this.ws.removeEventListener(event, listener);
};

SocketService.prototype.idle = function() {
    this.ws.send(JSON.stringify({
        act: SocketEvent.WS_SEND_PING
    }));
    setTimeout(this.idle, 30000);
};

/**
 * Attaches a handler for events(For example - "participant joined".) in the
 * conference. All possible event are defined in JitsiConferenceEvents.
 * @param eventId the event ID.
 * @param handler handler for the event.
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
 SocketService.prototype.on = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.on(eventId, handler);
    }
};

/**
 * Attaches a handler for events(For example - "participant joined".) in the
 * conference. All possible event are defined in JitsiConferenceEvents.
 * @param eventId the event ID.
 * @param handler handler for the event.
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
 SocketService.prototype.once = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.once(eventId, handler);
    }
};
/**
 * Removes event listener
 * @param eventId the event ID.
 * @param [handler] optional, the specific handler to unbind
 *
 * Note: consider adding eventing functionality by extending an EventEmitter
 * impl, instead of rolling ourselves
 */
 SocketService.prototype.off = function(eventId, handler) {
    if (this.eventEmitter) {
        this.eventEmitter.removeListener(eventId, handler);
    }
};

SocketService.prototype.destroy = function() {
    this._clearOnCallTimer();
    if (this.ws) {
        this.ws.disconnect();
    }
    this.data = {};
}
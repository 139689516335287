/* global __filename, $, Promise */
import Constants from '../../../Constants';
import { VOICE_TYPE } from "../../../config";

import { getLogger } from '@jitsi/logger';

import EffectStream from "./EffectStream";
import ToneEffect from "./ToneEffect";

const logger = getLogger(__filename);

export default class AudioEffect {

    /**
     * JitsiLocalTrack that is going to be mixed into the track that uses this effect.
     */
    _mixAudio = {};

    /**
     * MediaStream resulted from mixing.
     */
    _mixedMediaStream = undefined;

    /**
     * Original MediaStream from the JitsiLocalTrack that uses this effect.
     */
    _originalStream = {};

    /**
     * MediaStreamTrack obtained from the original MediaStream.
     */
    _originalTrack = {};

    _value = 0;
    _deviceId = '';

    /**
     * Creates AudioMixerEffect.
     *
     * @param {Object} options - Object option
     */
    constructor(mixAudio, options) {
        const { value } = options;
        this._value = Number(value);

        if (mixAudio.getType() !== Constants.MediaType.AUDIO) {
            throw new Error('AudioMixerEffect only supports audio JitsiLocalTracks; effect will not work!');
        }

        this._mixAudio = mixAudio;
        this._isMuted = this._mixAudio.isMuted();
        this._deviceId = this._mixAudio.getDeviceId();

        switch (VOICE_TYPE) {
            case 1:
                this._mixedMediaStream = new ToneEffect({
                    value: this._value
                });
                break;

            case 2:
                this._mixedMediaStream = new EffectStream({
                    value: this._value,
                    output: true
                });
                break;

            default:
                break;
        }
    }

    /**
     * set setEffect.
     *
     * @param {JitsiLocalTrack} mixAudio - JitsiLocalTrack which will be mixed with the original track.
     */
    async setEffect(options) {
        
        const { value } = options;
        this._value = Number(value);

        options.micDeviceId = this._deviceId;
        await this._mixedMediaStream.setEffect(options);

        return this;
    }

    /**
     * Starts the effect process and returns the modified stream.
     *
     * @param {MediaStream} stream the new stream.
     * @protected
     */
    startEffect(stream) {
        this._originalStream = stream;
        this._originalTrack = stream.getTracks()[0];
        if (this._mixedMediaStream) {
            return this._mixedMediaStream.startEffect(stream);
        }

        return stream;
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stopEffect() {
        return this.dispose();
    }

    /**
     * @inheritdoc
     *
     * Stops sending the media track. And removes it from the HTML.
     * NOTE: Works for local tracks only.
     *
     * @returns {void}
     */
    dispose() {
        logger.log('dispose', this._mixedMediaStream, this._isMuted);
        
        if(this._mixedMediaStream) {
            this._mixedMediaStream.dispose();
            delete this._mixedMediaStream;
        }
    }

    /**
     * Checks if the JitsiLocalTrack supports this effect.
     *
     * @param {JitsiLocalTrack} sourceLocalTrack - Track to which the effect will be applied.
     * @returns {boolean} - Returns true if this effect can run on the specified track, false otherwise.
     */
    isEnabled(sourceLocalTrack) {
        // Both JitsiLocalTracks need to be audio i.e. contain an audio MediaStreamTrack
        return sourceLocalTrack.isAudioTrack() && this._mixAudio.isAudioTrack();
    }
    
    /**
     * Change the muted state of the effect.
     *
     * @param {boolean} muted - Should effect be muted or not.
     * @returns {void}
     */
    setMuted(muted = false) {
        this._originalTrack.enabled = !muted;
    }

    /**
     * Check whether or not this effect is muted.
     *
     * @returns {boolean}
     */
    isMuted() {
        return !this._originalTrack.enabled;
    }
}

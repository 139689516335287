
import CameraFacingMode from './service/RTC/CameraFacingMode';

const Resolutions = require('./service/RTC/Resolutions');
//remove some resolution not support
delete Resolutions['2160'];
delete Resolutions['4k'];
delete Resolutions.fullhd;
delete Resolutions.hd;
delete Resolutions.qhd;
delete Resolutions.vga;

const {VoiceType} = require('./modules/effects/constants');

export default {
    CONNECTION_RETRY: 0,
    FORCE_STOP_ID: '999999999',
    Quality: {
        HIGH: 'high',
        MEDIUM: 'medium',
        LOW: 'low',
    },
    MediaType: {
        VIDEO: 'video',
        AUDIO: 'audio',
        PRESENTER: 'presenter',
    },
    CallType: {
        VIDEO: '1',
        AUDIO: '2',
    },
    STATE: {
        INITIAL: 0,
        WAITING: 1,
        PROCESSING: 2,
        IN_CALL: 3,
        STOP: 4,
    },
    Resolutions: Resolutions,
    DEFAULT_WIDTH: 1280,
    DEFAULT_HEGHT: 720,
    DEFAULT_RESOLUTION: 720,
    MAX_RESOLUTION: 1080,
    INIT_RESOLUTION: 240,
    events: {
        CALL_CHANGE_QUALITY: 'call.change_quality',
    },
    VoiceType : VoiceType,
    MODE: {
        WEB: 1,
        API: 2,
    },
    AGENT: {
        IOS: 'ios',
        ANDROID: 'android',
    },
    CameraFacingMode: CameraFacingMode
};
